import _ from "lodash";
import { apiUrls } from "../infrastructure/constants/api";
import apiHelperMethods from "../infrastructure/helpers/api-helpers";

import axios from "axios";

const UseUpdateUserOrganizationActivation = () => {
  const updateActivation = ({
    userId,
    isActive,
    updatedById,
    overrideAccess,
    organizationId,
  }) => {
    return axios.post(
      apiUrls.userIdentityUrls.updateUserOrganizationActivation,
      {
        userId,
        isActive,
        updatedById,
        overrideAccess,
        organizationId,
      }
    );
  };

  return updateActivation;
};

export default UseUpdateUserOrganizationActivation;

// const UseUpdateUserOrganizationActivation = () => {
//   const loadData = async ({
//     userId,
//     isActive,
//     updatedById,
//     overrideAccess,
//     organizationId,
//   }) => {
//     const data = {
//       userId: userId,
//       isActive: isActive,
//       updatedById: updatedById,
//       overrideAccess: overrideAccess,
//       organizationId: organizationId,
//     };

//     return apiHelperMethods.postWithReturn(
//       apiUrls.userIdentityUrls.updateUserOrganizationActivation,
//       data
//     );
//     // .then((res) => {
//     //   console.log(res);
//     // let tempUsers = res.data?.data?.userList;
//     // if (!tempUsers) {
//     //   return Promise.reject("No user found!");
//     // }
//     // tempUsers = convertUsersDates(tempUsers);
//     // const actionStatus = _.get(res, "headers.action-status", null);

//     // return Promise.resolve([tempUsers, actionStatus]);
//     // });
//   };
//   return loadData;
// };

// export default UseUpdateUserOrganizationActivation;
