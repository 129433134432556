import { apiUrls } from "../infrastructure/constants/api";
import apiHelperMethods from "../infrastructure/helpers/api-helpers";

const useLogin = () => {
  const postData = ({ email, password }) => {
    const data = {
      contactText: email,
      password: password,
    };

    const [results] = apiHelperMethods.postWithReturn(
      apiUrls.userIdentityUrls.login,
      data
    );
    return results;
  };

  return postData;
};

export default useLogin;
