import _ from "lodash";
import { apiUrls } from "../infrastructure/constants/api";
import apiHelperMethods from "../infrastructure/helpers/api-helpers";

import axios from "axios";

const UseUpsertLocation = () => {
  const upsertLocation = (payload) => {
    return axios.post(apiUrls.hgUrls.UpsertLocation, payload);
  };

  return upsertLocation;
};

export default UseUpsertLocation;
