import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import styles from "./styles.module.scss";

const statuses = {
  none: "None",
  initiated: "Initiated",
  pendingApplicantSubmission: "PendingApplicantSubmission",
  submitted: "Submitted",
  processing: "Processing",
  lenderLinkClicked: "LenderLinkClicked",
  applicantApplied: "ApplicantApplied",
  processedSuccessfullyWithApproval: "ProcessedSuccessfullyWithApproval",
  processedNoApproval: "ProcessedNoApproval",
  processedNoLender: "ProcessedNoLender",
  abandoned: "Abandoned",
  default: "ProcessingError", // Default case
};

const ApplicationProcessing = ({ applicationStatus = statuses.none }) => {
  const intl = useIntl();

  const getIntlMessage = useCallback(
    (id) => intl.formatMessage({ id }),
    [intl]
  );

  switch (applicationStatus) {
    default:
      return <div className={styles["processing-container"]}></div>;
    case statuses.processing:
      return (
        <div className={styles["processing-container"]}>
          {getIntlMessage("Errors.Processing")}
        </div>
      );
    case statuses.submitted:
      return (
        <div className={styles["processing-container"]}>
          {getIntlMessage("Errors.Submitted")}
        </div>
      );
  }
};

ApplicationProcessing.propTypes = {
  applicationStatus: PropTypes.oneOf([
    null,
    undefined,
    ...Object.values(statuses),
  ]),
};

export default ApplicationProcessing;
