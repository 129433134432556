import _ from "lodash";
import { apiUrls } from "../infrastructure/constants/api";
import apiHelperMethods from "../infrastructure/helpers/api-helpers";

const UseGetLenderHgSitesLocation = () => {
  const loadData = async ({ hgIdCollection, start, records, userType }) => {
    const data = {
      hgIdCollection,
      start,
      records,
    };
    let url = "";
    if (userType === "HealthcareGroup") {
      url = apiUrls.hgUrls.getOrganizationSiteListPaginated;
    }
    if (userType === "Lender") {
      url = apiUrls.lenderUrls.getLenderHgSitesPaginated;
    }
    return apiHelperMethods.getWithReturn(url, data).then((res) => {
      let tempUsers = "";
      if (userType === "HealthcareGroup") {
        tempUsers = res.data.data.hgSiteCollection;
      }
      if (userType === "Lender") {
        tempUsers = res.data.data.hg_SiteCollection;
      }
      let totalCount = res.data.data.totalCount;
      if (!tempUsers) {
        return Promise.reject("No user found!");
      }
      const actionStatus = _.get(res, "headers.action-status", null);
      return Promise.resolve([tempUsers, actionStatus, totalCount]);
    });
  };
  return loadData;
};

export default UseGetLenderHgSitesLocation;
