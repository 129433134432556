import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.scss";
import moment from "moment";
// import { propTypes } from "react-bootstrap/esm/Image";

const ShowDataControl = ({
  callbackfunction,
  period = "",
  children = <></>,
}) => {
  return (
    <>
      <div className={styles["container"]}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <div className={styles["title"]}>Show data</div>
          <div className={`${styles["date"]} ${styles["under600"]}`}>
            {moment().format("DD/MM/YYYY")}
          </div>
        </div>
        <div className={styles["options"]}>
          <option
            onClick={() => {
              callbackfunction("WTD");
            }}
            className={`${styles["option"]} ${
              period === "WTD" && styles["selected"]
            }`}
          >
            {/* Weekly */}
            WTD
          </option>
          <option
            onClick={() => {
              callbackfunction("MTD");
            }}
            className={`${styles["option"]} ${
              period === "MTD" && styles["selected"]
            }`}
          >
            MTD
            {/* Monthly */}
          </option>
          <option
            onClick={() => {
              callbackfunction("QTD");
            }}
            className={`${styles["option"]} ${
              period === "QTD" && styles["selected"]
            }`}
          >
            QTD
            {/* Quarterly */}
          </option>
          <option
            onClick={() => {
              callbackfunction("YTD");
            }}
            className={`${styles["option"]} ${
              period === "YTD" && styles["selected"]
            }`}
          >
            YTD
            {/* Yearly */}
          </option>
        </div>
        <div className={`${styles["date"]} ${styles["over600"]}`}>
          {moment().format("DD/MM/YYYY")}
        </div>
      </div>
    </>
  );
};

ShowDataControl.propTypes = {
  period: PropTypes.oneOf(["WTD", "MTD", "QTD", "YTD"]),
  callbackfunction: PropTypes.func,
};

export default ShowDataControl;
