import { apiUrls } from "../infrastructure/constants/api";
import apiHelperMethods from "../infrastructure/helpers/api-helpers";

const EditUserLocation = () => {
  const postData = ({ updateCollection, userType }) => {
    // console.log(updateCollection, userType);
    let url = apiUrls.hgUrls.userUpdateLocation;
    if (userType === "HealthcareGroup") {
      url = apiUrls.hgUrls.userUpdateLocation;
    }
    if (userType === "Lender") {
      url = apiUrls.lenderUrls.userUpdateLocation;
    }
    const data = { updateCollection: updateCollection };
    const [results] = apiHelperMethods.postWithReturn(url, data);
    return results;
  };
  return postData;
};

export default EditUserLocation;
