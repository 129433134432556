import { apiUrls } from "../infrastructure/constants/api";
import apiHelperMethods from "../infrastructure/helpers/api-helpers";

const useGetUser = () => {
  const loadUser = async () => {
    return apiHelperMethods
      .getWithReturn(apiUrls.userIdentityUrls.user)
      .then((res) => {
        let tempUser = res.data?.data?.membershipUser;
        if (!tempUser) {
          return Promise.reject("No user found!");
        }
        tempUser = {
          ...tempUser,
          createDate: !!tempUser.createDate
            ? new Date(tempUser.createDate)
            : undefined,
          emailVerificationDate: !!tempUser.emailVerificationDate
            ? new Date(tempUser.emailVerificationDate)
            : undefined,
          lastActivationChangeDate: !!tempUser.lastActivationChangeDate
            ? new Date(tempUser.lastActivationChangeDate)
            : undefined,
          lastLoginAttempt: !!tempUser.lastLoginAttempt
            ? new Date(tempUser.lastLoginAttempt)
            : undefined,
        };
        return Promise.resolve(tempUser);
      });
  };
  return loadUser;
};

export default useGetUser;
