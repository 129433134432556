import React, { useCallback, useState } from "react";
import styles from "./styles.module.scss";
import { NumericFormat, PatternFormat } from "react-number-format";
import { Col, FormControl, Row, Alert, Button } from "react-bootstrap";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { createValidationObject } from "../../infrastructure/helpers/object-helpers";
import _ from "lodash";
import Logo from "./logo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FormattedMessage, useIntl } from "react-intl";
import { IdIcon } from "hugeicons-react";
import CheckBox from "../../components/check-box/check-box";

const ApplicantInfo = ({
  errorMessage = null,
  onUpdateApplicantInfo = () => {},
}) => {
  const intl = useIntl();
  const [step, setStep] = useState(0);
  const [formData, setFormData] = useState({
    ssn: undefined,
    noSsn: false,
    preferredMonthlyPayment: undefined,
    annualIncome: undefined,
    availableCreditLine: undefined,
    hasAvaiableCreditLine: false,
  });
  const [formDataValidation, setFormDataValidation] = useState(
    createValidationObject(formData)
  );
  const getIntlMessage = useCallback(
    (id) => intl.formatMessage({ id }),
    [intl]
  );
  const handleChange = (name, value) => {
    // console.log(value);
    setFormData({
      ...formData,
      [name]: value,
    });
    setFormDataValidation({
      ...formDataValidation,
      [name]: true,
    });
  };
  return (
    <div className={`${styles["verification-container"]}`}>
      <Logo />
      <div className={styles["welcome-message"]}>
        <FormattedMessage id="WelcomeToDentirate" />
      </div>
      {!!errorMessage && typeof errorMessage === "string" && (
        <Alert className="mt-4" variant="danger">
          {errorMessage || getIntlMessage("Errors.InvalidLink")}
        </Alert>
      )}
      <form
        style={{ height: "100%" }}
        className="form mt-4 ml-4"
        onSubmit={(e) => {
          e.preventDefault();
          // if (!validateAll() || step !== steps.nextStep) {
          //   return;
          // }
          // document.querySelectorAll("input").forEach((input) => input.blur());
          onUpdateApplicantInfo({
            newApplicantInfo: {
              ssn: formData.ssn, // XXX-XX-XXXX
              noSsn: formData.noSsn,
              preferredMonthlyPayment: formData.preferredMonthlyPayment,
              annualIncome: formData.annualIncome,
              availableCreditLine: formData.availableCreditLine,
              hasAvailableCreditLine: formData.availableCreditLine >= 0,
            },
            gotoNextStep: true,
          });
        }}
      >
        <div className={styles["flex-col"]}>
          {step === 0 && (
            <>
              <Col lg={12} sm={12}>
                {/* SSN Field */}
                {/* <ValidationWrapper
              errorMessage={<FormattedMessage id="Errors.Validation.SSN" />}
              title={""}
              inputId="txt-ssn"
              isValid={formDataValidation.ssn}
              alertStyle={{ marginTop: "10px" }}
            > */}
                <Row>
                  <div className={styles["row-label"]}>
                    <FormattedMessage id="SSN.Enter" />
                  </div>
                </Row>
                <div className={` ${styles["large-input-label"]}`}>
                  <FormattedMessage id="SSN" />
                </div>
                <PatternFormat
                  id="input-ssn"
                  className={`form-control ${
                    !formDataValidation.ssn && "danger"
                  }`}
                  autoComplete="off"
                  value={formData.ssn || ""}
                  onValueChange={(values) => {
                    handleChange("ssn", values.value);
                  }}
                  format="###-##-####"
                  mask="_"
                  disabled={formData.noSsn}
                />
                <div className={styles["checkbox-container"]}>
                  <CheckBox
                    text={getIntlMessage("SSN.noSsn")}
                    onClick={(e) =>
                      handleChange("noSsn", !(formData?.noSsn || false))
                    }
                    Checked={formData?.noSsn || false}
                  />
                </div>
                {/* </ValidationWrapper> */}
              </Col>

              {/* Preferred Monthly Payment Field */}
              <Col lg={12} sm={12} className="mt-3">
                {/* <ValidationWrapper
              errorMessage={
                <FormattedMessage id="Errors.Validation.preferredMonthlyPayment" />
              }
              title={""}
              inputId="txt-preferredMonthlyPayment"
              isValid={formDataValidation.preferredMonthlyPayment}
              alertStyle={{ marginTop: "10px" }}
            > */}
                {/* <Row>
              <div className={styles["row-label"]}>
                <FormattedMessage id="preferredMonthlyPayment.Enter" />
              </div>
            </Row> */}
                <div className={` ${styles["large-input-label"]}`}>
                  <FormattedMessage id="preferredMonthlyPayment" />
                </div>
                <NumericFormat
                  thousandSeparator
                  prefix="$"
                  value={formData.preferredMonthlyPayment || ""}
                  onValueChange={(values) =>
                    handleChange("preferredMonthlyPayment", values.value)
                  }
                  className="form-control"
                />
                {/* </ValidationWrapper> */}
              </Col>

              {/* Annual Income Field */}
              <Col lg={12} sm={12} className="mt-3">
                {/* <ValidationWrapper
              errorMessage={
                <FormattedMessage id="Errors.Validation.AnnualIncome" />
              }
              title={""}
              inputId="txt-annualIncome"
              isValid={formDataValidation.annualIncome}
              alertStyle={{ marginTop: "10px" }}
            > */}
                {/* <Row>
              <div className={styles["row-label"]}>
                <FormattedMessage id="AnnualIncome.Enter" />
              </div>
            </Row> */}
                <div className={` ${styles["large-input-label"]}`}>
                  <FormattedMessage id="annualIncome" />
                </div>
                <NumericFormat
                  thousandSeparator
                  prefix="$"
                  value={formData.annualIncome || ""}
                  onValueChange={(values) =>
                    handleChange("annualIncome", values.value)
                  }
                  className="form-control"
                />
                {/* </ValidationWrapper> */}
              </Col>
              <Col lg={12} sm={12} className="mt-3">
                {/* <ValidationWrapper
              errorMessage={
                <FormattedMessage id="Errors.Validation.AnnualIncome" />
              }
              title={""}
              inputId="txt-annualIncome"
              isValid={formDataValidation.annualIncome}
              alertStyle={{ marginTop: "10px" }}
            > */}
                {/* <Row>
              <div className={styles["row-label"]}>
                <FormattedMessage id="AnnualIncome.Enter" />
              </div>
            </Row> */}
                <div className={` ${styles["large-input-label"]}`}>
                  <FormattedMessage id="availableCredit" />
                </div>
                <NumericFormat
                  id="available-credit-line"
                  name="number"
                  className={`form-control`}
                  thousandSeparator={true}
                  prefix={"$"}
                  placeholder=""
                  autoComplete="off"
                  customInput={FormControl}
                  allowNegative={false}
                  decimalScale={0}
                  fixedDecimalScale={true}
                  value={formData.availableCreditLine || ""}
                  onValueChange={(values) => {
                    handleChange("availableCreditLine", values.floatValue);
                  }}
                  maxLength={7}
                />
                {/* </ValidationWrapper> */}
              </Col>
              {/* Navigation Buttons */}
              <Row
                className={styles["btn-container"]}
                style={{ marginTop: "20px" }}
              >
                <Col xs={12} style={{ padding: 0 }}>
                  {
                    <Button
                      variant="primary"
                      className={`${styles["continue"]}`}
                      type="button"
                      onClick={() => {
                        setStep(1);
                      }} // Navigate to the next step
                      disabled={
                        !(
                          ((!!formData.ssn && formData.ssn.length === 9) ||
                            formData.noSsn) &&
                          !!formData.preferredMonthlyPayment &&
                          !!formData.annualIncome &&
                          formData.availableCreditLine >= 0 &&
                          formDataValidation.ssn &&
                          formDataValidation.preferredMonthlyPayment &&
                          formDataValidation.annualIncome &&
                          formDataValidation.availableCreditLine
                        )
                      }
                    >
                      {getIntlMessage("Button.Continue")}
                    </Button>
                  }
                </Col>
              </Row>
            </>
          )}

          {step === 1 && (
            <>
              <div>
                <Row>
                  <div className={styles["row-label"]}>
                    Confirm your data for the next step.
                  </div>
                </Row>

                {/* SSN */}
                <div className={styles["inital-info-summary"]}>
                  <div className={styles["column-label"]}>
                    <IdIcon />
                    <FormattedMessage id="ssnNumber" />
                  </div>
                  <div className={styles["column-value"]}>
                    {formData.ssn || "-"}
                  </div>
                </div>
                <hr />

                {/* Preferred Monthly Payment */}
                <div className={styles["inital-info-summary"]}>
                  <div className={styles["column-label"]}>
                    <FontAwesomeIcon icon={faArrowRight} />
                    <FormattedMessage id="preferredMonthlyPayment" />
                  </div>
                  <div className={styles["column-value"]}>
                    $
                    {parseFloat(
                      formData.preferredMonthlyPayment || 0
                    ).toLocaleString()}
                  </div>
                </div>
                <hr />

                {/* Annual Income */}
                <div className={styles["inital-info-summary"]}>
                  <div className={styles["column-label"]}>
                    <FontAwesomeIcon icon={faArrowRight} />
                    <FormattedMessage id="annualIncome" />
                  </div>
                  <div className={styles["column-value"]}>
                    ${parseFloat(formData.annualIncome || 0).toLocaleString()}
                  </div>
                </div>

                <hr />

                {/* Annual Income */}
                <div className={styles["inital-info-summary"]}>
                  <div className={styles["column-label"]}>
                    <FontAwesomeIcon icon={faArrowRight} />
                    <FormattedMessage id="availableCredit" />
                  </div>
                  <div className={styles["column-value"]}>
                    $
                    {parseFloat(
                      formData.availableCreditLine || 0
                    ).toLocaleString()}
                  </div>
                </div>
              </div>

              <Row className={styles["btn-container"]}>
                <Col xs={4} style={{ padding: 0 }}>
                  <Button
                    variant="dark"
                    className={`mt_3 w_100 ${styles["back"]}`}
                    type="button"
                    onClick={() => {
                      setStep(0); // Navigate back to the new step
                    }}
                  >
                    {getIntlMessage("Button.Back")}
                  </Button>
                </Col>
                <Col xs={8} style={{ padding: 0 }}>
                  <Button
                    variant="primary"
                    className={styles["continue"]} // Submit the form
                    type="submit"
                  >
                    {getIntlMessage("Button.Continue")}
                  </Button>
                </Col>
              </Row>
            </>
          )}
        </div>
      </form>
    </div>
  );
};

export default ApplicantInfo;
