import React from "react";
import styles from "./styles.module.scss";
import logo from "../../images/logo.png";

const Logo = ({ isLeft = false }) => {
  return (
    <div
      className={`${styles["small-logo-container"]} ${
        isLeft ? styles["header-left"] : ""
      }`}
    >
      <a href="https://www.dentirate.com">
        <img src={logo} alt="DentiRate Logo" />
      </a>
    </div>
  );
};

export default Logo;
