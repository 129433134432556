import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.scss";

const RadioButton = ({
  text = "",
  name = "radio",
  onchange = () => {},
  Checked = undefined,
  onClick = () => {},
  style = {},
}) => {
  return (
    <>
      <label className={styles["container"]} style={style}>
        {text}
        <input
          type="radio"
          name={name}
          onChange={onchange}
          checked={Checked}
          onClick={onClick}
        />
        <span className={styles["checkmark"]}></span>
      </label>
    </>
  );
};

RadioButton.propTypes = {};

export default RadioButton;
