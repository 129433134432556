// src/hooks/useHgLocations.js

import { useEffect, useState } from "react";
import { getLocationStore } from "../stores";

const useHgLocations = () => {
  const locationStore = getLocationStore();

  const [locationCollection, setLocationCollection] = useState(
    locationStore.getLocations()
  );

  useEffect(() => {
    const unsubscribe = locationStore.subscribe(setLocationCollection);
    return () => unsubscribe();
  }, [locationStore]);

  return {
    getLocationByIds: locationStore.getLocationByIds,
    addLocations: locationStore.addLocations,
    deleteLocations: locationStore.deleteLocations,
    setUserType: locationStore.setUserType,
    locationCollection,
  };
};

export default useHgLocations;
