import React from "react";
import PropTypes from "prop-types";
import ModalContainer from "../../components/modal-control";
import styles from "./styles2.module.scss";
import PaymentForm from "./payment-form";
import { CosIcon } from "hugeicons-react";

const ApplyModal = ({
  show = false,
  handleClose = () => {},
  message = "",
  children,
  application = {},
  lenderCollection = {},
  lenderIndex = {},
  initialInfo = {},
}) => {
  return (
    <ModalContainer
      show={show}
      handleClose={() => handleClose(false)}
      modalSize="lg"
      modalTitle={"In House Financing Apply Form"}
      centered
    >
      <div className={styles["modal-content"]}>{message || ""}</div>
      <PaymentForm
        application={application}
        lenderCollection={lenderCollection}
        lenderIndex={lenderIndex}
        initialInfo={initialInfo}
        handleClose={handleClose}
      />
    </ModalContainer>
  );
};

ApplyModal.propTypes = {
  show: PropTypes.bool,
  handleClose: PropTypes.func,
  message: PropTypes.string,
};

export default ApplyModal;
