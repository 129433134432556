import React, { useState } from "react";
import styles from "./styles.module.scss";
import { FilterHorizontalIcon, Search01Icon } from "hugeicons-react";
import SelectControl from "../select-control/select-control";
import treatmentType from "../../infrastructure/constants/enums/treatment-types";
import applicationStatus from "../../infrastructure/constants/enums/application-status";
import { Col, Container, Row } from "react-bootstrap";
import ReactSlider from "react-slider";
import _ from "lodash";
import { HgSitesSelectControl } from "../select-control";
import { useLocation, useNavigate } from "react-router-dom";

const UsersFilterSearch = ({
  tab = "users-tab",
  handleSearch = () => {},
  children = <></>,

  searchFilterData,
  setSearchFilterData,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const [selectControlValue, setSelectControlValue] = useState();
  return (
    <>
      <div className={styles["container"]}>
        <div className={styles["first-row"]}>
          <div className={styles["search-container"]}>
            {tab === "users-tab" && (
              <>
                <input
                  // type="text"
                  // name="searchText"
                  placeholder="Search"
                  // value={searchFilterData.searchText}
                  className={styles["search-text"]}
                  onChange={(e) => {
                    setSearchQuery(e.target.value);
                    if (e.target.value === "") {
                      handleSearch(searchQuery);
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSearch(searchQuery);
                    }
                  }}
                />
                <Search01Icon
                  className={styles["search-icon"]}
                  onClick={() => handleSearch(searchQuery)}
                />
              </>
            )}
          </div>
          {children}
        </div>
        {tab === "users-tab" && (
          <div className={styles["filter-container"]}>
            <FilterHorizontalIcon
              color="#767676"
              className={styles["filter-icon"]}
            />

            <SelectControl
              className={styles["filter"]}
              placeholder="Status"
              options={[
                { label: "All statuses", value: null },
                { label: "Active", value: true },
                { label: "Inactive", value: false },
              ]}
              // value={[{ label: "Active", value: searchFilterData.isActive }]}
              value={[selectControlValue]}
              onChange={(v) => {
                const queryString = new URLSearchParams(location.search);
                // console.log(v);
                if (v !== null) {
                  switch (v.value) {
                    case true:
                      setSelectControlValue({ label: "Active", value: true });
                      queryString.set("status", "active");
                      navigate(`/user-management?${queryString.toString()}`);
                      break;
                    case false:
                      setSelectControlValue({
                        label: "Inactive",
                        value: false,
                      });

                      queryString.set("status", "inactive");
                      navigate(`/user-management?${queryString.toString()}`);
                      break;
                    case null:
                      setSelectControlValue({
                        label: "All statuses",
                        value: null,
                      });

                      queryString.set("status", "all");
                      navigate(`/user-management?${queryString.toString()}`);
                      break;
                    default:
                      setSelectControlValue({ label: "Active", value: true });

                      queryString.set("status", "active");
                      navigate(`/user-management?${queryString.toString()}`);
                      break;
                  }
                } else {
                  setSelectControlValue();

                  queryString.set("status", "");
                  navigate(`/user-management?${queryString.toString()}`);
                }
              }}

              // isMulti={true}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default UsersFilterSearch;
