const lenderUrls = {
  getLenderHgSitesPaginated: "/api/Lender/GetLenderHgSitesPaginated",
  downloadKpiAggregated: "/api/ClientDashboard/Lender/DownloadKpiAggregated",
  getActiveUsers: `/api/ClientDashboard/Lender/KpiActiveUsers`,
  getKpiSummary: `/api/ClientDashboard/Lender/KpiSummary`,
  getKpiOverVew: `/api/ClientDashboard/Lender/KpiOverview`,
  getKpiTopHgs: `/api/ClientDashboard/Lender/KpiTopHgs`,
  userUpdateLocation: "/api/Lender/UpsertUsersInHgSites",
};

export default lenderUrls;
