import _ from "lodash";
import { apiUrls } from "../infrastructure/constants/api";
import apiHelperMethods from "../infrastructure/helpers/api-helpers";

function convertUsersDates(users) {
  return users.data?.invoiceCollection.map((tempUser) => ({
    ...tempUser,
    issueDate: !!tempUser.issueDate ? new Date(tempUser.issueDate) : undefined,
    dueDate: !!tempUser.dueDate ? new Date(tempUser.dueDate) : undefined,
    serviceStartDate: !!tempUser.serviceStartDate
      ? new Date(tempUser.serviceStartDate)
      : undefined,
    serviceEndDate: !!tempUser.serviceEndDate
      ? new Date(tempUser.serviceEndDate)
      : undefined,
  }));
}

const UseGetBillings = () => {
  const loadData = async ({
    minAmount,
    maxAmount,
    status,
    start,
    Records,
    organizationId,
  }) => {
    const data = {
      minAmountDue: minAmount,
      maxAmountDue: maxAmount,
      invoiceStatus: status,
      start: start,
      organizationId: organizationId,
      Records: Records,
    };
    return apiHelperMethods
      .getWithReturn(apiUrls.userIdentityUrls.billingsPaginated, data)
      .then((res) => {
        let tempUsers = res.data;

        if (!tempUsers) {
          return Promise.reject("No user found!");
        }
        tempUsers = convertUsersDates(tempUsers);
        const actionStatus = _.get(res, "headers.action-status", null);
        return Promise.resolve([tempUsers, actionStatus]);
      });
  };
  return loadData;
};

export default UseGetBillings;
