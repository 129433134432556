import request from "./request.js";
import Qs from "qs";

// export function sendGet(url) {
//   return request({
//     url: url,
//     method: 'get',
//     params: null
//   })
// }

export function sendGetKeyValue(url, param) {
  return request({
    url: url,
    method: "get",
    params: param,
  });
}

export function sendGet(url, userId) {
  return request({
    url: url,
    method: "get",
    params: null,
  });
}

export function sendPost(url) {
  return request({
    url: url,
    method: "post",
    params: null,
    transformRequest: [
      function (data) {
        data = JSON.stringify(data);
        return data;
      },
    ],
  });
}

export function sendPostByKeyValue(url, param) {
  return request({
    url: url,
    method: "post",
    params: param,
    transformRequest: [
      function (data) {
        data = Qs.stringify(data);
        return data;
      },
    ],
  });
}

export function sendPostByFormData(url, value) {
  return request({
    url: url,
    method: "post",
    params: {},
    // transformRequest: [function(data) {
    //   data = JSON.stringify(data)
    //   return data
    // }],

    data: value,
  });
}

export function sendPostByJson(url, param) {
  return request({
    url: url,
    method: "post",
    params: {},
    transformRequest: [
      function (data) {
        data = JSON.stringify(data);
        return data;
      },
    ],

    data: param,
  });
}
export function uploadFiles(url, param, headers) {
  return request({
    url: url,
    method: "post",
    params: {},

    headers: headers,
    data: param,
  });
}
