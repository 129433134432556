import { apiUrls } from "../infrastructure/constants/api";
import apiHelperMethods from "../infrastructure/helpers/api-helpers";

const useRecoveryOpen = () => {
  const postData = (code) => {
    const data = {
      encryptedCode: code,
    };

    const [results] = apiHelperMethods.postWithReturn(
      apiUrls.userIdentityUrls.recoveryOpen,
      data
    );
    return results;
  };
  return postData;
};

export default useRecoveryOpen;
