import hubs from "./general-api";
import hgUrls from "./hg-api";
import lenderUrls from "./lender-api";
import userIdentityUrls from "./user-identity";

// create files and add as many as you wish
const apiUrls = {
  userIdentityUrls,
  hgUrls,
  lenderUrls,
  hubs,
};

export default apiUrls;
