import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import StatisticItem from "../../components/statistic-item";
import { useCommonContext } from "../../common-context";
import { apiLoadingStatus } from "../../infrastructure/constants/api";
import LoadingSpinner from "../../components/loading-spinner/loading-spinner";
import Layout from "../../layout";
import HomeChart from "../../components/chart-controls/homepage-chart";
import ShowDataControl from "../../components/show-data-control/show-data-control";
import { mokeDataCollection } from "./data";
import {
  getDateRangeForPeriod,
  getDynamicViewType,
} from "../../infrastructure/helpers/date-helpers";
import _ from "lodash";
import SummaryControl from "../../components/dashboard-controls/summary-control/summary-control";
import LayoutBigPage from "../../layout-big-page";
import { useGetKpiOverView } from "../../hooks";
import { toast } from "react-toastify";

const HomePage = () => {
  const options = ["WTD", "MTD", "QTD", "YTD"];
  const [dataCollection, setDataCollection] = useState({});
  const [{ user, logo }] = useCommonContext();
  const [isLoading, setIsLoading] = useState(apiLoadingStatus.loaded);

  const [homepageFilter, setHomepageFilter] = useState({
    viewType: "YTD",
    timeZoneId: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });
  const [searchParams, setSearchParams] = useState({
    userType: user.data.userType,
    startDate: getDateRangeForPeriod(homepageFilter.viewType).startDate,
    endDate: getDateRangeForPeriod(homepageFilter.viewType).endDate,
    timeZoneId: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });
  const getOverView = useGetKpiOverView();
  const showErrorMassage = (massage) => {
    toast(massage, { type: "error", autoClose: 4000, toastId: "error" });
  };
  /// useHgLocations
  // const {
  //   getLocationByIds,
  //   addLocations,
  //   deleteLocations,
  //   setUserType,
  //   locationCollection,
  // } = useHgLocations();
  // window.getLocationByIds = getLocationByIds; // hgSiteId; not mistaken with HgId or LenderId or UserId
  // window.addLocations = addLocations;
  // window.deleteLocations = deleteLocations;
  // window.locationCollection = locationCollection;

  useEffect(() => {
    document.title = "Home | DentiRate";
  }, []);
  //changing searsh parameters according to view type selected by user
  useEffect(() => {
    const temp = getDateRangeForPeriod(homepageFilter.viewType);
    const newSearchParams = {
      ...searchParams,
      startDate: temp.startDate,
      endDate: temp.endDate,
    };
    if (!_.isEqual(searchParams, newSearchParams)) {
      setSearchParams(newSearchParams);
    }
  }, [homepageFilter.viewType]);

  // useEffect(() => {
  //   // return;
  //   let actionStatus = null;
  //   setIsLoading(apiLoadingStatus.loading);
  //   getOverView({
  //     userType: user.data?.userType,
  //     viewType: homepageFilter.viewType,
  //   })
  //     .then(([res, status]) => {
  //       setIsLoading(apiLoadingStatus.loaded);
  //       actionStatus = status;
  //       setDataCollection(res);
  //     })
  //     .catch((err) => {
  //       actionStatus = _.get(err, "response.headers.action-status", null);
  //       setIsLoading(apiLoadingStatus.error);
  //       console.log(err);
  //     })
  //     .finally(() => {
  //       switch (actionStatus) {
  //         default:
  //           break;
  //         case "Failed":
  //           showErrorMassage("Failed");
  //           break;
  //         case "UsedBefore":
  //           showErrorMassage("Used Before");
  //           break;
  //         case "SameAsPrevious":
  //           showErrorMassage("Same As Previous");
  //           break;
  //         case "AdminLockedOut":
  //           showErrorMassage("Admin LockedOut ");
  //           break;
  //         case "InvalidCredentialsNewlyLockedOut":
  //           showErrorMassage("Invalid Credentials Newly LockedOut");
  //           break;
  //         case "InvalidCredentials":
  //           showErrorMassage("Invalid Credentials");
  //           break;
  //         case "ValidationError":
  //           showErrorMassage("Validation Error");
  //           break;
  //         case "NotExists":
  //           showErrorMassage("Not Found");
  //           break;
  //         case "Forbidden":
  //           showErrorMassage("Forbidden ");
  //           break;
  //         case "Success":
  //           break;
  //       }
  //     });
  // }, [, homepageFilter]);
  return (
    <LayoutBigPage
      bigPage={true}
      user={{
        type: "admin",
        name: user.data.firstName + " " + user.data.lastName,
        logo: logo.data?.url,
      }}
    >
      <LoadingSpinner
        isLoading={
          user.loadingStatus === apiLoadingStatus.loading ||
          logo.loadingStatus === apiLoadingStatus.loading ||
          isLoading === apiLoadingStatus.loading
        }
      />
      <div className={styles["main-container"]}>
        <div className={styles["row-wrapper"]}>
          <div className={styles["first-row"]}>
            <h1>OVERVIEW</h1>

            <div className={styles["show-data-mode"]}>
              <ShowDataControl
                period={
                  options.find(
                    (i) => i === homepageFilter?.viewType?.toUpperCase()
                  ) || options[0]
                }
                callbackfunction={(o) => {
                  if (o) {
                    setHomepageFilter({
                      ...homepageFilter,
                      viewType: o,
                    });
                  }
                }}
              />
            </div>
          </div>
        </div>
        <div className={styles["container"]}>
          <div className={styles["wrapper"]}>
            <div className={styles["statistics"]}>
              {/* <span>
                <StatisticItem type="leads" value={53} />
              </span> */}
              <span>
                <StatisticItem
                  type='submitted'
                  value={
                    mokeDataCollection.data?.overviewCount?.totalSubmittedCount
                  }
                />
              </span>
              <span>
                <StatisticItem
                  type='pre-approved'
                  value={
                    mokeDataCollection.data?.overviewCount
                      ?.totalPreApprovedCount
                  }
                />
              </span>
              {/* <span>
                <StatisticItem type="applications" />
              </span> */}
              <span>
                <StatisticItem
                  type='prospects'
                  value={mokeDataCollection.data?.overviewCount?.totalLeadCount}
                />
              </span>
            </div>
            {/* Only for HG Users */}
            {/* <h2>Only HGs</h2>
          <HgSitesSelectControl multi/> */}

            {/* Only for Lender Users */}
            {/* <h2>Only Lenders</h2>
          <LenderHGsSelectControl />
          <LenderHGSiteSelectControl hgIdCollection={[20005]} multi /> */}
            <HomeChart
              userType={user.data?.userType}
              // trendCollection={trendCollection}
              viewType={homepageFilter.viewType}
              trendCollection={mokeDataCollection.data.trendCollection}
            >
              {/* <div className={styles["show-data-mode"]}>
                  <ShowDataControl
                    period={
                      options.find(
                        (i) => i === homepageFilter?.viewType?.toUpperCase()
                      ) || options[0]
                    }
                    callbackfunction={(o) => {
                      if (o) {
                        setHomepageFilter({
                          ...homepageFilter,
                          viewType: o,
                        });
                      }
                    }}
                  />
                </div> */}
            </HomeChart>
          </div>
          <div className={styles["wrapper"]}>
            <SummaryControl filters={searchParams} showSpinner={true} />
          </div>
        </div>
      </div>
    </LayoutBigPage>
  );
};

export default HomePage;
