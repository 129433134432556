import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.scss";

const ValidatorMessage = ({
  errorMessage = "",
  isValid = true,
  style = {},
}) => {
  return (
    <div
      className={`alert alert-danger validation-message ${
        isValid ? "invisible" : ""
      } `}
      style={style}
    >
      {errorMessage}
    </div>
  );
};

ValidatorMessage.propTypes = {
  errorMessage: PropTypes.string,
  isValid: PropTypes.bool,
};

export default ValidatorMessage;
