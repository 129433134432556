import React from "react";
import PropTypes from "prop-types";
import { Tooltip as InnerTooltip, OverlayTrigger } from "react-bootstrap";

const TooltipControl = (props) => {
  const {
    showDelay = 150,
    hideDelay = 100,
    children,
    placement = "top",
    content,
    disabled = false,
  } = props;

  const renderTooltip = (props) =>
    content ? <InnerTooltip {...props}>{content}</InnerTooltip> : null;

  if (disabled) {
    return children;
  }

  return (
    <OverlayTrigger
      placement={placement}
      delay={{ show: showDelay, hide: hideDelay }}
      overlay={renderTooltip}
    >
      {children}
    </OverlayTrigger>
  );
};

TooltipControl.propTypes = {
  placement: PropTypes.oneOf(["right", "left", "bottom", "top"]),
  showDelay: PropTypes.number,
  hideDelay: PropTypes.number,
  children: PropTypes.any,
  content: PropTypes.any,
  disabled: PropTypes.bool,
};

export default TooltipControl;
