import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.scss";

const CheckBox = ({
  text = "",
  onchange = () => {},
  Checked = undefined,
  onClick = () => {},
  style = {},
  id = null,
  disabled = false,
}) => {
  return (
    <>
      <label className={styles["container"]} style={style}>
        {text}
        <input
          type='checkbox'
          onChange={onchange}
          checked={Checked}
          onClick={onClick}
          id={id}
          disabled={disabled}
        />
        <span className={styles["checkmark"]}></span>
      </label>
    </>
  );
};

CheckBox.propTypes = {};

export default CheckBox;
