import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import styles from "./styles.module.scss";
import "./select-control.scss";

const SelectControl = ({
  value = null,
  onChange = () => {},
  isMulti = false,
  options = [],
  placeholder = "Select",
  isClearable = true,
  styles = {},
  className = "",
  classNamePrefix = "react-select",
}) => {
  const innerStyles = {
    margin: 0,
    ...(styles || {}),
  };
  if (isMulti) {
    return (
      <Select
        value={value}
        onChange={onChange}
        isMulti={isMulti}
        options={options}
        isClearable={isClearable}
        placeholder={placeholder}
        styles={innerStyles}
        className={`${styles["select-control"]} ${className}`}
        classNamePrefix={classNamePrefix}
      />
    );
  }

  return (
    <Select
      value={!!value ? value : null}
      onChange={onChange}
      isMulti={isMulti}
      options={options}
      isClearable={isClearable}
      placeholder={placeholder}
      styles={innerStyles}
      className={`${styles["select-control"]} ${className}`}
      classNamePrefix={classNamePrefix}
    />
  );
};

// isMulti = false --> value=string or number
// isMulti = true --> array of [{label: string, value: string|number}]

const optionPropType = PropTypes.shape({
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
});
SelectControl.propTypes = {
  isClearable: PropTypes.bool,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(optionPropType),
  onChange: PropTypes.func,
  isMulti: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(optionPropType),
    PropTypes.object,
  ]),
  className: PropTypes.string,
  classNamePrefix: PropTypes.string,
};

export default SelectControl;
