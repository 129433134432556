import { apiUrls } from "../infrastructure/constants/api";
import apiHelperMethods from "../infrastructure/helpers/api-helpers";

const EditUserBio = () => {
  const postData = (newUser) => {
    const [results] = apiHelperMethods.postWithReturn(
      apiUrls.userIdentityUrls.editUserBio,
      newUser
    );
    return results;
  };
  return postData;
};

export default EditUserBio;
