import { Container, Row, Col, Form } from "react-bootstrap";
import FormCustomControl from "../../../components/form-custom-control";
import logo from "../../../images/logo.png";
import styles from "./styles.module.scss";
import { Mail01Icon, MultiplicationSignCircleIcon } from "hugeicons-react";
import { useState } from "react";
import _ from "lodash";
import { apiLoadingStatus } from "../../../infrastructure/constants/api";
import { toast } from "react-toastify";
import UseRecoveryInit from "../../../hooks/use-recovery-init";
import LoadingSpinner from "../../../components/loading-spinner/loading-spinner";
import { useNavigate } from "react-router-dom";

const RecoveryInit = () => {
  const [email, setEmail] = useState("");
  const [emailValidation, setEmailValidation] = useState(true);
  const [loadingStatus, setLoadingStatus] = useState(apiLoadingStatus.unloaded);
  const [step, setStep] = useState(1);

  const handleRecoveryInit = UseRecoveryInit();

  const navigate = useNavigate();

  function validateEmail(email) {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return !!email && email !== "" && emailRegex.test(email);
  }

  const validateForm = () => {
    const emailValid = validateEmail(email);
    setEmailValidation(emailValid);
    return emailValid;
  };

  const onContinue = async () => {
    if (loadingStatus === apiLoadingStatus.loading) {
      return;
    }
    //validation here
    const validationResult = validateForm();
    if (!validationResult) {
      return;
    }
    //
    setEmailValidation(true);

    setLoadingStatus(apiLoadingStatus.loading);
    let actionStatus = undefined;
    let data = undefined;

    console.log(email);
    handleRecoveryInit(email)
      .then((res) => {
        actionStatus = _.get(res, "headers.action-status", null);
        data = _.get(res, "data");
      })
      .catch((err) => {
        actionStatus = _.get(err, "response.headers.action-status", null);
      })
      .finally(() => {
        setLoadingStatus(apiLoadingStatus.loaded);
        switch (actionStatus) {
          default:
            showErrorMassage("Unknown error");
            break;
          case "Failed":
          case "ValidationError":
            showErrorMassage("Invalid Credientials");
            break;
          case "UnverifiedContact":
            showErrorMassage("Unverified Contact");
            break;
          case "NotExists":
            showErrorMassage("Email address does not exist");
            break;
          case "AdminLockedOut":
            showErrorMassage("Your account was just locked out.");
            break;
          case "ExceededLimit":
            setStep(2);
            break;
          case "Success":
            setStep(2);
            break;
        }
      });
  };

  const showErrorMassage = (massage) => {
    toast(massage, { type: "error", autoClose: 4000 });
  };

  return (
    <div>
      <LoadingSpinner isLoading={loadingStatus === apiLoadingStatus.loading} />

      {step === 1 && (
        <Container className={styles["form-container"]}>
          <Row className={styles["logo-container"]}>
            <Col>
              <img src={logo} alt="logo" />
            </Col>
          </Row>
          <Row className={styles["welcome-container"]}>
            <Col>
              <h4>Forgot Password</h4>
            </Col>
          </Row>
          <FormCustomControl
            onContinue={onContinue}
            onBack={() => {
              navigate(-1);
            }}
          >
            <Form.Group className="mb-3">
              <Form.Label className={styles["input-lable"]}>
                Email address
              </Form.Label>
              <Form.Control
                className={
                  emailValidation
                    ? styles["input-text-box"]
                    : styles["input-text-box-warning"]
                }
                type="email"
                placeholder="Enter email"
                defaultValue={email || ""}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                disabled={loadingStatus === apiLoadingStatus.loading}
              />
              {!emailValidation && (
                <Form.Text className={styles["warning-error"]}>
                  <MultiplicationSignCircleIcon /> Something went wrong!
                </Form.Text>
              )}
            </Form.Group>
          </FormCustomControl>
        </Container>
      )}
      {step === 2 && (
        <Container className={styles["form-container"]}>
          <Row className={styles["logo-container"]}>
            <Col>
              <img src={logo} alt="logo" />
            </Col>
          </Row>
          <Row className={styles["welcome-container"]}>
            <Col>
              <h4>Forgot Password</h4>
            </Col>
          </Row>
          <FormCustomControl
            onContinue={() => {
              navigate("/login");
            }}
            onBack={() => {
              setStep(1);
            }}
            continueLabel="OK"
            backDisabled={true}
          >
            <p>Please open the link that we sent to:</p>
            <div className={styles["email-container"]}>
              <Mail01Icon color="#F59C00" />
              <p>{email}</p>
            </div>
          </FormCustomControl>
        </Container>
      )}
    </div>
  );
};

export default RecoveryInit;
