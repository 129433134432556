import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import _ from "lodash";
import {
  ArrowRight01Icon,
  Calendar04Icon,
  Edit02Icon,
  Location04Icon,
  Mail02Icon,
} from "hugeicons-react";
import ToggleSwitch from "../toggle-switch";
import UseUpdateUserOrganizationActivation from "../../hooks/use-update-user-organization-activation";
import { toast } from "react-toastify";
import useHgLocations from "../../hooks/use-hg-locations1";
import TooltipControl from "../tooltip-control/tooltip-control";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

const UserItem = ({
  user = {
    firstName: "",
    lastName: "",
    type: "",
    email: "",
    date: "",
    isActive: null,
    locations: [],
    userId: "",
    updatedById: "",
    overrideAccess: "",
    organizationId: "",
  },
  currentUserId = 0,
}) => {
  const [openLocations, setOpenLocations] = useState(false);

  const [locations, setLocations] = useState(user.locations);
  /// useHgLocations
  const {
    getLocationByIds,
    addLocations,
    deleteLocations,
    setUserType,
    locationCollection,
  } = useHgLocations();
  window.getLocationByIds = getLocationByIds; // hgSiteId; not mistaken with HgId or LenderId or UserId
  // window.addLocations = addLocations;
  // window.deleteLocations = deleteLocations;
  // window.locationCollection = locationCollection;

  const [checked, setChecked] = useState(user.isActive);
  // console.log("first checked", checked, "isActive", !!user.isActive);
  const [isLoading, setIsLoading] = useState(false);

  const updateUserActivation = UseUpdateUserOrganizationActivation();
  const data = {
    isActive: !user.isActive,
    userId: user.userId,
    updatedById: user.updatedById,
    overrideAccess: user.overrideAccess,
    organizationId: user.organizationId,
  };

  const navigate = useNavigate();
  const showMassage = (massage) => {
    massage === "Successfully updated!"
      ? toast(massage, { type: "success", autoClose: 4000, toastId: "success" })
      : toast(massage, { type: "error", autoClose: 4000, toastId: "error" });
  };

  const handleToggle = async () => {
    if (data.userId === data.updatedById) {
      toast("You cannot deactivate yourself!", {
        type: "warning",
        autoClose: 4000,
        toastId: "warning",
      });
      return;
    }

    let actionStatus = null;

    try {
      setIsLoading(true);
      const res = await updateUserActivation(data);
      actionStatus = _.get(res, "headers.action-status", null);
      setIsLoading(false);
      setChecked(!checked);
      // console.log(actionStatus);
    } catch (error) {
      showMassage("Something went wrong!");
      // console.log(error);
    } finally {
      setIsLoading(false);
      switch (actionStatus) {
        default:
          showMassage("Unknown error");
          break;
        case "InvalidCredentials":
          showMassage("Invalid Credientials");
          break;
        case "Failed":
          showMassage("Failed");
          break;
        case "InvalidCredentialsNewlyLockedOut":
          showMassage("Invalid Credientials Newly LockedOut");
          break;
        case "UsedBefore":
          showMassage("UsedBefore");
          break;
        case "SameAsPrevious":
          showMassage("Same As Previous");
          break;
        case "AdminLockedOut":
          showMassage("Your account was just locked out.");
          break;
        case "ValidationError":
          showMassage("Validation Error");
          break;
        case "NotExists":
          showMassage("Not Exists");
          break;
        case "Forbidden":
          showMassage("Forbidden");
          break;
        case "Success":
          showMassage("Successfully updated!");
          break;
      }
    }

    // // .finally(() => {
    //   console.log(users);
    //   setLoadingStatus(apiLoadingStatus.loaded);
    //   switch (actionStatus) {
    //     default:
    //       showMassage("Unknown error");
    //       break;
    //     case "InvalidCredentials":
    //       showMassage("Invalid Credientials");
    //       break;
    //     case "Failed":
    //       showMassage("Failed");
    //       break;
    //     case "InvalidCredentialsNewlyLockedOut":
    //       showMassage("Invalid Credientials Newly LockedOut");
    //       break;
    //     case "UsedBefore":
    //       showMassage("UsedBefore");
    //       break;
    //     case "SameAsPrevious":
    //       showMassage("Same As Previous");
    //       break;
    //     case "AdminLockedOut":
    //       showMassage("Your account was just locked out.");
    //       break;
    //     case "ValidationError":
    //       showMassage("Validation Error");
    //       break;
    //     case "NotExists":
    //       showMassage("Not Exists");
    //       break;
    //     case "Forbidden":
    //       showMassage("Forbidden ");
    //       break;
    //     case "Success":
    //       break;
    //   }
    // });
  };

  useEffect(() => {
    setChecked(user.isActive);
  }, [user.isActive]);

  const getLocations = async (arrayOfIds) => {
    const loc = await getLocationByIds(arrayOfIds);
    return loc;
  };

  useEffect(() => {
    getLocations(user.locations).then((res) => {
      // console.log(user.locations);
      // console.log(res);
      setLocations(res);
    });

    // setLocations(loc);
  }, []);

  return (
    <>
      {/* <div>{user.isActive ? "true" : "false"}</div>
      <div>{checked ? "true" : "false"}</div> */}
      <div className={styles["container"]}>
        <div className={`${styles["row"]} ${styles["first"]}`}>
          <div className={styles["name-box"]}>
            {user.firstName} {user.lastName}
            {user.type && (
              <div className={styles["user-type"]}>{user.type}</div>
            )}
          </div>
          <div className={styles["date-box"]}>
            {user.date && (
              <div className={styles["date"]}>
                <Calendar04Icon />
                <span>{user.date}</span>
              </div>
            )}
            {user.type && (
              <TooltipControl content={"Edit User"}>
                <button
                  className={styles["download-icon-box"]}
                  onClick={() => {
                    navigate(`/user-management/edit/${user.userId}`);
                  }}
                >
                  <Edit02Icon className={styles["download-icon"]} />
                </button>
              </TooltipControl>
            )}
            {currentUserId != user.userId && user.type && (
              <TooltipControl
                content={checked ? "Deactivate" : "Activate"}
                placement={"top"}
              >
                <div>
                  <ToggleSwitch
                    isChecked={checked}
                    callbackFunction={handleToggle}
                    isLoading={isLoading}
                  />
                </div>
              </TooltipControl>
            )}
          </div>
        </div>

        <div className={styles["row-wrapper"]}>
          <div className={`${styles["second"]}`}>
            <span className={styles["icons"]}>
              <Mail02Icon className={styles["email-icon"]} />
            </span>
            <span className={styles["values"]}>{user.email}</span>
          </div>
          <div className={styles["right-side"]}>
            <div className={styles["location-icon"]}>
              <Location04Icon />
            </div>
            <div className={styles["location-nums"]}>
              <span className={styles["num"]}>{locations.length}</span>
              <span className={styles["text"]}> Locations</span>
            </div>
            <div
              className={styles["show-more"]}
              onClick={() => {
                setOpenLocations(!openLocations);
              }}
            >
              <span className={styles["text"]}>
                {openLocations ? "Show Less" : "Show More"}
              </span>
              <span
                className={`${styles["icon"]} ${
                  openLocations && styles["rotate"]
                }`}
              >
                <ArrowRight01Icon />
              </span>
            </div>
          </div>
        </div>

        <div
          className={`${styles["row-wrapper-two"]} ${
            !openLocations && styles["closed"]
          }`}
          // style={!openLocations ? { display: "none" } : { display: "flex" }}
        >
          {locations.map((location, index) => {
            {
              return (
                // <>
                <div
                  className={
                    styles[
                      `${index !== locations.length - 1 ? "first" : "second"}`
                    ]
                  }
                  key={index}
                >
                  <div className={styles["first-item"]}>
                    <div className={styles["location-icon"]}>
                      <Location04Icon />
                    </div>
                    <span className={styles["value"]}>
                      {location?.officeName}
                    </span>
                  </div>
                  <div className={styles["address-wrapper"]}>
                    <div className={styles["second-item"]}>
                      <span className={styles["value"]}>
                        {location?.contactFirstName} {location?.contactLastName}
                      </span>
                    </div>
                    <div className={styles["third-item"]}>
                      <span className={styles["value"]}>
                        {location?.city} {location?.state} {location?.address1}
                      </span>
                    </div>
                  </div>
                </div>
                // </>
              );
            }
          })}
        </div>
      </div>
    </>
  );
};

UserItem.propTypes = {
  currentUserId: PropTypes.number,
};

export default UserItem;
