import React from "react";
import PropTypes from "prop-types";
import { Col, Container, Row } from "react-bootstrap";
import { HgLendersSelectControl, HgSitesSelectControl, LenderHGSiteSelectControl, LenderHGsSelectControl } from "../../components/select-control";
import Layout from "../../layout";

const Test = (props) => {
  return (
    <Layout>
      <Container style={{ paddingTop: 2, gap: 30 }}>
        <Row>
          <Col xs={12} lg={6}>
            <HgLendersSelectControl />
          </Col>
          <Col xs={12} lg={6}>
            <HgSitesSelectControl />
          </Col>
          <Col xs={12} lg={6}>
            <LenderHGSiteSelectControl />
          </Col>
          <Col xs={12} lg={6}>
            <LenderHGsSelectControl />
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

Test.propTypes = {};

export default Test;
