import { PrinterIcon } from "hugeicons-react";
import FormCustomControl from "../form-custom-control/form-custom-control";
import styles from "./styles.module.scss";
import React, { useEffect, useRef, useState } from "react";
import moment from "moment/moment";

const NewApplicationPrint = ({ setStep }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={styles["print-wrapper"]}>
      {/* <FormCustomControl
          onContinue={() => setStep(3)}
          onBack={() => setStep(1)}
          continueLabel="Agree"
          backLabel="Disagree"
        > */}
      <div className={styles["print-box"]}>
        <h3>IMPORTANT NOTE</h3>
        <p>
          You are required to acquire the applicant's consent before proceeding
          to the next step
        </p>
        <div
          className={styles["button"]}
          onClick={() => {
            window.print();
          }}
        >
          <PrinterIcon /> Print
        </div>
      </div>
      <div>
        <div className={styles["printed-area"]}>
          <h4>Consumer Consent Disclosure for Dentirate.com</h4>
          <p>
            By providing your email address and/or mobile phone number, you
            consent to receive electronic communications from{" "}
            <b>Dentirate.com ("we", "our")</b>. Below are details regarding the
            communications you may receive and how to manage your preferences.
          </p>
          <h4>Types of Communications:</h4>
          <ul>
            <li>
              <b>Transactional:</b> Information about your financing offers.
            </li>
            <li>
              <b>Notifications:</b> Reminders and alerts
            </li>
            <li>
              <b>Marketing:</b> Promotional materials, updates, offers, and
              newsletters
            </li>
            <li>
              <b>Feedback & Surveys:</b> Requests for feedback and participation
              in surveys or other data-gathering exercises.
            </li>
            <li>
              <b>General Updates:</b> Any other information we believe may
              benefit or interest you.
            </li>
          </ul>

          <h4>Consent Details:</h4>
          <ul>
            <li>
              By providing your <b>email</b>, you permit us to send emails to
              the address you've shared.
            </li>
            <li>
              By sharing your <b>mobile number</b>, you agree to receive text
              messages from us.
            </li>
          </ul>
          <h4>Message & Data Charges:</h4>
          <p>
            Please be aware that certain mobile carriers may charge you for
            receiving our messages. Dentirate.com is not liable for any such
            charges.
          </p>
          <h4>Managing Your Preferences:</h4>
          <ul>
            <li>
              <b>Emails:</b> To stop receiving emails, click the “unsubscribe”
              link found at the bottom of our emails.
            </li>
            <li>
              <b>Texts:</b> Reply with "STOP" to any text message you receive
              from us to opt out.
            </li>
            <li>
              Please allow up to 10 business days for changes to your
              preferences to take effect.
            </li>
          </ul>
          <h4>Electronic Record Access:</h4>
          <p>
            Your agreement to this disclosure confirms that you can access this
            consent, other electronic records, and the emails we send.
          </p>
        </div>
      </div>
      <p className={styles["terms-of-use"]}>
        For additional details, please review our{" "}
        <a href="https://www.dentirate.com/terms/">Terms of Use</a> and{" "}
        <a href="https://www.dentirate.com/privacy-policy/">Privacy Policy.</a>
      </p>
      <div className={styles["applicant-signature"]}>
        <span>Applicant's Name (Printed):</span>
        <span>Applicant's Signature:</span>
        <span>Date: {moment().format("MM/DD/YYYY")}</span>
      </div>
      {/* </FormCustomControl> */}
    </div>
  );
};

export default NewApplicationPrint;
