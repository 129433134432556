import React from "react";
import PropTypes from "prop-types";
import { Container, Form, Col, Row, Button } from "react-bootstrap";
import styles from "./styles.module.scss";

const FormCustomControl = ({
  children = <></>,
  onContinue = () => {},
  continueLabel = "Continue",
  displayContinue = true,
  continueDisabled = false,
  onBack = () => {},
  backLabel = "Back",
  displayBackContinue = true,
  backDisabled = false,
}) => {
  return (
    <Form
      noValidate
      className={styles["container"]}
      onSubmit={(e) => {
        e.preventDefault();
        onContinue();
      }}
    >
      <Container className={styles["bootstrap-container"]}>
        <Row>
          <Col>{children}</Col>
        </Row>
        <Row className={styles["btn-row"]}>
          <Col xs={4} md={3} className={styles["btn-container"]}>
            {displayBackContinue && (
              <Button
                disabled={backDisabled}
                className={styles["btn"]}
                onClick={onBack}
                variant="light"
              >
                {backLabel}
              </Button>
            )}
          </Col>
          <Col xs={8} md={9} className={styles["btn-container"]}>
            {displayContinue && (
              <Button
                disabled={continueDisabled}
                type="submit"
                className={styles["btn"]}
              >
                {continueLabel}
              </Button>
            )}
          </Col>
        </Row>
      </Container>
    </Form>
  );
};

FormCustomControl.propTypes = {};

export default FormCustomControl;
