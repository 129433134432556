import _ from "lodash";
import { getObjectPropertyNames } from "../../../../infrastructure/helpers";

const defaultLocation = {
  // id: undefined,

  locationInfo: {
    corporateName: null,
    associatedPracticeName: null,
    officeName: null,
    taxId: null,
    typeOfOwnership: null,
    totalNumberOfEmployees: null,
    dateBusinessStarted: null,
    businessType: null,
    practiceManagementSoftware: null,
    officePhoneNumber: null,
    faxNumber: null,
    officeWebsite: null,
  },

  physicalAddress: {
    physicalStreet1: null,
    physicalStreet2: null,
    physicalZipcode: null,
    physicalState: null,
    physicalCity: null,
  },

  mailingAddress: {
    mailingStreet1: null,
    mailingStreet2: null,
    mailingZipcode: null,
    mailingState: null,
    mailingCity: null,
  },

  ownerInfo: {
    ownerFirstName: null,
    ownerLastName: null,
    ownerLicenseNumber: null,
    ownerStreet1: null,
    ownerStreet2: null,
    ownerZipcode: null,
    ownerState: null,
    ownerCity: null,
    ownerCellPhone: null,
    ownerEmailAddress: null,
  },

  managingDoctor: {
    doctorFirstName: null,
    doctorLastName: null,
    doctorLicenseNumber: null,
    doctorLicenseState: null,
  },

  contactInfo: {
    contactFirstName: null,
    contactLastName: null,
    contactOfficePhoneNumber: null,
    contactEmailAddress: null,
  },

  bankInfo: {
    bankName: null,
    routingNumber: null,
    accountNumber: null,
    bankLetter: null,
  },

  // identificationProvided: true,
  // identificationImage: null,
  // preferredLanguage: null,
  // cellPhone: null,
  // homePhone: null,
  // workPhone: null,
  // annualIncome: 0,
  // preferredMonthlyPayment: 0,
  // emailAddress: null,

  // apartmentOrUnit: null,
  // hasAvaiableCreditLine: null,
  // availableCreditLine: 0,
  // initialConsentTimestamp: null,
  // hgPatientReferenceId: null,

  // locationStatus: "Initiated",
  // hg_SiteId: 0,
  // submitApplication: true,
  // updateKey: null,
};
// const mockData = {
//   id: undefined,
//   treatmentType: "GENERALDENTISTRY",
//   amount: 2500,
//   applicantInfo: {
//     firstName: "Alex",
//     lastName: "Kh",
//     middleName: null,
//     ssn: "123456788",
//     ssnLast4: null,
//     noSsn: false,
//     doB: "2006-02-02",
//     identificationProvided: true,
//     identificationType: "DriversLicense",
//     identificationImage: null,
//     preferredLanguage: "en",
//     cellPhone: "4695346099",
//     homePhone: null,
//     workPhone: null,
//     annualIncome: 0,
//     preferredMonthlyPayment: 0,
//     emailAddress: "alex.baqeri@gmail.com",
//     street1: "1239 Bernard St",
//     street2: null,
//     zipcode: "76201",
//     state: "TX",
//     city: "Dallas",
//     apartmentOrUnit: null,
//     hasAvaiableCreditLine: true,
//     availableCreditLine: 2500,
//     initialConsentTimestamp: "2024-05-09T16:04:23Z",
//     hgPatientReferenceId: "9",
//   },
//   applicationStatus: "Initiated",
//   hg_SiteId: 2,
//   submitApplication: true,
//   updateKey: null,
// };
const getDefaultLocation = () => _.cloneDeep(defaultLocation);
// const getDefaultApplication = () => _.cloneDeep(mockData);
const defaultLocationValidtions = (() => {
  const propertyNames = getObjectPropertyNames(defaultLocation);
  const output = _.cloneDeep(defaultLocation);
  propertyNames.forEach((propertyName) => {
    _.set(output, propertyName, true);
  });
  return output;
})();
const getDefaultLocationValidations = () =>
  _.cloneDeep(defaultLocationValidtions);

export { getDefaultLocation, getDefaultLocationValidations };
