import { apiUrls } from "../infrastructure/constants/api";
import apiHelperMethods from "../infrastructure/helpers/api-helpers";

const useDownloadKpi = () => {
  const getData = async ({
    userType,
    startDate,
    endDate,
    timeZoneId,
    hgKpiType,
    siteIdCollection,
    lenderIdCollection,
    userIdCollection,
  }) => {
    const data = {
      userType,
      startDate,
      endDate,
      timeZoneId,
      hgKpiType,
      siteIdCollection,
      lenderIdCollection,
      userIdCollection,
    };
    let url;
    switch (userType) {
      case "HealthcareGroup":
        url = apiUrls.hgUrls.downloadKpiDetails;
        break;
      case "Lender":
        url = apiUrls.lenderUrls.downloadKpiAggregated;
        break;
      default:
        url = apiUrls.hgUrls.downloadKpiDetails;

        break;
    }
    return apiHelperMethods.getWithReturn(url, data);
    // .then((res) => {
    //   let tempData = res;
    //   console.log(tempData);
    //   console.log(tempData.headers.get("Content-Disposition"));
    //   // if (!tempData) {
    //   //   return Promise.reject("No data found!");
    //   // }

    //   // return Promise.resolve(tempData);
    // });
  };
  return getData;
};

export default useDownloadKpi;
