import React, { useEffect, useMemo, useState } from "react";
import { Col, Container, Row, Form } from "react-bootstrap";
import logo from "../../../images/logo.png";
import styles from "./styles.module.scss";
import LoadingSpinner from "../../../components/loading-spinner/loading-spinner";
import { apiLoadingStatus } from "../../../infrastructure/constants/api";
import FormCustomControl from "../../../components/form-custom-control";
import { useQuery } from "../../../hooks";
import _ from "lodash";
import useRecoveryOpen from "../../../hooks/use-recovery-open";
import { toast } from "react-toastify";
import useChangePassword from "../../../hooks/use-change-password";
import { useLocation, useNavigate } from "react-router-dom";
import {
  AlertCircleIcon,
  CheckmarkCircle02Icon,
  MultiplicationSignCircleIcon,
  ResetPasswordIcon,
  ViewIcon,
  ViewOffIcon,
} from "hugeicons-react";

const ChangePassword = () => {
  const [loadingStatus, setLoadingStatus] = useState(apiLoadingStatus.unloaded);
  const [password, setPassword] = useState({
    newPassword: "",
    confirmPassword: "",
  });
  // const [matchPasswords, setMatchPasswords] = useState(null);
  const [minCharactersPass, setMinCharactersPass] = useState(null);
  const [upperCasePass, setUpperCasePass] = useState(null);
  const [minNumPass, setMinNumPass] = useState(null);
  const [symbolFormatPass, setSymbolFormatPass] = useState(null);
  // const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  // const [passwordValidation, setPasswordValidation] = useState({
  //   matchPasswords: true,
  //   minCharactersPass: true,
  //   upperCasePass: true,
  //   minNumPass: true,
  //   symbolFormatPass: true,
  // });
  const location = useLocation();
  const [step, setStep] = useState(1);
  const [message, setMessage] = useState("");
  let actionStatus = undefined;
  const queryHelper = useQuery();
  const navigate = useNavigate();
  const handleRecoveryOpen = useRecoveryOpen();
  const changePassword = useChangePassword();

  const code = useMemo(() => queryHelper.getValue("code"), [location.search]);

  const showErrorMassage = (massage) => {
    toast(massage, { type: "error", autoClose: 4000, toastId: "error" });
  };

  const validatePassword = (event) => {
    const symbolFormat = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    if (event.target.value.length < 8) {
      setMinCharactersPass(false);
    } else {
      setMinCharactersPass(true);
    }
    if (!/\d/.test(event.target.value)) {
      // console.log("setminNumPass", !/\d/.test("passw4ord.newPassword"));
      setMinNumPass(false);
    } else {
      setMinNumPass(true);
    }
    if (event.target.value !== event.target.value.toLowerCase()) {
      setUpperCasePass(true);
    } else {
      setUpperCasePass(false);
    }
    if (!symbolFormat.test(event.target.value)) {
      setSymbolFormatPass(false);
    } else {
      setSymbolFormatPass(true);
    }
    // if (event.target.value !== password.confirmPassword) {
    //   setMatchPasswords(false);
    // } else {
    //   setMatchPasswords(true);
    // }
    // console.log("match", matchPasswords);
  };

  const onContinue = () => {
    // validatePassword();
    if (
      password.newPassword === "" ||
      !minCharactersPass ||
      !minNumPass ||
      !upperCasePass ||
      !symbolFormatPass ||
      password.newPassword !== password.confirmPassword
    ) {
      return;
    }
    setLoadingStatus(apiLoadingStatus.loading);

    changePassword({
      password: password.confirmPassword,
      code: code,
    })
      .then((res) => {
        actionStatus = _.get(res, "headers.action-status", null);
      })
      .catch((err) => {
        actionStatus = _.get(err, "response.headers.action-status", null);
      })
      .finally(() => {
        setLoadingStatus(apiLoadingStatus.loaded);
        switch (actionStatus) {
          default:
            setMessage("Unknown error");
            setStep(3);
            break;
          case "Failed":
          case "ValidationError":
            setMessage("New Password is Invalid");
            setStep(3);
            break;
          case "NotExist":
            setMessage("Link does not exists");
            setStep(3);
            break;
          case "Expired":
            setMessage("link has been expiered");
            setStep(3);
            break;
          case "AdminLockedOut":
            setMessage("Your account was just locked out.");
            setStep(3);
            break;
          case "Used":
            setMessage("This password has been already used");
            setStep(3);
            break;
          case "Success":
            setMessage("Your password has been successfully changed!");
            setStep(3);
            break;
        }
      });
  };

  useEffect(() => {
    setLoadingStatus(apiLoadingStatus.loading);
    handleRecoveryOpen(code)
      .then((res) => {
        actionStatus = _.get(res, "headers.action-status", null);
      })
      .catch((err) => {
        actionStatus = _.get(err, "response.headers.action-status", null);
      })
      .finally(() => {
        setLoadingStatus(apiLoadingStatus.loaded);
        switch (actionStatus) {
          default:
            showErrorMassage("Unknown error");
            break;
          case "Failed":
          case "ValidationError":
            showErrorMassage("Invalid Credientials");
            break;
          case "NotExist":
            showErrorMassage("Link does not exists");
            break;
          case "Expired":
            showErrorMassage("link has been expiered");
            break;
          case "AdminLockedOut":
            showErrorMassage("Your account was just locked out.");
            break;
          case "Used":
            showErrorMassage("This link has been already used");
            break;
          case "Success":
            setStep(2);
            break;
        }
      });
  }, []);

  return (
    <div className={styles["main"]}>
      <LoadingSpinner isLoading={loadingStatus === apiLoadingStatus.loading} />

      {step === 2 && (
        <Container className={styles["container"]}>
          <Row className={styles["logo-container"]}>
            <Col>
              <img src={logo} alt='logo' />
            </Col>
          </Row>
          <Row className={styles["welcome-container"]}>
            <Col>
              <h4>Change Password</h4>
            </Col>
          </Row>
          <FormCustomControl onContinue={onContinue} onBack={() => {}}>
            <Form.Group className='mb-3'>
              <Form.Label className={styles["input-lable"]}>
                New Password
              </Form.Label>
              <div className={styles["input-container"]}>
                <Form.Control
                  className={
                    (minCharactersPass == null &&
                      minNumPass == null &&
                      upperCasePass == null &&
                      symbolFormatPass == null) ||
                    (minCharactersPass &&
                      minNumPass &&
                      upperCasePass &&
                      symbolFormatPass)
                      ? styles["input-text-box"]
                      : styles["input-text-box-warning"]
                  }
                  type={showConfirmPassword ? "text" : "password"}
                  placeholder='password'
                  defaultValue={password.newPassword || ""}
                  onChange={(e) => {
                    setPassword({
                      ...password,
                      newPassword: e.target.value,
                    });
                    validatePassword(e);
                  }}
                  disabled={loadingStatus === apiLoadingStatus.loading}
                />
                <div
                  className={styles["view-icon"]}
                  onMouseDown={() => {
                    setShowConfirmPassword(!showConfirmPassword);
                  }}
                  onMouseUp={() => {
                    setShowConfirmPassword(!showConfirmPassword);
                  }}
                >
                  {!showConfirmPassword ? <ViewIcon /> : <ViewOffIcon />}
                </div>
              </div>
              <Form.Text
                className={`${styles["hint"]} ${
                  minCharactersPass === true && styles["checked"]
                } ${minCharactersPass === false && styles["warning-error"]}`}
              >
                {minCharactersPass === null && <AlertCircleIcon />}
                {minCharactersPass === true && <CheckmarkCircle02Icon />}
                {minCharactersPass === false && (
                  <MultiplicationSignCircleIcon />
                )}
                At least 8 Characters
              </Form.Text>

              <Form.Text
                className={`${styles["hint"]} ${
                  upperCasePass === true && styles["checked"]
                } ${upperCasePass === false && styles["warning-error"]}`}
              >
                {upperCasePass === null && <AlertCircleIcon />}
                {upperCasePass === true && <CheckmarkCircle02Icon />}
                {upperCasePass === false && <MultiplicationSignCircleIcon />} At
                least one Uppercase
              </Form.Text>

              <Form.Text
                className={`${styles["hint"]} ${
                  symbolFormatPass === true && styles["checked"]
                } ${symbolFormatPass === false && styles["warning-error"]}`}
              >
                {symbolFormatPass === null && <AlertCircleIcon />}
                {symbolFormatPass === true && <CheckmarkCircle02Icon />}
                {symbolFormatPass === false && (
                  <MultiplicationSignCircleIcon />
                )}{" "}
                At least one Symbol
              </Form.Text>

              <Form.Text
                className={`${styles["hint"]} ${
                  minNumPass === true && styles["checked"]
                } ${minNumPass === false && styles["warning-error"]}`}
              >
                {minNumPass === null && <AlertCircleIcon />}
                {minNumPass === true && <CheckmarkCircle02Icon />}
                {minNumPass === false && <MultiplicationSignCircleIcon />} At
                least one Number
              </Form.Text>
            </Form.Group>
            <Form.Group className='mb-3'>
              <Form.Label className={styles["input-lable"]}>
                Re-enter new password
              </Form.Label>
              <div className={styles["input-container"]}>
                <Form.Control
                  className={
                    password.confirmPassword === "" ||
                    password.confirmPassword === password.newPassword
                      ? styles["input-text-box"]
                      : styles["input-text-box-warning"]
                  }
                  type={showConfirmPassword ? "text" : "password"}
                  placeholder='Re-enter new password'
                  defaultValue={password.confirmPassword || ""}
                  onChange={(e) => {
                    setPassword({
                      ...password,
                      confirmPassword: e.target.value,
                    });
                  }}
                  disabled={loadingStatus === apiLoadingStatus.loading}
                />
                <div
                  className={styles["view-icon"]}
                  onMouseDown={() => {
                    setShowConfirmPassword(!showConfirmPassword);
                  }}
                  onMouseUp={() => {
                    setShowConfirmPassword(!showConfirmPassword);
                  }}
                >
                  {!showConfirmPassword ? <ViewIcon /> : <ViewOffIcon />}
                </div>
              </div>
              {password.newPassword !== password.confirmPassword &&
                password.confirmPassword !== "" && (
                  <Form.Text
                    className={`${styles["hint"]} ${styles["warning-error"]}`}
                  >
                    <MultiplicationSignCircleIcon />
                    Passwords don't matched!
                  </Form.Text>
                )}
            </Form.Group>
          </FormCustomControl>
        </Container>
      )}

      {step === 3 && (
        <Container className={styles["container"]}>
          <Row className={styles["logo-container"]}>
            <Col>
              <img src={logo} alt='logo' />
            </Col>
          </Row>
          <Row className={styles["welcome-container"]}>
            <Col>
              <h4>Change Password</h4>
            </Col>
          </Row>
          <FormCustomControl
            onContinue={() => {
              navigate("/login");
            }}
            continueLabel='OK'
            backDisabled={true}
          >
            <p>
              <ResetPasswordIcon color='#007775' width='30px' height='30px' />
            </p>
            <div className={styles["email-container"]}>
              <p>{message}</p>
            </div>
          </FormCustomControl>
        </Container>
      )}
    </div>
  );
};

export default ChangePassword;
