import React, { useState } from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.scss";

const ToggleSwitch = ({
  text = "",
  callbackFunction = () => {},
  isChecked = true,
  isLoading = false,
}) => {
  // const [checked, setChecked] = useState(true);
  // !!isChecked ? setChecked(!!isChecked) : setChecked(!isChecked);
  // const [isLoading, setIsLoading] = useState(false);
  return (
    <label className={styles["switch"]}>
      {text}
      <input
        type='checkbox'
        checked={isChecked}
        // isLoading={isLoading}
        onClick={() => {
          // !isLoading && console.log("yes!");
          !isLoading && callbackFunction();
          // setChecked(!checked);
        }}
        onChange={(e) => {
          // callbackFunction(e.target.checked);
          // console.log(e.target.checked);
        }}
      />
      <span
        className={`${styles["slider"]} ${isLoading ? styles["loading"] : ""}`}
      ></span>
    </label>
  );
};

ToggleSwitch.propTypes = {};

export default ToggleSwitch;
