import React from "react";
import PropTypes from "prop-types";
import ModalContainer from "../../../components/modal-control";
import styles from "./styles.module.scss";

const NewApplicationModal = ({
  show = false,
  handleClose = () => {},
  message = "",
  children,
}) => {
  return (
    <ModalContainer
      show={show}
      handleClose={handleClose}
      modalSize={"lg"}
      modalTitle={"Application Created"}
      centered
    >
      <div className={styles["modal-content"]}>{message || ""}</div>
      {children}
    </ModalContainer>
  );
};

NewApplicationModal.propTypes = {
  show: PropTypes.bool,
  handleClose: PropTypes.func,
  message: PropTypes.string,
};

export default NewApplicationModal;
