import { Col, Container, Row } from "react-bootstrap";
import styles from "./styles.module.scss";
import { useEffect, useState } from "react";
import { apiLoadingStatus } from "../../../infrastructure/constants/api";
import LoadingSpinner from "../../loading-spinner/loading-spinner";
import { toast } from "react-toastify";
import _ from "lodash";
import useGetActiveUsers from "../../../hooks/use-get-active-users";
import { Location01Icon, UserGroupIcon } from "hugeicons-react";

const ActiveUsersControl = ({ filters = {} }) => {
  const [isLoading, setIsLoading] = useState(apiLoadingStatus.loaded);
  const [activeUsersData, setActiveUsersData] = useState({
    locationCount: 0,
    userCount: 0,
  });
  const showErrorMassage = (massage) => {
    toast(massage, { type: "error", autoClose: 4000, toastId: "error" });
  };
  const getSummary = useGetActiveUsers();
  useEffect(() => {
    // return;
    let actionStatus = null;
    setIsLoading(apiLoadingStatus.loading);
    getSummary(filters)
      .then(([res, status]) => {
        actionStatus = status;
        setActiveUsersData(res);
      })
      .catch((err) => {
        actionStatus = _.get(err, "response.headers.action-status", null);
        console.log(err);
      })
      .finally(() => {
        setIsLoading(apiLoadingStatus.loaded);
        switch (actionStatus) {
          default:
            showErrorMassage("Unknown error");
            break;
          case "Failed":
            showErrorMassage("Failed");
            break;
          case "UsedBefore":
            showErrorMassage("Used Before");
            break;
          case "SameAsPrevious":
            showErrorMassage("Same As Previous");
            break;
          case "AdminLockedOut":
            showErrorMassage("Admin LockedOut ");
            break;
          case "InvalidCredentialsNewlyLockedOut":
            showErrorMassage("Invalid Credentials Newly LockedOut");
            break;
          case "InvalidCredentials":
            showErrorMassage("Invalid Credentials");
            break;
          case "ValidationError":
            showErrorMassage("Validation Error");
            break;
          case "NotExists":
            showErrorMassage("Not Found");
            break;
          case "Forbidden":
            showErrorMassage("Forbidden ");
            break;
          case "Success":
            break;
        }
      });
  }, [filters]);

  return (
    <Container className={styles["container"]}>
      <div className={styles["row"]}>
        <div className={`${styles["col"]} ${styles["user"]}`}>
          <div className={styles["count"]}>{activeUsersData.userCount}</div>
          <div className={styles["title"]}>
            <UserGroupIcon className={styles["icon"]} /> Active Users
          </div>
        </div>

        <div className={`${styles["col"]} ${styles["location"]}`}>
          <div className={styles["count"]}>{activeUsersData.locationCount}</div>
          <div className={styles["title"]}>
            <Location01Icon className={styles["icon"]} /> Locations
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ActiveUsersControl;
