export const hgKpiTopLenderCollection = [
  {
    healthCareId: 20000,
    label: "#1-Zachary Montgomery",
    totalSubmittedCount: 306,
    totalPreApprovedCount: 259,
    totalLeadCount: 159,
    preApprovedRate: 0.85,
    leadRate: 0.61,
    totalSubmittedAmount: 9046867,
    totalPreApprovedAmount: 8584185,
    totalLeadAmount: 4375209,
    avgSubmittedAmount: 29564.92,
    avgPreApprovedAmount: 33143.57,
    avgLeadAmount: 27517.04,
    preApprovedAmtRate: 0.95,
    leadAmtRate: 0.51,
  },
  {
    healthCareId: 20000,
    label: "#2-Sophia Davis",
    totalSubmittedCount: 302,
    totalPreApprovedCount: 259,
    totalLeadCount: 157,
    preApprovedRate: 0.86,
    leadRate: 0.61,
    totalSubmittedAmount: 8292244,
    totalPreApprovedAmount: 8114559,
    totalLeadAmount: 3925873,
    avgSubmittedAmount: 27457.76,
    avgPreApprovedAmount: 31330.34,
    avgLeadAmount: 25005.56,
    preApprovedAmtRate: 0.98,
    leadAmtRate: 0.48,
  },
  {
    healthCareId: 20000,
    label: "#3-Mia Lee",
    totalSubmittedCount: 282,
    totalPreApprovedCount: 240,
    totalLeadCount: 147,
    preApprovedRate: 0.85,
    leadRate: 0.61,
    totalSubmittedAmount: 8240597,
    totalPreApprovedAmount: 8156794,
    totalLeadAmount: 4139352,
    avgSubmittedAmount: 29221.98,
    avgPreApprovedAmount: 33986.64,
    avgLeadAmount: 28158.86,
    preApprovedAmtRate: 0.99,
    leadAmtRate: 0.51,
  },
  {
    healthCareId: 20000,
    label: "#4-Ryan Murphy",
    totalSubmittedCount: 289,
    totalPreApprovedCount: 249,
    totalLeadCount: 141,
    preApprovedRate: 0.86,
    leadRate: 0.57,
    totalSubmittedAmount: 8175995,
    totalPreApprovedAmount: 7597373,
    totalLeadAmount: 3488006,
    avgSubmittedAmount: 28290.64,
    avgPreApprovedAmount: 30511.54,
    avgLeadAmount: 24737.63,
    preApprovedAmtRate: 0.93,
    leadAmtRate: 0.46,
  },
  {
    healthCareId: 20000,
    label: "#5-Abigail Scott",
    totalSubmittedCount: 296,
    totalPreApprovedCount: 240,
    totalLeadCount: 139,
    preApprovedRate: 0.81,
    leadRate: 0.58,
    totalSubmittedAmount: 9308240,
    totalPreApprovedAmount: 8503267,
    totalLeadAmount: 3966660,
    avgSubmittedAmount: 31446.76,
    avgPreApprovedAmount: 35430.28,
    avgLeadAmount: 28537.12,
    preApprovedAmtRate: 0.91,
    leadAmtRate: 0.47,
  },
  {
    healthCareId: 20000,
    label: "Other",
    totalSubmittedCount: 4962,
    totalPreApprovedCount: 4172,
    totalLeadCount: 2389,
    preApprovedRate: 0.84,
    leadRate: 0.57,
    totalSubmittedAmount: 145587516,
    totalPreApprovedAmount: 137083867,
    totalLeadAmount: 66031390,
    avgSubmittedAmount: 29340.49,
    avgPreApprovedAmount: 32858.07,
    avgLeadAmount: 27639.76,
    preApprovedAmtRate: 0.94,
    leadAmtRate: 0.48,
  },
];
