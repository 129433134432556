const hgUrls = {
  getOrganizationSiteListPaginated:
    "/api/HealthcareGroup/GetOrganizationSiteListPaginated",
  downloadKpiDetails: "/api/ClientDashboard/Hg/DownloadKpiDetails",
  getActiveUsers: `/api/ClientDashboard/Hg/ActiveUsers`,
  getKpiSummary: `/api/ClientDashboard/Hg/KpiSummary`,
  getKpiOverVew: `/api/ClientDashboard/Hg/KpiOverview`,
  getKpiTopLenders: `/api/ClientDashboard/Hg/KpiTopLenders`,
  updateLocationActivation: `/api/HealthcareGroup/ActivateHgSite`,
  UpsertLocation: "/api/HealthcareGroup/UpsertHgSites",
  userUpdateLocation: "/api/HealthcareGroup/UpsertUsersInHgSites",
};

export default hgUrls;
