// src/stores/locationStore.js

import { apiUrls } from "../infrastructure/constants/api";
import apiHelperMethods from "../infrastructure/helpers/api-helpers";

class LocationStore {
  constructor(userType) {
    this.userType = userType;
    this.locations = {};
    this.listeners = [];
  }

  setUserType = (newUserType) => {
    this.userType = newUserType;
    // Optionally reset locations or fetch new ones
    this.locations = {};
    this.notify();
  };

  // Fetch locations by IDs
  getLocationByIds = async (ids = [], forceReload = false) => {
    if (!Array.isArray(ids)) {
      ids = [ids];
    }

    const idsToFetch = [];
    const fetchedLocations = {};

    ids.forEach((id) => {
      if (!forceReload && this.locations[id]) {
        fetchedLocations[id] = this.locations[id];
      } else {
        idsToFetch.push(id);
      }
    });

    if (idsToFetch.length > 0) {
      const newLocations = await this.fetchLocationsFromAPI(idsToFetch);
      newLocations.forEach((location) => {
        this.locations[location.id] = location;
        fetchedLocations[location.id] = location;
      });
      this.notify();
    }

    return ids.map((id) => fetchedLocations[id] || null);
  };

  // Add multiple locations
  addLocations = (locationsArray) => {
    locationsArray.forEach((location) => {
      this.locations[location.id] = location;
    });
    this.notify();
  };

  getLocations = () => {
    return Object.values(this.locations);
  };

  // Delete locations by IDs
  deleteLocations = (ids = []) => {
    if (!Array.isArray(ids)) {
      ids = [ids];
    }

    ids.forEach((id) => {
      delete this.locations[id];
    });
    this.notify();
  };

  // Subscribe to store updates
  subscribe = (listener) => {
    this.listeners.push(listener);
    // Return an unsubscribe function
    return () => {
      this.listeners = this.listeners.filter((l) => l !== listener);
    };
  };

  // Notify all subscribers of state changes
  notify = () => {
    this.listeners.forEach((listener) => listener([...this.getLocations()]));
  };

  // Fetch multiple locations from API
  fetchLocationsFromAPI = async (ids = []) => {
    if (ids.length === 0) {
      return [];
    }

    let url;
    switch (this.userType?.toLowerCase()) {
      case "healthcaregroup":
        url = apiUrls.hgUrls.getOrganizationSiteListPaginated;
        break;
      case "lender":
        url = apiUrls.lenderUrls.getLenderHgSitesPaginated;
        break;
      default:
        return [];
    }

    try {
      const response = await apiHelperMethods.getWithReturn(url, {
        HgSiteIdCollection: ids,
        start: 0,
        records: ids.length,
      });
      return (
        response.data?.data?.hgSiteCollection ||
        response.data?.data?.hg_SiteCollection ||
        []
      );
    } catch (error) {
      // Handle error accordingly
      console.error("Failed to fetch locations:", error);
      return [];
    }
  };
}

let locationStoreInstance = new LocationStore();

export const initializeLocationStore = (userType) => {
  locationStoreInstance = new LocationStore(userType);
  return locationStoreInstance;
};

export const getLocationStore = () => {
  return locationStoreInstance;
};
