const treatmentType = {
  generalDentistry: "generalDentistry",
  orthodontics: "orthodontics",
  otherspecialties: "otherSpecialties",
  getValueByText: (v) => {
    if (!v || typeof v !== "string" || v.length === 0) {
      return null;
    }
    switch (v.toLowerCase()) {
      default:
        return null;
      case "GeneralDentistry".toLocaleLowerCase():
        return treatmentType.generalDentistry;
      case "Orthodontics".toLocaleLowerCase():
        return treatmentType.orthodontics;
      case "OtherSpecialties".toLocaleLowerCase():
        return treatmentType.otherspecialties;
    }
  },
  getValuesByText: (arr) => {
    if (!arr || !Array.isArray(arr) || arr.length === 0) {
      return [];
    }
    const output = [];
    for (let i in arr) {
      switch (i.toLowerCase()) {
        default:
          break;
        case "generalDentistry".toLocaleLowerCase():
          output.push(treatmentType.generalDentistry);
        case "Orthodontics".toLocaleLowerCase():
          output.push(treatmentType.orthodontics);
        case "OtherSpecialties".toLocaleLowerCase():
          output.push(treatmentType.otherspecialties);
      }
    }
    return output;
  },
  collection: [
    // { label: "None", value: undefined },
    { label: "GeneralDentistry", value: "generalDentistry" },
    { label: "Orthodontics", value: "orthodontics" },
    { label: "OtherSpecialties", value: "otherSpecialties" },
  ],
};

export default treatmentType;
