import React, { forwardRef, useRef } from "react";
import PropTypes from "prop-types";
import "./select-control.scss";
import styles from "./styles.module.scss";
import { AsyncPaginate } from "react-select-async-paginate";

const innerStyles = {
  menu: (provided, state) => ({
    ...provided,
    zIndex: 100,
  }),
};

const SelectAsyncControl = forwardRef(
  (
    {
      value = null,
      disabled = false,
      multi = false,
      onChange = () => {},
      placeholder = "Select",
      clearable = true,
      customStyles = {},
      isSearchable = true,
      getOptionLabel = (o) => (o ? o.label : ""),
      getOptionValue = (o) => (o ? o.label : ""),
      menuPlacement = "auto",
      onKeyDown = () => {},
      tabIndex = 0,
      loadOptions = () => {},
      className = "",
      classNamePrefix = "react-select",
      debounceTimeout = 600,
      menuIsOpen = undefined,
    },
    ref
  ) => {
    const dropdownStyles = customStyles
      ? { ...innerStyles, ...customStyles }
      : innerStyles;

    return (
      <AsyncPaginate
        tabIndex={tabIndex}
        selectRef={ref}
        className={`${styles["select-control"]} ${className}`}
        loadOptions={loadOptions}
        styles={dropdownStyles}
        value={value}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        onChange={onChange}
        isSearchable={isSearchable}
        isClearable={clearable}
        placeholder={placeholder}
        isMulti={multi}
        isDisabled={disabled}
        additional={{
          page: 1,
        }}
        menuPlacement={menuPlacement}
        onKeyDown={(e) => {
          if (ref?.current?.props?.menuIsOpen) {
            e.stopPropagation();
            return;
          }
          onKeyDown(e);
        }}
        classNamePrefix={classNamePrefix}
        debounceTimeout={debounceTimeout}
        menuIsOpen={menuIsOpen}
      />
    );
  }
);

const optionType = PropTypes.shape({
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
});
SelectAsyncControl.propTypes = {
  value: PropTypes.oneOfType([optionType, PropTypes.arrayOf(optionType)]),
  disabled: PropTypes.bool,
  clearable: PropTypes.bool,
  multi: PropTypes.bool,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  customStyles: PropTypes.shape({}),
  isSearchable: PropTypes.bool,
  getOptionLabel: PropTypes.func,
  getOptionValue: PropTypes.func,
  loadOptions: PropTypes.func.isRequired,
  menuPlacement: PropTypes.string,
  className: PropTypes.string,
  classNamePrefix: PropTypes.string,
  onKeyDown: PropTypes.func,
  tabIndex: PropTypes.number,
  debounceTimeout: PropTypes.number,
  menuIsOpen: PropTypes.bool,
};

export default SelectAsyncControl;
