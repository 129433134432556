import qs from "qs";

const queryStringHelpers = {
  getQueryString: (paramName, defaultValue) => {
    const query = new URLSearchParams(window.location.search);
    const value = query.get(paramName);
    return value != null ? value : defaultValue;
  },

  /** Helper function to generate a URLSearchParams for axios. Current use case is when there are multiple fields with *same* key in query param
   *  @ref https://stackoverflow.com/questions/42898009/multiple-fields-with-same-key-in-query-params-axios-request
   *  @params {[{key: value}]} Array of parameter objects, as key value pairs
   *  @return a URLSearchParams object
   */
  formatParams: (params) => {
    let params_ = new URLSearchParams();
    params.forEach((param) =>
      Object.entries(param).forEach(([key, val]) =>
        Array.isArray(val)
          ? val.forEach((val_) => params_.append(key, val_))
          : params_.append(key, val)
      )
    );
    return params_;
  },
  stringify: (obj) => {
    // Handle case when the input is undefined or not an object
    if (!obj || typeof obj !== "object") {
      return undefined;
    }

    // Remove null, undefined, or empty arrays
    const cleanedObj = Object.fromEntries(
      Object.entries(obj).filter(
        ([, value]) =>
          value != null && !(Array.isArray(value) && value.length === 0)
      )
    );

    // Stringify the cleaned object
    const queryString = qs.stringify(cleanedObj, { arrayFormat: "repeat" });

    // If query string is empty, return undefined
    return queryString ? queryString : undefined;
  },
};
export default queryStringHelpers;
