import React, { useContext, useReducer } from "react";
import getCommonContextInitalValues from "./intial-values";
import commonContextReducer from "./reducers";
import PropTypes from "prop-types";

const CommonContext = React.createContext(null);

const CommonContextProvider = ({ children = <></> }) => {
  const initalValues = getCommonContextInitalValues();
  const [state, dispatch] = useReducer(
    (state, action) => commonContextReducer(state, action),
    initalValues
  );
  return (
    <CommonContext.Provider value={[state, dispatch]}>
      {children}
    </CommonContext.Provider>
  );
};

CommonContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

const useCommonContext = () => {
  const [state, dispatch] = useContext(CommonContext);

  return [state, dispatch];
};

export { CommonContextProvider, useCommonContext };
