import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.scss";
import {
  BankIcon,
  Location04Icon,
  UserAccountIcon,
  UserIcon,
} from "hugeicons-react";

const HgReportSelectControl = ({
  callbackfunction = () => {},
  choice = "",
  title = "Show data",
}) => {
  return (
    <>
      <div className={styles["container"]}>
        {!!title && <div className={styles["title"]}>{title}</div>}
        <div className={styles["options"]}>
          <div
            onClick={() => {
              callbackfunction("User");
            }}
            className={`${styles["option"]} ${
              choice === "User" && styles["selected"]
            }`}
          >
            <UserIcon />
            User
          </div>
          <div
            onClick={() => {
              callbackfunction("Location");
            }}
            className={`${styles["option"]} ${
              choice === "Location" && styles["selected"]
            }`}
          >
            <Location04Icon />
            <span>Location</span>
          </div>
          <div
            onClick={() => {
              callbackfunction("Lender");
            }}
            className={`${styles["option"]} ${
              choice === "Lender" && styles["selected"]
            }`}
          >
            <BankIcon />
            Lender
          </div>
        </div>
      </div>
    </>
  );
};

HgReportSelectControl.propTypes = {
  choice: PropTypes.oneOf(["User", "Location", "Lender"]),
  callbackfunction: PropTypes.func,
};

export default HgReportSelectControl;
