import React from "react";
import styles from "./styles.module.scss";
import {
  CalendarCheckIn01Icon,
  Link01Icon,
  MoneyExchange01Icon,
  ProfitIcon,
  TaskDone01Icon,
} from "hugeicons-react";
import { Container, Col, Row } from "react-bootstrap";
import { commaSeperatedCurrency } from "../../infrastructure/helpers";

const BillingCard = ({
  date = null,
  paymentStatus = "",
  numberOfLeads = 0,
  amount = 0,
  dueDate = null,
}) => {
  return (
    <div className={styles["container"]}>
      <div className={styles["top-section"]}>
        <div className={styles["date"]}>{date}</div>
        <div className={styles["top-left-section"]}>
          <div className={styles["payment-status"]}>
            <ProfitIcon size={34} color="#757575" />
            <p>Payment Status</p>
            <div className={`${styles["status"]} ${styles[paymentStatus]}`}>
              {paymentStatus}
            </div>
          </div>
          <div className={styles["link-icon"]}>
            <Link01Icon size={24} />
          </div>
        </div>
      </div>
      <Container className={styles["bottom-section"]}>
        <Row>
          <Col md={3}>
            <Row className={styles["first-column"]}>
              <Col xs={9} sm={12} md={12} lg={9} className={styles["column"]}>
                <div>
                  <TaskDone01Icon color="#757575" className={styles["icon"]} />
                </div>
                <p>Number of Leads</p>
              </Col>
              <Col xs={3} sm={12} md={12} lg={3}>
                <div
                  className={`${styles["number-of-leads"]} ${styles["data-column"]}`}
                >
                  {numberOfLeads}
                </div>
              </Col>
            </Row>
          </Col>
          <Col md={4}>
            <Row className={styles["middle-column"]}>
              <Col xs={8} sm={12} md={12} lg={8} className={styles["column"]}>
                <MoneyExchange01Icon
                  color="#757575"
                  className={styles["icon"]}
                  size={24}
                />
                <p>Amunt Due</p>
              </Col>
              <Col xs={4} sm={12} md={12} lg={4}>
                <div
                  className={`${styles["amount-due"]} ${styles["data-column"]}`}
                >
                  {commaSeperatedCurrency(amount, 0)}
                </div>
              </Col>
            </Row>
          </Col>
          <Col md={5}>
            <Row className={styles["last-column"]}>
              <Col xs={6} sm={12} md={12} lg={6}>
                <CalendarCheckIn01Icon
                  color="#757575"
                  className={styles["icon"]}
                />
                <p>Due Date</p>
              </Col>
              <Col xs={6} sm={12} md={12} lg={6}>
                <div
                  className={`${styles["due-date"]} ${styles["data-column"]}`}
                >
                  {dueDate}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        {/* <Row className={styles["middle-row"]}>
            <Col xs={4} className={styles["first-col"]}>
              <Row>
                <Col xs={12} md={8} className={styles["title-col"]}>
                  <div className={styles["middle-icons"]}>
                    <TaskDone01Icon className={styles["wallet-icon"]} />
                  </div>
                  <div className={styles["title"]}>Number of Leads</div>
                </Col>
                <Col xs={12} md={4} className={styles["value-col"]}>
                  <span className={styles["value"]}>
                    {numberOfLeads.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                      maximumFractionDigits: 0,
                    })}
                  </span>
                </Col>
              </Row>
            </Col>

            <Col xs={4} className={styles["middle-col"]}>
              <Row>
                <Col xs={12} md={6} className={styles["title-col"]}>
                  <div className={styles["middle-icons"]}>
                    <MoneyExchange01Icon className={styles["medical-icon"]} />
                  </div>
                  <div className={styles["title"]}>Amount Due</div>
                </Col>
                <Col xs={12} md={6} className={styles["value-col"]}>
                  <div className={styles["value"]}>{amount}</div>
                </Col>
              </Row>
            </Col>

            <Col xs={4} className={styles["last-col"]}>
              <Row>
                <Col
                  xs={12}
                  md={8}
                  className={styles["title-col"]}
                  style={{ paddingRight: 0 }}
                >
                  <div className={styles["middle-icons"]}>
                    <CalendarCheckIn01Icon className={styles["payment-icon"]} />
                  </div>
                  <div className={styles["title"]}>Due Date</div>
                </Col>
                <Col xs={12} md={4} className={styles["value-col"]}>
                  <span className={styles["value"]}>
                    {dueDate.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                      maximumFractionDigits: 0,
                    })}
                  </span>
                </Col>
              </Row>
            </Col>
          </Row> */}
      </Container>
    </div>
  );
};

export default BillingCard;
