import { apiUrls } from "../infrastructure/constants/api";
import apiHelperMethods from "../infrastructure/helpers/api-helpers";

const useUpdatePassword = () => {
  const postData = ({ userId, oldPassword, newPassword }) => {
    const data = {
      userId,
      oldPassword,
      newPassword,
    };

    const [results] = apiHelperMethods.postWithReturn(
      apiUrls.userIdentityUrls.udatePassword,
      data
    );
    return results;
  };
  return postData;
};

export default useUpdatePassword;
