import _ from "lodash";
import { apiUrls } from "../infrastructure/constants/api";
import apiHelperMethods from "../infrastructure/helpers/api-helpers";

import axios from "axios";

const UseUpdateLocationActivation = () => {
  const updateActivation = ({ hgSiteId, isActive }) => {
    return axios.post(apiUrls.hgUrls.updateLocationActivation, {
      hgSiteId,
      isActive,
    });
  };

  return updateActivation;
};

export default UseUpdateLocationActivation;
