import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Alert, Button } from "react-bootstrap";
import styles from "./styles.module.scss";
import { sendPostByJson } from "../../utils/httpUtils";
import { getDefaultApplication } from "../applications/form/utils/utils";
import ApplicantVerification from "./applicant-verification";
import { apiLoadingStatus, apiUrls } from "../../infrastructure/constants/api";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import ApplicantConsent from "./applicant-consent";
import Logo from "./logo";
import ApplicantResults from "./results";
import { commonContextActions, useCommonContext } from "../../common-context";
import { FormattedMessage, useIntl } from "react-intl";
import { MailSend02Icon } from "hugeicons-react";
import ApplicationProcessing from "./application-processing";
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import LoadingSpinner from "../../components/loading-spinner";
import ApplicantInfo from "./applicant-info";

const steps = {
  errorInFlow: -1,
  none: 0,
  verification: 1,
  applicantInfo: 2,
  consent: 3,
  processing: 4,
  results: 5,
};
const Applicant = () => {
  const navigate = useNavigate();
  const intl = useIntl();
  const [{ config }, dispatchCommonContext] = useCommonContext();
  const { applicationId: id } = useParams();
  const [searchParams] = useSearchParams();
  const [step, setStep] = useState(steps.none);
  const [loadingStatus, setLoadingStatus] = useState(apiLoadingStatus.unloaded);
  const accessKey = useMemo(() => searchParams.get("accessKey"), []);
  const [application, setApplication] = useState(getDefaultApplication());
  const applicationRef = useRef(application);
  const [lenderCollection, setLenderCollection] = useState([]);
  const [logoCollection, setLogoCollection] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [verificationReset, setVerificationReset] = useState(0);
  const [initialInfo, setInitialInfo] = useState({
    id: null,
    zipCode: null,
    yearOfBirth: null,
    accessKey: null,
  });
  const intialRef = useRef(initialInfo);
  const [signalrConnection, setSignalrConnection] = useState({
    url: null,
    token: null,
    connection: null,
  });
  const getIntlMessage = useCallback(
    (id) => intl.formatMessage({ id }),
    [intl]
  );

  const handleStepsByStatus = ({
    applicationStatus,
    consumerApplication,
    lenderCollection,
    logoCollection,
  }) => {
    setApplication({
      ...application,
      ...(consumerApplication || {}),
      applicationStatus: applicationStatus,
    });
    applicationRef.current = {
      ...application,
      ...(consumerApplication || {}),
      applicationStatus: applicationStatus,
    };
    setLenderCollection([...(lenderCollection || [])]);
    setApplication(consumerApplication || null);
    setLogoCollection([...(logoCollection || [])]);
    switch (applicationStatus) {
      case "None":
        setErrorMessage(getIntlMessage("Errors.InvalidApplication"));
        setStep(steps.errorInFlow);
        return;
      case "Initiated":
        setErrorMessage(getIntlMessage("Errors.Initiated"));
        setStep(steps.errorInFlow);
        return;
      case "PendingApplicantSubmission":
        if (step === steps.verification) {
          setStep(steps.applicantInfo);
        } else {
          setStep(steps.consent);
        }

        break;
      case "Processing":
      case "Submitted":
        setStep(steps.processing);
        break;
      case "LenderLinkClicked":
      case "ApplicantApplied":
      case "ProcessedSuccessfulyWithApproval":
      case "ProcessedNoApproval":
      case "ProcessedNoLender":
        setStep(steps.results);
        break;
      case "Abandoned":
        setErrorMessage(getIntlMessage("Errors.Abandoned"));
        setStep(steps.errorInFlow);
        break;
      default:
      case "ProcessingError":
        setErrorMessage(getIntlMessage("Errors.ProcessingError"));
        setStep(steps.errorInFlow);
        break;
    }
  };

  const initiateApplication = async () => {
    if (loadingStatus === apiLoadingStatus.loading) {
      return;
    }
    setLoadingStatus(apiLoadingStatus.loading);
    setErrorMessage(null);

    const url = "/Applicant/InitiateApplicationByAccessKey";
    sendPostByJson(url, {
      id,
      accessKey,
      connectionId: signalrConnection?.connection?.connectionId,
    })
      .then((res) => {
        if (res.status != 200 || !res.data?.data?.status) {
          return Promise.resolve(getIntlMessage("Errors.UnknownError"));
        }
        if (!res.data.data.isAccessible) {
          setErrorMessage(getIntlMessage("Errors.NotAccessible"));
          setStep(steps.errorInFlow);
          return;
        }
        dispatchCommonContext({
          type: commonContextActions.updateLanguage,
          payload: res.data.data?.language,
        });
        setStep(steps.verification);
      })
      .catch((err) => {
        setStep(steps.errorInFlow);
        if (!err?.response?.status) {
          getIntlMessage("Errors.UnableToLoadApplication");
        } else if (
          err.response.headers.get("Action-Status") ===
          "UnsuccessfulAttemptsReached"
        ) {
          setErrorMessage(getIntlMessage("Errors.ApplicantTooManyAttempts"));
        } else {
          setErrorMessage(getIntlMessage("Errors.InvalidLink"));
        }
      })
      .finally(() => {
        setLoadingStatus(apiLoadingStatus.unloaded);
      });
  };

  const onContinue = ({ zipCode, yearOfBirth }) => {
    if (loadingStatus === apiLoadingStatus.loading) {
      return;
    }
    setLoadingStatus(apiLoadingStatus.loading);
    setErrorMessage(null);

    const url = "/Applicant/ApplicationByAccessKey";
    sendPostByJson(url, {
      id,
      accessKey,
      zipCode,
      yearOfBirth,
      connectionId: signalrConnection?.connection?.connectionId,
    })
      .then((res) => {
        setInitialInfo({ id, accessKey, zipCode, yearOfBirth });
        if (res.status !== 200 || !res.data?.data?.status) {
          return Promise.resolve(getIntlMessage("Errors.UnknownError"));
        }
        if (!res.data.data.isAccessible) {
          setErrorMessage(getIntlMessage("Errors.ApplicationExpired"));
          setStep(steps.errorInFlow);
          return;
        }
        handleStepsByStatus({
          applicationStatus: res.data?.data?.status,
          consumerApplication: res.data?.data?.consumerApplication,
          lenderCollection: res.data?.data?.lenderCollection,
          logoCollection: res.data?.data?.logoCollection,
        });
      })
      .catch((err) => {
        if (!err?.response?.status) {
          setErrorMessage(getIntlMessage("Errors.UnableToLoadApplication"));
        } else if (
          err.response.headers.get("Action-Status") ===
          "UnsuccessfulAttemptsReached"
        ) {
          setErrorMessage(getIntlMessage("Errors.ApplicantTooManyAttempts"));

          setStep(steps.errorInFlow);
        } else if (err.response.status === 403) {
          setVerificationReset(Math.random());
          setErrorMessage(getIntlMessage("Errors.InvalidEntries"));
        } else if (err.response.status >= 500) {
          setErrorMessage(getIntlMessage("Errors.UnknownError"));
          setStep(steps.errorInFlow);
        } else {
          setErrorMessage(getIntlMessage("Errors.InvalidLink"));
          setStep(steps.errorInFlow);
        }
      })
      .finally(() => {
        setLoadingStatus(apiLoadingStatus.unloaded);
      });

    return;
  };

  const onUpdateApplicantInfo = ({
    newApplicantInfo = {
      ssn: null, // XXX-XX-XXXX
      noSsn: true,
      preferredMonthlyPayment: null,
      annualIncome: null,
      availableCreditLine: null,
      hasAvailableCreditLine: false,
    },
    gotoNextStep = false,
  }) => {
    // console.log(newApplicantInfo);
    if (gotoNextStep) {
      handleStepsByStatus({
        applicationStatus: application.applicationStatus,
        consumerApplication: {
          ...application,
          applicantInfo: {
            ...application.applicantInfo,
            ssn: newApplicantInfo.ssn,
            noSsn: newApplicantInfo.noSsn,
            preferredMonthlyPayment: newApplicantInfo.preferredMonthlyPayment,
            annualIncome: newApplicantInfo.annualIncome,
            availableCreditLine: newApplicantInfo.availableCreditLine,
            hasAvaiableCreditLine: newApplicantInfo.hasAvailableCreditLine,
          },
        },
        lenderCollection: lenderCollection,
        logoCollection: logoCollection,
      });
    } else {
      setApplication({
        ...application,
        applicantInfo: {
          ...application.applicantInfo,
          ssn: newApplicantInfo.ssn,
          noSsn: newApplicantInfo.noSsn,
          preferredMonthlyPayment: newApplicantInfo.preferredMonthlyPayment,
          annualIncome: newApplicantInfo.annualIncome,
          availableCreditLine: newApplicantInfo.availableCreditLine,
          hasAvailableCreditLine: newApplicantInfo.hasAvailableCreditLine,
        },
      });
    }
  };

  const submitApplication = () => {
    if (loadingStatus === apiLoadingStatus.loading) {
      return;
    }
    setLoadingStatus(apiLoadingStatus.loading);
    setErrorMessage(null);
    const url = "/Applicant/SubmitApplicationByApplicant";
    const data = {
      accessKey,
      id,
      treatmentType: application?.treatmentType,
      applicantInfo: application?.applicantInfo,
      updateKey: application?.updateKey,
      submitApplication: true,
      amount: application.amount,
      connectionId: signalrConnection?.connection?.connectionId,
    };
    // window.json = JSON.stringify(data);

    sendPostByJson(url, data)
      .then((res) => {
        if (res.status != 200) {
          return Promise.resolve(getIntlMessage("Errors.UnknownError"));
        }
        if (!res.data.data.isAccessible) {
          setErrorMessage(getIntlMessage("Errors.ApplicationExpired"));

          setStep(steps.errorInFlow);
          return;
        }
        handleStepsByStatus({
          applicationStatus: res.data?.data?.status,
          consumerApplication: res.data?.data?.consumerApplication,
          lenderCollection: res.data?.data?.lenderCollection,
          logoCollection: res.data?.data?.logoCollection,
        });
      })
      .catch((err) => {
        setStep(steps.errorInFlow);
        if (!err?.response?.status) {
          setErrorMessage(getIntlMessage("Errors.UnableToLoadApplication"));
        } else if (
          err.response.headers.get("Action-Status") ===
          "UnsuccessfulAttemptsReached"
        ) {
          setErrorMessage(getIntlMessage("Errors.ApplicantTooManyAttempts"));

          setStep(steps.errorInFlow);
        } else if (err.response.status === 403) {
          setErrorMessage(getIntlMessage("Errors.InvalidEntries"));
        } else {
          setErrorMessage(getIntlMessage("Errors.InvalidLink"));
        }
      })
      .finally(() => {
        setLoadingStatus(apiLoadingStatus.unloaded);
      });
  };

  const onApplicationStatusUpdated = (message) => {
    try {
      // validating message
      if (
        !message ||
        message.applicationId !== applicationRef.current?.id ||
        !message.applicationStatus
      ) {
        return;
      }
      const currentStatus = message.applicationStatus;
      switch (currentStatus) {
        case "Submitted":
        case "Processing":
          setApplication({
            ...applicationRef.current,
            applicationStatus: currentStatus,
          });
          setStep(steps.processing);
          break;
        default:
          onContinue({
            zipCode: intialRef.current.zipCode,
            yearOfBirth: intialRef.current.yearOfBirth,
          });
          break;
      }
    } catch (e) {
      console.log(e);
    }
  };

  const connectToSignalR = async () => {
    try {
      // Initialize the SignalR connection
      const hubConnection = new HubConnectionBuilder()
        .withUrl(apiUrls.hubs.actionsHub)
        .configureLogging(LogLevel.Information)
        .withAutomaticReconnect()
        .build();

      // Start the connection
      await hubConnection.start();
      // console.log("SignalR connection established!");

      // Handle incoming messages
      hubConnection.on("APPLICATION_UPDATED", (message) => {
        onApplicationStatusUpdated(message);
      });

      // Set the connection state
      setSignalrConnection({
        ...signalrConnection,
        connection: hubConnection,
      });
    } catch (error) {
      console.error("Error connecting to SignalR:", error);
    }
  };
  const disconnectSignalR = async () => {
    if (typeof signalrConnection?.connection?.stop === "function") {
      signalrConnection.connection.stop();
    }
  };
  const isInitiatedValid = useMemo(
    () => id && accessKey && id.length > 0 && accessKey.length > 0,
    []
  );

  useEffect(() => {
    if (isInitiatedValid) {
      initiateApplication();
    } else {
      setStep(steps.errorInFlow);
    }
  }, [isInitiatedValid]);

  useEffect(() => {
    if (!!signalrConnection.connection) {
      return;
    }
    connectToSignalR();

    return () => {
      disconnectSignalR();
    };
  }, []);

  useEffect(() => {
    applicationRef.current = application;
  }, [application]);

  useEffect(() => {
    intialRef.current = initialInfo;
  }, [initialInfo]);
  // useEffect(() => {
  //   if (step === steps.verification) {
  //     onContinue({
  //       zipCode: "76201",
  //       yearOfBirth: 2006,
  //     });
  //   } else if (step === steps.consent) {
  //     submitApplication();
  //   }
  // }, [step]);

  useEffect(() => {
    document.title = getIntlMessage("ApplicantPageTitle");
  }, []);
  return (
    <div className={styles["container"]}>
      <LoadingSpinner isLoading={apiLoadingStatus.loading === loadingStatus} />
      {step === steps.errorInFlow && (
        <div
          className={`text-center ${styles["container"]} ${styles["error"]} pb-0`}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Logo />
          <Alert
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              backgroundColor: "transparent",
              border: "none",
              color: "black",
              fontSize: "16px",
              fontWeight: "400",
              height: "100%",
            }}
          >
            <p
              style={{
                color: "#BB0000",
                fontSize: "24px",
                fontWeight: "600",
              }}
            >
              Invalid Link
            </p>
            {errorMessage || <FormattedMessage id='Errors.InvalidLink' />}
            <div className={styles["help-mail"]}>
              <MailSend02Icon />
              <span>help@dentirate.com</span>
            </div>
          </Alert>
          <Button
            variant='dark'
            className={styles["invalid-back"]}
            type='button'
            onClick={() => {
              navigate(-1);
            }}
          >
            Back
          </Button>
        </div>
      )}

      {step === steps.verification && (
        <ApplicantVerification
          reset={verificationReset}
          onContinue={onContinue}
          errorMessage={errorMessage}
        />
      )}

      {step === steps.processing && (
        <ApplicationProcessing
          applicationStatus={application?.applicationStatus || "None"}
        />
      )}

      {step === steps.applicantInfo && (
        <ApplicantInfo onUpdateApplicantInfo={onUpdateApplicantInfo} />
      )}

      {step === steps.consent && (
        <ApplicantConsent
          lenderCollection={lenderCollection}
          errorMessage={errorMessage}
          onConsent={submitApplication}
        />
      )}

      {step === steps.results && (
        <>
          <ApplicantResults
            application={application}
            lenderCollection={lenderCollection}
            initialInfo={initialInfo}
            logoCollection={logoCollection}
          />
        </>
      )}
    </div>
  );
};

Applicant.propTypes = {};

export default Applicant;
