import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ModalContainer from "../modal-control";
import styles from "./styles.module.scss";
import RadioButton from "../radio-button";
import { Col, FormControl, Row, Table } from "react-bootstrap";
import { NumericFormat } from "react-number-format";
import ValidationWrapper from "../validation-wrapper";
import _ from "lodash";
import { HgLendersSelectControl } from "../select-control";
import { useExtendApp } from "../../hooks";
import { apiLoadingStatus } from "../../infrastructure/constants/api";
import LoadingSpinner from "../loading-spinner/loading-spinner";
import { toast } from "react-toastify";

const ExtendApplicationModal = ({
  show = false,
  handleClose = () => {},
  id = "",
  amount = 0,
  refreshapp,
}) => {
  const [availableFundCollection, setAvailableFundCollection] = useState([
    // {
    //   lenderId: 0,
    //   lenderName: "string",
    //   availableFundAmount: 0,
    // },
  ]);
  const [step, setStep] = useState(0);
  const [applicationExtendInfo, setApplicationExtendInfo] = useState({
    id: id,
    applicationRequestType: "",
    annualIncome: 0,
    preferredMonthlyPayment: 0,
    requestedAmount: 0,
    availableFundCollection: [],
  });
  const [applicationValidations, setApplicationValidations] = useState({
    requestedAmount: true,
    applicationRequestType: true,
    lenderName: true,
    availableFundAmount: true,
  });
  const [availableFund, setAvailableFund] = useState({
    lenderObj: null,
    availableFundAmount: null,
  });
  const innerChange = (name, newValue) => {
    setApplicationExtendInfo({
      ...applicationExtendInfo,
      [name]: newValue,
    });
  };
  const [loadingStatus, setLoadingStatus] = useState(apiLoadingStatus.unloaded);

  const extendApp = useExtendApp();
  const addLender = () => {
    // console.log(availableFund);
    if (!availableFund.lenderObj || !availableFund.availableFundAmount) {
      setApplicationValidations({
        ...applicationValidations,
        lenderName: !!availableFund.lenderObj,
        availableFundAmount: !!availableFund.availableFundAmount,
      });
      return;
    }

    setAvailableFundCollection((availableFundCollection) => [
      ...availableFundCollection,
      {
        lenderId: availableFund.lenderObj.id,
        lenderName: availableFund.lenderObj.name,
        availableFundAmount: availableFund.availableFundAmount,
      },
    ]);
    setAvailableFund({
      lenderObj: null,
      availableFundAmount: null,
    });
  };

  const validateAll = () => {
    setApplicationValidations({
      ...applicationValidations,
      requestedAmount:
        !!applicationExtendInfo.requestedAmount &&
        applicationExtendInfo.requestedAmount <= amount,
      applicationRequestType: !!applicationExtendInfo.applicationRequestType,
    });

    return (
      !!applicationExtendInfo.requestedAmount &&
      applicationExtendInfo.requestedAmount <= amount &&
      !!applicationExtendInfo.applicationRequestType
    );
  };

  const showErrorMessage = (message) => {
    toast(message, {
      type: "error",
      toastId: "login error",
    });
  };

  const onSubmit = () => {
    const validation = validateAll();
    if (!validation) {
      return;
    }
    const temp = _.cloneDeep(applicationExtendInfo);
    temp.availableFundCollection = availableFundCollection;
    // console.log(temp);
    setLoadingStatus(apiLoadingStatus.loading);
    // console.log(temp);
    let actionStatus = undefined;
    let data = undefined;
    extendApp(temp)
      .then((res) => {
        actionStatus = _.get(res, "headers.action-status", null);
        data = _.get(res, "data");
      })
      .catch((err) => {
        actionStatus = _.get(err, "response.headers.action-status", null);
      })
      .finally(() => {
        setLoadingStatus(apiLoadingStatus.loaded);
        switch (actionStatus) {
          default:
            break;
          case "ValidationError":
            showErrorMessage("Validation Error");
            break;
          case "NotExists":
            showErrorMessage("Account Not Exists");
            break;
          case "DuplicateEmail":
            showErrorMessage("Email has been used already!");
            break;
          case "InvitationThrottled":
            showErrorMessage("This invitation Already has been sent!");
            break;
          case "InvalidInviter ":
            showErrorMessage("InvalidInviter ");
            break;
          case "LockedOut":
            showErrorMessage("This account is locked out.");
            break;
          case "AdminLockedOut":
            showErrorMessage("Your account was just locked out.");
            break;
          case "Success":
            setStep(1);

            break;
        }
      });
  };
  useEffect(() => {
    setApplicationExtendInfo({
      ...applicationExtendInfo,
      applicationRequestType: "",
      annualIncome: 0,
      preferredMonthlyPayment: 0,
      requestedAmount: 0,
      availableFundCollection: [],
    });
    setStep(0);
  }, [show]);
  return (
    <ModalContainer
      show={show}
      handleClose={() => {
        handleClose(false);
      }}
      modalSize={"lg"}
      modalTitle={" Extend Application"}
      centered
    >
      <LoadingSpinner isLoading={loadingStatus === apiLoadingStatus.loading} />

      {step === 0 && (
        <div className={styles["modal-content"]}>
          <div className={styles["required-note"]}>
            <span className={styles["red-star"]}>*</span> required fields
          </div>
          <Row>
            <Col className="relative" xs={12} md={6} lg={4}>
              <ValidationWrapper
                isValid={applicationValidations.requestedAmount}
                title="$ Treatment Cost"
                titleStar={true}
                errorMessage="Invalid contract value"
                inputId="amount"
              >
                <NumericFormat
                  id="amount"
                  name="number"
                  className={`form-control ${
                    !applicationValidations.requestedAmount && "danger"
                  }`}
                  thousandSeparator={true}
                  prefix={"$"}
                  placeholder=""
                  autoComplete="off"
                  customInput={FormControl}
                  allowNegative={false}
                  decimalScale={0}
                  fixedDecimalScale={true}
                  value={applicationExtendInfo.requestedAmount || ""}
                  onValueChange={(values) => {
                    innerChange("requestedAmount", values.floatValue);
                  }}
                  maxLength={8}
                />
              </ValidationWrapper>
            </Col>
            <Col className="relative" xs={12} md={6} lg={4}>
              {/* <ValidationWrapper
              // isValid={applicationValidations.preferredMonthlyPayment}
              title={"Preferred Monthly Payment"}
              inputId="input-preferred-payment"
              errorMessage="Invalid monthly payment"
            >
              <NumericFormat
                id="input-preferred-payment"
                name="number"
                className="form-control"
                thousandSeparator={true}
                prefix={"$"}
                placeholder=""
                autoComplete="off"
                customInput={FormControl}
                allowNegative={false}
                decimalScale={0}
                fixedDecimalScale={true}
                value={applicationExtendInfo.preferredMonthlyPayment || ""}
                onValueChange={(values) => {
                  innerChange("preferredMonthlyPayment", values.floatValue);
                }}
                maxLength={50}
              />
            </ValidationWrapper> */}
            </Col>

            <Col className="relative" xs={12} md={6} lg={4}>
              {/* <ValidationWrapper
              // isValid={applicationValidations.annualIncome}
              title={"Annual Income"}
              inputId="input-annual-income"
              errorMessage="Invalid annual income"
            >
              <NumericFormat
                id="input-annual-income"
                name="number"
                className="form-control"
                thousandSeparator={true}
                prefix={"$"}
                placeholder=""
                autoComplete="off"
                customInput={FormControl}
                allowNegative={false}
                decimalScale={0}
                fixedDecimalScale={true}
                value={applicationExtendInfo.annualIncome || ""}
                onValueChange={(values) => {
                  innerChange("annualIncome", values.floatValue);
                }}
                maxLength={12}
              />
            </ValidationWrapper> */}
            </Col>

            <Col
              className="relative"
              xs={12}
              md={6}
              lg={4}
              style={{ top: "8px" }}
            ></Col>
          </Row>{" "}
          <ValidationWrapper
            isValid={applicationValidations.applicationRequestType}
            title={"Application Request Type"}
            titleStar={true}
            inputId="application-request-type"
            errorMessage="One of the above options must be selected"
          >
            <Row id="application-request-type">
              <Col xs={12} md={6} lg={4} xl={4} xxl={3}>
                <RadioButton
                  text="Both ThirdParty And In-house financing"
                  style={{ marginTop: "8px" }}
                  onClick={() =>
                    innerChange(
                      "applicationRequestType",
                      "BothThirdPartyAndIHF"
                    )
                  }
                />
              </Col>
              <Col xs={12} md={6} lg={5} xl={4} xxl={3}>
                <RadioButton
                  text="First ThirdParty Then In-house financing"
                  style={{ marginTop: "8px" }}
                  onClick={() =>
                    innerChange(
                      "applicationRequestType",
                      "FirstThirdPartyThenIHF"
                    )
                  }
                />
              </Col>
              <Col xs={12} md={6} lg={5} xl={4} xxl={3}>
                <RadioButton
                  text="Only apply for in-house financing"
                  style={{ marginTop: "8px" }}
                  onClick={() =>
                    innerChange("applicationRequestType", "OnlyIHF")
                  }
                />
              </Col>
              <Col xs={12} md={6} lg={4} xl={4} xxl={3}>
                <RadioButton
                  text="Only apply for ThirdParty"
                  style={{ marginTop: "8px" }}
                  onClick={() =>
                    innerChange("applicationRequestType", "OnlyThirdParty")
                  }
                />
              </Col>
            </Row>
          </ValidationWrapper>
          <Row>
            <Col lg={6}>
              <ValidationWrapper
                isValid={applicationValidations.lenderName}
                title={"Select Lender"}
                titleStar={false}
                inputId="lender"
                errorMessage="Lender must be selected"
              >
                <HgLendersSelectControl
                  id="lender"
                  className={`${styles["input"]} ${
                    !applicationValidations.lenderName && styles["not-valid"]
                  }`}
                  onChange={(value) => {
                    setAvailableFund({
                      ...availableFund,
                      lenderObj: value,
                    });
                    setApplicationValidations({
                      ...applicationValidations,
                      lenderName: true,
                    });
                  }}
                  value={availableFund.lenderObj}
                />
              </ValidationWrapper>
            </Col>
            <Col lg={4}>
              <ValidationWrapper
                isValid={applicationValidations.availableFundAmount}
                title={"Amount"}
                titleStar={false}
                inputId="available-amount"
                errorMessage="Enter amount please"
              >
                <NumericFormat
                  id="available-amount"
                  name="number"
                  className={`form-control ${
                    !applicationValidations.availableFundAmount && "danger"
                  }`}
                  thousandSeparator={true}
                  prefix={"$"}
                  placeholder=""
                  autoComplete="off"
                  customInput={FormControl}
                  allowNegative={false}
                  decimalScale={0}
                  fixedDecimalScale={true}
                  value={availableFund.availableFundAmount || ""}
                  onValueChange={(values) => {
                    setAvailableFund({
                      ...availableFund,
                      availableFundAmount: values.floatValue,
                    });
                    setApplicationValidations({
                      ...applicationValidations,
                      availableFundAmount: true,
                    });
                  }}
                  maxLength={12}
                />
              </ValidationWrapper>
            </Col>
            <Col>
              <ValidationWrapper
              // isValid={applicationValidations.applicationRequestType}
              // title={"Amount"}
              // titleStar={true}
              // inputId="available-amount"
              // errorMessage="Enter amount please"
              >
                <button className={styles["add-btn"]} onClick={addLender}>
                  Add
                </button>
              </ValidationWrapper>{" "}
            </Col>
          </Row>
          {/* <Container> */}
          {availableFundCollection.length > 0 && (
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Lender Name</th>
                  <th>Available Amont</th>
                </tr>
              </thead>
              <tbody>
                {availableFundCollection.map((i, index) => (
                  <tr key={index}>
                    <td>{i.lenderName}</td>
                    <td>{i.availableFundAmount}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
          {/* </Container> */}
          <button className={styles["add-btn"]} onClick={onSubmit}>
            Submit
          </button>
        </div>
      )}
      {step === 1 && (
        <div className={styles["modal-content"]}>
          <h4>Your Application Has Been Successfuly Extended.</h4>
          <button
            className={styles["add-btn"]}
            onClick={() => {
              handleClose(false);
              refreshapp();
            }}
          >
            OK
          </button>
        </div>
      )}
    </ModalContainer>
  );
};

ExtendApplicationModal.propTypes = {
  show: PropTypes.bool,
  handleClose: PropTypes.func,
  message: PropTypes.string,
  refreshapp: PropTypes.func,
};

export default ExtendApplicationModal;
