import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { Button } from "react-bootstrap";
import _ from "lodash";
import Header from "../../../components/header"; // Import Header
import Footer from "../../../components/footer";
import {
  LocationAdd01Icon,
  LocationAdd02Icon,
  LocationCheck01Icon,
  LocationUpdate01Icon,
  LocationUpdate02Icon,
  TaskAdd01Icon,
  TaskEdit01Icon,
} from "hugeicons-react";
import moment from "moment";
import { regexPatterns } from "../../../infrastructure/constants";
import parsePhoneNumber from "libphonenumber-js";
import { getObjectPropertyNames } from "../../../infrastructure/helpers";

import NewLocationInputs from "./location-inputs";
import { useCommonContext } from "../../../common-context";
import ProcessBar from "./process-bar";
import {
  getDefaultLocation,
  getDefaultLocationValidations,
} from "./utils/utils";
import { useLocation, useNavigate } from "react-router-dom";
import { sendPostByJson } from "../../../utils/httpUtils";
import UseUpsertLocation from "../../../hooks/use-upsert-location";
import LoadingSpinner from "../../../components/loading-spinner/loading-spinner";
import { toast } from "react-toastify";
import useHgLocations from "../../../hooks/use-hg-locations1";

const steps = {
  initial: 0,
  consent: 1,
  loanInformation: 2,
  submitted: 3,
  error: 4,
};

const NewLocationForm = ({
  initialLocation = undefined,
  title = "Add New Location",
}) => {
  const upsertLocation = UseUpsertLocation();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const webAddress = useLocation();
  const [{ user, logo }] = useCommonContext();
  const [process, setProcess] = useState([
    { title: "Location Information", status: "in-progress", textWidth: "50px" },
    { title: "Physical Address", status: undefined, textWidth: "40px" },
    { title: "Mailing Address", status: undefined, textWidth: "40px" },
    { title: "Owner Information", status: undefined, textWidth: "50px" },
    { title: "Managing Doctor", status: undefined, textWidth: "45px" },
    { title: "Contact Information", status: undefined, textWidth: "50px" },
    { title: "Bank Information", status: undefined, textWidth: "50px" },
  ]);

  const [location, setLoccation] = useState(
    initialLocation || getDefaultLocation()
  );
  const [locationValidations, setLocationValidations] = useState(
    getDefaultLocationValidations()
  );

  const validate = (name, value, obj) => {
    const result = validateProperty(name, value, obj || location);
    const temp = { ...locationValidations };
    _.set(temp, name, result);
    setLocationValidations(temp);
  };

  const validateProperty = (name, value, obj) => {
    switch (name) {
      case "locationInfo.corporateName":
      case "locationInfo.associatedPracticeName":
      case "locationInfo.officeName":
      case "locationInfo.taxId":
      case "locationInfo.typeOfOwnership":
      case "locationInfo.practiceManagementSoftware":
      case "corporateName":
      case "associatedPracticeName":
      case "officeName":
      case "taxId":
      case "typeOfOwnership":
      case "practiceManagementSoftware":
      // case "locationInfo.officeWebsite":
      case "physicalAddress.physicalStreet1":
      case "physicalAddress.physicalState":
      case "physicalAddress.physicalCity":
      case "physicalStreet1":
      case "physicalState":
      case "physicalCity":
      ////////////
      case "mailingAddress.mailingStreet1":
      case "mailingAddress.mailingState":
      case "mailingAddress.mailingCity":
      case "mailingStreet1":
      case "mailingState":
      case "mailingCity":
      ////////////
      case "ownerInfo.ownerFirstName":
      case "ownerInfo.ownerLastName":
      // case "ownerInfo.ownerLicenseNumber":

      case "ownerFirstName":
      case "ownerLastName":
      // case "ownerLicenseNumber":
      ///////////
      case "managingDoctor.doctorFirstName":
      case "managingDoctor.doctorLastName":
      case "managingDoctor.doctorLicenseNumber":
      case "managingDoctor.doctorLicenseState":
      case "doctorFirstName":
      case "doctorLastName":
      case "doctorLicenseNumber":
      case "doctorLicenseState":
      ////////////
      case "contactInfo.contactFirstName":
      case "contactInfo.contactLastName":
      case "contactFirstName":
      case "contactLastName":
      ////////
      case "bankInfo.bankName":
      case "bankInfo.bankLetter":
      case "bankName":
      case "bankLetter":
        return !!value && typeof value === "string" && value.length > 0;
      // case "amount":
      // case "temp_applicantInfo.annualIncome":
      case "ownerInfo.ownerLicenseNumber":
      case "ownerLicenseNumber":
      /////////
      case "bankInfo.routingNumber":
      case "bankInfo.accountNumber":
      case "routingNumber":
      case "accountNumber":
        return typeof value === "number" && !!value && value > 0;
      case "temp_applicantInfo.preferredMonthlyPayment":
        return (
          typeof value === "number" &&
          !!value &&
          value > 0 &&
          (!obj?.amount || value < obj.amount)
        );
      case "hg_SiteId":
        return !!value && value > 0;
      case "applicantInfo.ssn":
        return (
          !!obj.applicantInfo.noSsn ||
          (!!value &&
            typeof value === "string" &&
            value.length > 0 &&
            !!value.trim().match(/^\d{9}$/gi))
        );
      case "applicantInfo.initialConsentTimestamp":
        return !!value && moment(value) && moment(value).isValid();
      case "locationInfo.dateBusinessStarted":
      case "dateBusinessStarted":
        return (
          !!value &&
          moment(value) &&
          moment(value).isValid() &&
          moment(value).isSameOrBefore(moment())
          // && moment(value).isAfter(moment().add(-100, "y")) &&
          // moment(value).isSameOrBefore(moment().add(-18, "y"))
        );

      case "physicalAddress.physicalZipcode":
      case "mailingAddress.mailingZipcode":
      case "physicalZipcode":
      case "mailingZipcode":
        return (
          !!value &&
          typeof value === "string" &&
          value.length > 0 &&
          !!value.trim().match(/^\d{5}$/)
        );

      case "ownerInfo.ownerZipcode":
      case "ownerZipcode":
        if (value !== null) {
          return (
            !!value &&
            typeof value === "string" &&
            value.length > 0 &&
            !!value.trim().match(/^\d{5}$/)
          );
        } else {
          return true;
        }
      case "ownerInfo.ownerEmailAddress":
      case "contactInfo.contactEmailAddress":
      case "ownerEmailAddress":
      case "contactEmailAddress":
        return (
          !!value &&
          typeof value === "string" &&
          value.length > 0 &&
          !!regexPatterns.email.test(value)
        );
      case "locationInfo.officeWebsite":
      case "officeWebsite":
        return (
          !!value &&
          typeof value === "string" &&
          value.length > 0 &&
          !!regexPatterns.website.test(value)
        );
      case "applicantInfo.hasAvaiableCreditLine":
        return value != null;
      case "locationInfo.officePhoneNumber":
      case "contactInfo.contactOfficePhoneNumber":
      case "officePhoneNumber":
      case "contactOfficePhoneNumber":
      case "ownerInfo.ownerCellPhone":
      case "ownerCellPhone": {
        const phoneNumber = parsePhoneNumber(value || "", "US");
        return (
          !!value &&
          typeof value === "string" &&
          value.length > 0 &&
          !!phoneNumber &&
          phoneNumber.isPossible() &&
          phoneNumber.isValid()
        );
      }
      case "faxNumber":
      case "locationInfo.faxNumber": {
        const phoneNumber = parsePhoneNumber(value || "", "US");
        // console.log(value);
        if (value !== null) {
          return (
            !!value &&
            typeof value === "string" &&
            value.length >= 0 &&
            !!phoneNumber &&
            phoneNumber.isPossible() &&
            phoneNumber.isValid()
          );
        } else {
          return true;
        }
      }
      case "applicantInfo.homePhone":
      case "applicantInfo.workPhone": {
        const phoneNumber = parsePhoneNumber(value || "", "US");
        return (
          !value ||
          (typeof value === "string" &&
            (value.length === 0 ||
              (!!phoneNumber &&
                phoneNumber.isPossible() &&
                phoneNumber.isValid())))
        );
      }
      case "applicantInfo.emailAddress":
        return !!value && typeof value === "string" && value;
      case "applicantInfo.identificationProvided":
      case "applicantInfo.identificationType":
      default:
        return true;
    }
  };

  const validateAll = (object) => {
    ////////////
    const mainObject = object;
    const propertyNames = getObjectPropertyNames({ ...mainObject });
    let isValid = true;

    switch (object) {
      case location.locationInfo:
      case location.physicalAddress:
      case location.mailingAddress:
      case location.ownerInfo:
      case location.managingDoctor:
      case location.contactInfo:
      case location.bankInfo:
        // const mainObjectValidations = locationValidations.locationInfo;
        // console.log(locationValidations.locationInfo);
        // console.log(propertyNames);
        // const temp = { ...locationValidations };
        for (let i = 0; i < propertyNames.length; i += 1) {
          const propertyName = propertyNames[i];
          if (!propertyName) {
            continue;
          }
          const v = _.get(mainObject, propertyName);
          // console.log(v);
          const result = validateProperty(
            propertyName,
            _.get(mainObject, propertyName),
            mainObject
          );
          // console.log(result, propertyName);
          // _.set(temp, propertyName, result);
          isValid = isValid && result;
        }
        // setLocationValidations(temp);
        return isValid;

      ///////////
      default:
        // const propertyNames = getObjectPropertyNames({ ...location });
        // let isValid = true;
        const temp = { ...locationValidations };

        for (let i = 0; i < propertyNames.length; i += 1) {
          const propertyName = propertyNames[i];
          if (!propertyName) {
            continue;
          }
          const v = _.get(location, propertyName);
          const result = validateProperty(
            propertyName,
            _.get(location, propertyName),
            location
          );
          _.set(temp, propertyName, result);
          isValid = isValid && result;
        }
        setLocationValidations(temp);
        return isValid;
    }
  };
  const showMassage = (massage) => {
    massage === "Successfully added!" || massage === "Successfully edited!"
      ? toast(massage, { type: "success", autoClose: 4000, toastId: "success" })
      : massage === "Please contact DentiRate to activate the office"
      ? toast(massage, { type: "warning", autoClose: 4000, toastId: "warning" })
      : toast(massage, { type: "error", autoClose: 4000, toastId: "error" });
  };
  const submitLocation = async (shouldLocationBeSubmitted) => {
    if (!validateAll(location)) {
      return;
    }
    // if (!validateAll() || loadingStatus === apiLoadingStatus.loading) {
    //   return;
    // }
    // setLoadingStatus(apiLoadingStatus.loading);
    // const url = !isExistingApplication
    //   ? "/Application/AddConsumerApplication"
    //   : "/Application/UpdateApplication";
    const data = {
      ...location,
      // applicantInfo: {
      //   ...application.applicantInfo,
      //   noSsn: application.applicantInfo.noSsn || false,
      // },
      // submitApplication: !!shouldLocationBeSubmitted,
    };
    let payload = {
      // id: 0,
      nickname: "string",
      hgGroupCode: "string",
      hG_Id: 0,
      corporateName: data.locationInfo.corporateName,
      // storeName: "string",
      officeName: data.locationInfo.officeName,
      taxId: data.locationInfo.taxId,
      typeOfOwnership: data.locationInfo.typeOfOwnership,
      numberOfEmployees: data.locationInfo.totalNumberOfEmployees,
      businessStartedDate: data.locationInfo.dateBusinessStarted,
      businessType: data.locationInfo.businessType,
      practiceManagementSoftware: data.locationInfo.practiceManagementSoftware,
      phoneNumber: data.locationInfo.officePhoneNumber,
      address1: data.physicalAddress.physicalStreet1,
      address2: data.physicalAddress.physicalStreet2,
      city: data.physicalAddress.physicalCity,
      state: data.physicalAddress.physicalState,
      zipCode: data.physicalAddress.physicalZipcode,
      mailingAddress1: data.mailingAddress.mailingStreet1,
      mailingAddress2: data.mailingAddress.mailingStreet2,
      mailingCity: data.mailingAddress.mailingCity,
      mailingState: data.mailingAddress.mailingState,
      mailingZipCode: data.mailingAddress.mailingZipcode,
      ownerFirstName: data.ownerInfo.ownerFirstName,
      ownerLastName: data.ownerInfo.ownerLastName,
      ownerLicenseNumber: data.ownerInfo.ownerLicenseNumber,
      // ownerSSN: "string",
      ownerAddress1: data.ownerInfo.ownerStreet1,
      ownerAddress2: data.ownerInfo.ownerStreet2,
      ownerCity: data.ownerInfo.ownerCity,
      ownerState: data.ownerInfo.ownerState,
      ownerZipCode: data.ownerInfo.ownerZipcode,
      ownerPhoneNumber: data.ownerInfo.ownerCellPhone,
      ownerEmail: data.ownerInfo.ownerEmailAddress,
      managerFirstName: data.managingDoctor.doctorFirstName,
      managerLastName: data.managingDoctor.doctorLastName,
      managerLicenseNumber: data.managingDoctor.doctorLicenseNumber,
      managerLicenseState: data.managingDoctor.doctorLicenseState,
      contactFirstName: data.contactInfo.contactFirstName,
      contactLastName: data.contactInfo.contactLastName,
      contactPhoneNumber: data.contactInfo.contactOfficePhoneNumber,
      contactEmail: data.contactInfo.contactEmailAddress,
      bankName: data.bankInfo.bankName,
      bankRoutingNumber: data.bankInfo.routingNumber,
      bankAccountNumber: data.bankInfo.accountNumber,
      bankLetterImage: data.bankInfo.bankLetter,
      // bankLetterProvided: true,
      // longitude: 0,
      // latitude: 0,
      // isActive: true,
    };
    if (title === "Edit Location") {
      payload = { ...payload, id: locationId };
    }

    console.log(payload);
    let actionStatus = null;
    try {
      setIsLoading(true);
      const res = await upsertLocation(payload);
      actionStatus = _.get(res, "headers.action-status", null);

      // setIsLoading(false);
    } catch (error) {
      showMassage("Something went wrong!");
    } finally {
      setTimeout(() => {
        setIsLoading(false);
        navigate("/locations");
      }, 1000);
      switch (actionStatus) {
        default:
          showMassage("Unknown error");
          break;
        case "InvalidCredentials":
          showMassage("Invalid Credientials");
          break;
        case "Failed":
          showMassage("Failed");
          break;
        case "InvalidCredentialsNewlyLockedOut":
          showMassage("Invalid Credientials Newly LockedOut");
          break;
        case "UsedBefore":
          showMassage("UsedBefore");
          break;
        case "SameAsPrevious":
          showMassage("Same As Previous");
          break;
        case "AdminLockedOut":
          showMassage("Your account was just locked out.");
          break;
        case "ValidationError":
          showMassage("Validation Error");
          break;
        case "NotExists":
          showMassage("Not Exists");
          break;
        case "Forbidden":
          showMassage("Forbidden");
          break;
        case "Success":
          if (title === "Edit Location") {
            showMassage("Successfully edited!");
          } else {
            showMassage("Successfully added!");
          }
          break;
      }
    }

    // sendPostByJson(url, data);
    //   .then((res) => {
    //     if (res.status >= 200 && res.status < 300) {
    //       // toastSuccess(
    //       //   <>Please ask applicant to check their device.</>,
    //       //   "application-form"
    //       // );
    //       // setTimeout(() => {
    //       let message = "";
    //       if (!shouldLocationBeSubmitted) {
    //         message = `The application was successfully saved. ${
    //           title === "Edit Application" ? "FROM Edit-Application!!!" : ""
    //         }`;
    //       } else {
    //         message = `The new application was successfully submitted. Please ask the applicant to check their device. ${
    //           title === "Edit Application" ? "FROM Edit-Application!!!" : ""
    //         }`;
    //       }

    //       navigate("/applications", {
    //         state: {
    //           applicationFormMessage: { message, show: true },
    //         },
    //       });
    //       return true;
    //       // return <NewApplicationModal />;
    //       // }, 1000);
    //     } else {
    //       return Promise.reject(`API Response: ${res.status}`);
    //     }
    //   })
    //   .catch(() => {
    //     toastError(<>There is an error.</>, "application-form");
    //     setLoadingStatus(apiLoadingStatus.unloaded);
    //   });
    // console.log(data);
  };

  // const nextProcess = () => {
  //   let targetIndex = -1;

  //   const updatedProcessArray = process.map((item, indx) => {
  //     if (item.status === "completed") {
  //       return { ...item };
  //     }
  //     if (item.status === "in-progress") {
  //       targetIndex = indx;
  //       return { ...item, status: "completed" };
  //     }
  //     if (indx === targetIndex + 1) {
  //       return { ...item, status: "in-progress" };
  //     }
  //     return { ...item };
  //   });

  //   setProcess(updatedProcessArray);
  // };

  // const previousProcess = () => {
  //   let lastInProgressIndex = -1;
  //   let lastCompletedIndex = -1;

  //   // First pass to find the indexes
  //   process.forEach((item, indx) => {
  //     if (item.status === "in-progress") {
  //       lastInProgressIndex = indx;
  //     }
  //     if (item.status === "completed") {
  //       lastCompletedIndex = indx;
  //     }
  //   });

  //   // Second pass to update the statuses
  //   const updatedProcessArray = process.map((item, indx) => {
  //     if (indx === lastInProgressIndex) {
  //       return { ...item, status: undefined };
  //     }
  //     if (indx === lastCompletedIndex) {
  //       return { ...item, status: "in-progress" };
  //     }
  //     return { ...item };
  //   });

  //   setProcess(updatedProcessArray);
  // };
  // const updateProcess = (title) => {
  //   let foundIndex = -1;

  //   const updatedProcessArray = process.map((item, indx) => {
  //     if (item.title === title) {
  //       foundIndex = indx;
  //       return { ...item, status: "in-progress" };
  //     }

  //     if (foundIndex === -1) {
  //       // Before the found index, set to completed
  //       return { ...item, status: "completed" };
  //     }

  //     if (foundIndex !== -1 && indx > foundIndex) {
  //       // After the found index, set to undefined
  //       return { ...item, status: undefined };
  //     }

  //     return { ...item };
  //   });

  //   setProcess(updatedProcessArray);
  // };
  const setProcessStatus = (title, status) => {
    const updatedProcessArray = process.map((item, indx) => {
      if (item.title === title) {
        return { ...item, status: status };
      }

      return { ...item };
    });

    setProcess(updatedProcessArray);
  };

  ///edit location
  const updateStatus = (object) => {
    let status = undefined;
    const v = getObjectPropertyNames(object);
    // console.log(v);
    for (let i = 0; i < v.length; i++) {
      // console.log(_.get(object, v[i]));
      if (_.get(object, v[i]) === null) {
        continue;
      } else {
        status = "in-progress";
        break;
      }
    }
    // console.log(validateField(location), validateField(object), object);
    validateAll(object) && (status = "completed");
    return status;
  };

  const { getLocationByIds } = useHgLocations();
  const getLocationDetails = async (id) => {
    const loc = await getLocationByIds(id);
    return loc;
  };
  const urlParts = webAddress.pathname.split("/");
  const locationId = urlParts[urlParts.length - 1];

  useEffect(() => {
    if (webAddress.pathname.includes("edit")) {
      // console.log(locationId);

      getLocationDetails(locationId)
        .then((res) => {
          if (res[0] !== null) {
            setLoccation({
              ...location,
              locationInfo: {
                corporateName: res[0].corporateName,
                associatedPracticeName: null,
                officeName: res[0].officeName,
                taxId: res[0].taxId,
                typeOfOwnership: res[0].typeOfOwnership,
                totalNumberOfEmployees: res[0].numberOfEmployees,
                dateBusinessStarted: res[0].businessStartedDate,
                businessType: res[0].businessType,
                practiceManagementSoftware: null,
                officePhoneNumber: res[0].phoneNumber,
                faxNumber: null,
                officeWebsite: null,
              },
              physicalAddress: {
                physicalStreet1: res[0].address1,
                physicalStreet2: null,
                physicalZipcode: res[0].zipCode,
                physicalState: res[0].state,
                physicalCity: res[0].city,
              },
              mailingAddress: {
                mailingStreet1: null,
                mailingStreet2: null,
                mailingZipcode: null,
                mailingState: res[0].mailingState,
                mailingCity: res[0].mailingCity,
              },

              ownerInfo: {
                ownerFirstName: res[0].ownerFirstName,
                ownerLastName: res[0].ownerLastName,
                ownerLicenseNumber: res[0].ownerLicenseNumber,
                ownerStreet1: res[0].ownerAddress1,
                ownerStreet2: null,
                ownerZipcode: res[0].ownerZipCode,
                ownerState: res[0].ownerState,
                ownerCity: res[0].ownerCity,
                ownerCellPhone: res[0].ownerPhoneNumber,
                ownerEmailAddress: res[0].ownerEmail,
              },

              managingDoctor: {
                doctorFirstName: res[0].managerFirstName,
                doctorLastName: res[0].managerLastName,
                doctorLicenseNumber: null,
                doctorLicenseState: res[0].managerLicenseState,
              },

              contactInfo: {
                contactFirstName: res[0].contactFirstName,
                contactLastName: res[0].contactLastName,
                contactOfficePhoneNumber: res[0].contactPhoneNumber,
                contactEmailAddress: res[0].contactEmail,
              },

              bankInfo: {
                bankName: res[0].bankName,
                routingNumber: res[0].bankRoutingNumber,
                accountNumber: res[0].bankAccountNumber,
                bankLetter: res[0].bankLetterImage,
              },
            });
          }

          // console.log(location);
        })
        .finally(() => {
          //   console.log(updateStatus(location.locationInfo));
          //   // validateAll(location);
          //   setProcessStatus(
          //     "Location Information",
          //     updateStatus(location.locationInfo)
          //   );
          //   setProcessStatus(
          //     "Physical Address",
          //     updateStatus(location.physicalAddress)
          //   );
          //   setProcessStatus(
          //     "Mailing Address",
          //     updateStatus(location.mailingAddress)
          //   );
          //   setProcessStatus(
          //     "Owner Information",
          //     updateStatus(location.ownerInfo)
          //   );
          //   setProcessStatus(
          //     "Managing Doctor",
          //     updateStatus(location.managingDoctor)
          //   );
          //   setProcessStatus(
          //     "Contact Information",
          //     updateStatus(location.contactInfo)
          //   );
          //   setProcessStatus("Bank Information", updateStatus(location.bankInfo));
          // });
        });
    }
  }, [webAddress.pathname]);

  return (
    <div className={styles["layout"]}>
      <Header
        title={"Add New Location"}
        user={user.data}
        userLogoUrl={logo.data?.url}
      />
      <div className={styles["icon"]}>
        <div className={styles["title"]}>
          {title === "Edit Location" ? (
            <LocationUpdate01Icon size={35} />
          ) : (
            <LocationAdd01Icon size={35} />
          )}

          <h1 className={styles["step-title"]}>{title}</h1>
        </div>
        <ProcessBar process={process} />
      </div>
      <div className={`container ${styles["container"]}`}>
        <div className="filter_box mt_2">
          {/* <LoadingSpinner isLoading={buttonDisabled} /> */}
        </div>

        <div className={styles["step-container"]}>
          <LoadingSpinner isLoading={isLoading} />

          <NewLocationInputs
            user={user}
            process={process}
            setProcess={setProcess}
            title={title}
            location={location}
            onChange={(temp) => {
              setLoccation(temp);
            }}
            locationValidations={locationValidations}
            validateInputs={validate}
            validateField={validateAll}
            setProcessStatus={setProcessStatus}
            // isExistingApplication={isExistingApplication}
          />
          <div className={`noprint ${styles["btn-container"]}`}>
            <Button
              // disabled={buttonDisabled}
              variant="secondary"
              className={styles["btn-consent"]}
              onClick={() => {
                // (!isExistingApplication && setStep(steps.consent)) ||
                navigate("/locations");
              }}
            >
              Back
            </Button>

            {/* <Button
              disabled={buttonDisabled}
              variant="dark"
              onClick={() => submitApplication(false)}
              className={styles["btn-consent"]}
            >
              Save
            </Button> */}
            <Button
              // disabled={buttonDisabled}
              variant="primary"
              onClick={() => submitLocation(true)}
              className={styles["btn-consent"]}
            >
              Submit
            </Button>
            {/* <Button
              variant="secondary"
              className={styles["btn-consent"]}
              onClick={nextProcess}
            >
              next step
            </Button>
            <Button
              variant="secondary"
              className={styles["btn-consent"]}
              onClick={previousProcess}
            >
              pre step
            </Button>
            <Button
              variant="secondary"
              className={styles["btn-consent"]}
              onClick={() => {
                updateProcess("Owner Information");
              }}
            >
              updateProcess
            </Button> */}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default NewLocationForm;
