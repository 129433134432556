import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.scss";

const ProcessIndicator = ({
  text = "",
  status = undefined,
  first = false,
  textWidth = "60px",
}) => {
  // console.log(status);
  return (
    <>
      <div className={`${styles["container"]} ${styles[status]}`}>
        {first ? (
          <div
            className={`${styles["line"]} ${styles[status]}`}
            style={{ maxWidth: 0 }}
          />
        ) : (
          <div className={`${styles["line"]} ${styles[status]}`} />
        )}
        <div className={`${styles["indicator"]} ${styles[status]}`} />
        <span style={{ width: `${textWidth}` }}>{text}</span>
      </div>
    </>
  );
};

ProcessIndicator.propTypes = {};

export default ProcessIndicator;
