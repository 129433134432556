import React, { useEffect } from "react";
import { Row, Col, Form, FormControl } from "react-bootstrap";
import SelectControl, {
  HgSitesSelectControl,
} from "../../../components/select-control";
import { NumericFormat, PatternFormat } from "react-number-format";
import styles from "./styles.module.scss";
import { stateCollection } from "../../../infrastructure/constants";
import _ from "lodash";
// import {
//   getDefaultlocation,
//   getDefaultlocationValidations,
// } from "./utils/utils";
import {
  commaSeperatedCurrency,
  getObjectPropertyNames,
} from "../../../infrastructure/helpers";
import ValidationWrapper from "../../../components/validation-wrapper";
import { isMobile } from "react-device-detect";
import moment from "moment";
import treatmentType from "../../../infrastructure/constants/enums/treatment-types";
import languageType from "../../../infrastructure/constants/enums/languages";
import CheckBox from "../../../components/check-box/check-box";
import RadioButton from "../../../components/radio-button";
import {
  getDefaultLocation,
  getDefaultLocationValidations,
} from "./utils/utils";

const NewLocationInputs = ({
  process = [],
  setProcess = () => {},
  title = "Add New Location",
  user = {},
  onChange = (name, value) => {},
  location = getDefaultLocation(),
  locationValidations = getDefaultLocationValidations(),
  validateInputs = (name, value) => {},
  isExistingLocation = false,
  validateField = () => {},
  setProcessStatus = () => {},
}) => {
  const innerChange = (name, newValue) => {
    const temp = { ...location };
    let value = newValue || null;
    switch (name) {
      // case "locationInfo.preferredLanguage":
      case "physicalAddress.physicalState":
      case "mailingAddress.mailingState":
      case "ownerInfo.ownerState":
      case "managingDoctor.doctorLicenseState":
        // case "treatmentType":
        // case "hg_SiteId":
        value = newValue?.value || null;
        break;
        // case "locationInfo.hasAvaiableCreditLine":
        //   value = newValue;
        //   temp.locationInfo.availableCreditLine = value ? temp.amount : 0;
        //   break;
        // case "locationInfo.noSsn":
        if (!!value) {
          temp.locationInfo.ssn = null;
          temp.locationInfo.ssnLast4 = null;
          validateInputs("locationInfo.ssn", undefined, {
            ...location,
            locationInfo: {
              ...location.locationInfo,
              noSsn: true,
            },
          });
        }

        break;
      default:
        break;
    }

    validateInputs(name, value, location);
    if (!!name) {
      _.set(temp, name, value);
      onChange(temp);
    }
  };

  const updateStatus = (object) => {
    let status = undefined;
    const v = getObjectPropertyNames(object);
    // console.log(v);
    for (let i = 0; i < v.length; i++) {
      // console.log(_.get(object, v[i]));
      if (_.get(object, v[i]) === null) {
        continue;
      } else {
        status = "in-progress";
        break;
      }
    }
    // console.log(validateField(location), validateField(object), object);
    validateField(object) && (status = "completed");
    return status;
  };
  useEffect(() => {
    setProcessStatus(
      "Location Information",
      updateStatus(location.locationInfo)
    );
    // console.log(location.locationInfo, updateStatus(location.locationInfo));
  }, [
    location.locationInfo.corporateName,
    location.locationInfo.associatedPracticeName,
    location.locationInfo.officeName,
    location.locationInfo.taxId,
    location.locationInfo.typeOfOwnership,
    location.locationInfo.totalNumberOfEmployees,
    location.locationInfo.dateBusinessStarted,
    location.locationInfo.businessType,
    location.locationInfo.practiceManagementSoftware,
    location.locationInfo.officePhoneNumber,
    location.locationInfo.faxNumber,
    location.locationInfo.officeWebsite,
  ]);

  useEffect(() => {
    setProcessStatus(
      "Physical Address",
      updateStatus(location.physicalAddress)
    );
    // console.log(location.physicalAddress);

    console.log(
      location.physicalAddress,
      updateStatus(location.physicalAddress)
    );
  }, [
    location.physicalAddress.physicalStreet1,
    location.physicalAddress.physicalStreet2,
    location.physicalAddress.physicalZipcode,
    location.physicalAddress.physicalState,
    location.physicalAddress.physicalCity,
  ]);

  useEffect(() => {
    setProcessStatus("Mailing Address", updateStatus(location.mailingAddress));
  }, [
    location.mailingAddress.mailingStreet1,
    location.mailingAddress.mailingStreet2,
    location.mailingAddress.mailingZipcode,
    location.mailingAddress.mailingState,
    location.mailingAddress.mailingCity,
  ]);

  useEffect(() => {
    // console.log("Owner Information", updateStatus(location.ownerInfo));
    setProcessStatus("Owner Information", updateStatus(location.ownerInfo));
  }, [
    location.ownerInfo.ownerFirstName,
    location.ownerInfo.ownerLastName,
    location.ownerInfo.ownerLicenseNumber,
    location.ownerInfo.ownerStreet1,
    location.ownerInfo.ownerStreet2,
    location.ownerInfo.ownerZipcode,
    location.ownerInfo.ownerState,
    location.ownerInfo.ownerCity,
    location.ownerInfo.ownerCellPhone,
    location.ownerInfo.ownerEmailAddress,
  ]);

  useEffect(() => {
    // console.log("Owner Information", updateStatus(location.ownerInfo));
    setProcessStatus("Managing Doctor", updateStatus(location.managingDoctor));
  }, [
    location.managingDoctor.doctorFirstName,
    location.managingDoctor.doctorLastName,
    location.managingDoctor.doctorLicenseNumber,
    location.managingDoctor.doctorLicenseState,
  ]);

  useEffect(() => {
    // console.log("Owner Information", updateStatus(location.ownerInfo));
    setProcessStatus("Contact Information", updateStatus(location.contactInfo));
  }, [
    location.contactInfo.contactFirstName,
    location.contactInfo.contactLastName,
    location.contactInfo.contactOfficePhoneNumber,
    location.contactInfo.contactEmailAddress,
  ]);

  useEffect(() => {
    // console.log("Owner Information", updateStatus(location.ownerInfo));
    setProcessStatus("Bank Information", updateStatus(location.bankInfo));
  }, [
    location.bankInfo.bankName,
    location.bankInfo.routingNumber,
    location.bankInfo.accountNumber,
    location.bankInfo.bankLetter,
  ]);

  useEffect(() => {
    document.title = `${title} | DentiRate`;
    window.scrollTo(0, 0);
  }, []);

  return (
    <div div className={styles["input-container"]}>
      {/* Location Information */}
      <div className={styles["section"]}>
        <div className={styles["section-title"]}>
          <span>{process[0].title}</span>
          <div className={styles["line"]}></div>
        </div>

        <Row>
          <Col className="relative" xs={12} md={5} lg={6} xl={4}>
            <ValidationWrapper
              isValid={locationValidations.locationInfo?.corporateName}
              titleStar={true}
              title="Corporate/Legal Name"
              errorMessage="Invalid Corporate/Legal Name"
            >
              <Form.Control
                className={`form-control ${
                  !locationValidations.locationInfo?.corporateName && "danger"
                }`}
                type="text"
                value={location.locationInfo.corporateName || ""}
                onChange={(e) => {
                  innerChange("locationInfo.corporateName", e.target.value);
                }}
                autoComplete="off"
                maxLength={50}
              />
            </ValidationWrapper>
          </Col>
          <Col className="relative" xs={12} md={7} lg={6} xl={4}>
            <ValidationWrapper
              isValid={locationValidations.locationInfo?.associatedPracticeName}
              titleStar={true}
              title="Associated Practice Name or Store Number"
              errorMessage="Invalid Associated Practice Name or Store Number"
            >
              <Form.Control
                type="text"
                className={`form-control ${
                  !locationValidations.locationInfo?.associatedPracticeName &&
                  "danger"
                }`}
                value={location.locationInfo.associatedPracticeName || ""}
                onChange={(e) => {
                  innerChange(
                    "locationInfo.associatedPracticeName",
                    e.target.value
                  );
                }}
                autoComplete="off"
                maxLength={50}
              />
            </ValidationWrapper>
          </Col>
          <Col className="relative" xs={12} md={4} lg={4} xl={4}>
            <ValidationWrapper
              isValid={locationValidations.locationInfo?.officeName}
              titleStar={true}
              title="Office/DBA Name"
              errorMessage="Invalid Office/DBA Name"
            >
              <Form.Control
                type="text"
                className={`form-control ${
                  !locationValidations.locationInfo.officeName && "danger"
                }`}
                value={location.locationInfo.officeName || ""}
                onChange={(e) => {
                  innerChange("locationInfo.officeName", e.target.value);
                }}
                autoComplete="off"
                maxLength={50}
              />
            </ValidationWrapper>
          </Col>
          <Col className="relative" xs={12} md={4} lg={4} xl={4}>
            <ValidationWrapper
              isValid={locationValidations.locationInfo?.taxId}
              titleStar={true}
              title="Tax ID Number"
              errorMessage="Invalid Tax ID Number"
            >
              <Form.Control
                type="text"
                className={`form-control ${
                  !locationValidations.locationInfo.taxId && "danger"
                }`}
                value={location.locationInfo.taxId || ""}
                onChange={(e) => {
                  innerChange("locationInfo.taxId", e.target.value);
                }}
                autoComplete="off"
                maxLength={50}
              />
            </ValidationWrapper>
          </Col>
          <Col className="relative" xs={12} md={4} lg={4} xl={4}>
            <ValidationWrapper
              isValid={locationValidations.locationInfo?.typeOfOwnership}
              titleStar={true}
              title="Type of Ownership"
              errorMessage="Invalid Type of Ownership"
            >
              <Form.Control
                type="text"
                className={`form-control ${
                  !locationValidations.locationInfo.typeOfOwnership && "danger"
                }`}
                value={location.locationInfo.typeOfOwnership || ""}
                onChange={(e) => {
                  innerChange("locationInfo.typeOfOwnership", e.target.value);
                }}
                autoComplete="off"
                maxLength={50}
              />
            </ValidationWrapper>
          </Col>
          <Col className="relative" xs={12} md={6} lg={6} xl={4}>
            <ValidationWrapper
              // isValid={locationValidations.amount}
              title="Total Number of Employees"
              // titleStar={true}
              errorMessage="Invalid Number"
            >
              <NumericFormat
                name="number"
                className={`form-control`}
                // !locationValidations.amount && "danger"
                thousandSeparator={true}
                // prefix={"$"}
                placeholder=""
                autoComplete="off"
                customInput={FormControl}
                allowNegative={false}
                decimalScale={0}
                fixedDecimalScale={true}
                value={location.locationInfo.totalNumberOfEmployees || ""}
                onValueChange={(values) => {
                  innerChange(
                    "locationInfo.totalNumberOfEmployees",
                    values.floatValue
                  );
                }}
                maxLength={8}
              />
            </ValidationWrapper>
          </Col>

          <Col className="relative" xs={12} md={6} lg={6} xl={4}>
            <ValidationWrapper
              isValid={locationValidations.locationInfo.dateBusinessStarted}
              title={"Date Business Started"}
              titleStar={true}
              errorMessage="Invalid date"
            >
              <Form.Control
                className={`form-control ${
                  !locationValidations.locationInfo.dateBusinessStarted &&
                  "danger"
                }`}
                value={
                  !!location.locationInfo?.dateBusinessStarted &&
                  moment(location.locationInfo?.dateBusinessStarted) &&
                  moment(location.locationInfo?.dateBusinessStarted).isValid()
                    ? moment(location.locationInfo?.dateBusinessStarted).format(
                        "yyyy-MM-DD"
                      )
                    : ""
                }
                onChange={(e) =>
                  innerChange(
                    "locationInfo.dateBusinessStarted",
                    e.target.value
                  )
                }
                type="date"
                autoComplete="off"
              />
            </ValidationWrapper>
          </Col>
          <Col className="relative" xs={12} md={7} lg={6} xl={4}>
            <ValidationWrapper
              isValid={
                // locationValidations.locationInfo?.hgPatientReferenceId ||
                true
              }
              title="Practice/Medical Specialty, or Business Type"
              errorMessage="Invalid content"
            >
              <Form.Control
                type="text"
                className="form-control"
                value={location.locationInfo.businessType || ""}
                onChange={(e) => {
                  innerChange("locationInfo.businessType", e.target.value);
                }}
                autoComplete="off"
                maxLength={50}
              />
            </ValidationWrapper>
          </Col>
          <Col className="relative" xs={12} md={5} lg={6} xl={4}>
            <ValidationWrapper
              isValid={
                locationValidations.locationInfo?.practiceManagementSoftware
              }
              titleStar={true}
              title="Practice Management Software"
              errorMessage="Invalid content"
            >
              <Form.Control
                type="text"
                className={`form-control ${
                  !locationValidations.locationInfo
                    .practiceManagementSoftware && "danger"
                }`}
                value={location.locationInfo.practiceManagementSoftware || ""}
                onChange={(e) => {
                  innerChange(
                    "locationInfo.practiceManagementSoftware",
                    e.target.value
                  );
                }}
                autoComplete="off"
                maxLength={50}
              />
            </ValidationWrapper>
          </Col>
        </Row>
        <div className={styles["separator-dashed"]}></div>
        <Row>
          <Col className="relative" xs={12} md={4} lg={4} xl={4}>
            <ValidationWrapper
              isValid={locationValidations.locationInfo.officePhoneNumber}
              title="Office Phone Number"
              titleStar={true}
              inputId="input-cell-phone"
              errorMessage="Invalid Phone Number"
            >
              <PatternFormat
                className={`form-control ${
                  !locationValidations.locationInfo.officePhoneNumber &&
                  "danger"
                }`}
                id="input-cell-phone"
                name="number"
                autoComplete="off"
                // customInput={FormControl}
                value={location.locationInfo.officePhoneNumber || ""}
                onValueChange={(values) => {
                  innerChange("locationInfo.officePhoneNumber", values.value);
                }}
                type="tel"
                format="+1 (###) ###-####"
                mask="_"
              />
            </ValidationWrapper>
          </Col>
          <Col className="relative" xs={12} md={4} lg={4} xl={4}>
            <ValidationWrapper
              isValid={locationValidations.locationInfo.faxNumber}
              title="Fax Number"
              // titleStar={true}
              inputId="input-cell-phone"
              errorMessage="Invalid Fax Number"
            >
              <PatternFormat
                className={`form-control ${
                  !locationValidations.locationInfo.faxNumber && "danger"
                }`}
                id="input-cell-phone"
                name="number"
                autoComplete="off"
                // customInput={FormControl}
                value={location.locationInfo.faxNumber || ""}
                onValueChange={(values) => {
                  innerChange("locationInfo.faxNumber", values.value);
                }}
                type="tel"
                format="+1 (###) ###-####"
                mask="_"
              />
            </ValidationWrapper>
          </Col>
          <Col className="relative" xs={12} md={4} lg={4} xl={4}>
            <ValidationWrapper
              isValid={locationValidations.locationInfo.officeWebsite}
              titleStar={true}
              title="Office Website"
              errorMessage="Invalid Website"
            >
              <Form.Control
                type="text"
                className={`form-control ${
                  !locationValidations.locationInfo.officeWebsite && "danger"
                }`}
                value={location.locationInfo.officeWebsite || ""}
                onChange={(e) => {
                  innerChange("locationInfo.officeWebsite", e.target.value);
                }}
                autoComplete="off"
                maxLength={50}
              />
            </ValidationWrapper>
          </Col>
        </Row>
      </div>

      {/* physical address */}
      <div className={styles["section"]}>
        <div className={styles["section-title"]}>
          <span>{process[1].title}</span>
          <div className={styles["line"]}></div>
        </div>

        <Row>
          <Col className="relative" xs={12} md={12} lg={3}>
            <ValidationWrapper
              isValid={locationValidations.physicalAddress.physicalStreet1}
              title={"Street Line 1"}
              titleStar={true}
              errorMessage="Invalid street line"
            >
              <Form.Control
                className={`form-control ${
                  !locationValidations.physicalAddress.physicalStreet1 &&
                  "danger"
                }`}
                value={location.physicalAddress?.physicalStreet1 || ""}
                onChange={(e) =>
                  innerChange("physicalAddress.physicalStreet1", e.target.value)
                }
                autoComplete="off"
              />
            </ValidationWrapper>
          </Col>
          <Col className="relative" xs={12} md={6} lg={3}>
            <ValidationWrapper
              // isValid={locationValidations.locationInfo.street2}
              title={"Street Line 2"}
              errorMessage="Invalid street line"
            >
              <Form.Control
                value={location.physicalAddress?.physicalStreet2 || ""}
                onChange={(e) =>
                  innerChange("physicalAddress.physicalStreet2", e.target.value)
                }
                autoComplete="off"
              />
            </ValidationWrapper>
          </Col>
          {/* <Col className="relative" xs={12} md={6} lg={1}>
            <ValidationWrapper
              isValid={locationValidations.locationInfo.apartmentOrUnit}
              title={"Apt/Unit"}
              errorMessage="Invalid apt"
            >
              <Form.Control
                value={location.locationInfo?.apartmentOrUnit || ""}
                onChange={(e) =>
                  innerChange("locationInfo.apartmentOrUnit", e.target.value)
                }
                autoComplete="off"
              />
            </ValidationWrapper>
          </Col> */}
          <Col className="relative" xs={12} md={6} lg={2}>
            <ValidationWrapper
              isValid={locationValidations.physicalAddress.physicalCity}
              title={"City"}
              titleStar={true}
              errorMessage="Invalid city"
            >
              <Form.Control
                className={`form-control ${
                  !locationValidations.physicalAddress.physicalCity && "danger"
                }`}
                value={location.physicalAddress?.physicalCity || ""}
                onChange={(e) =>
                  innerChange("physicalAddress.physicalCity", e.target.value)
                }
                autoComplete="off"
              />
            </ValidationWrapper>
          </Col>
          <Col className="relative" xs={12} md={6} lg={2}>
            <ValidationWrapper
              isValid={locationValidations.physicalAddress.physicalState}
              title={"State"}
              titleStar={true}
              errorMessage="Invalid state"
            >
              <SelectControl
                className={`${styles["input"]} ${
                  !locationValidations.physicalAddress.physicalState &&
                  styles["not-valid"]
                }`}
                options={stateCollection}
                value={
                  !!location.physicalAddress.physicalState
                    ? stateCollection.find(
                        (i) =>
                          i.value === location.physicalAddress.physicalState
                      )
                    : null
                }
                onChange={(v) =>
                  innerChange("physicalAddress.physicalState", v)
                }
                placeholder=""
              />
            </ValidationWrapper>
          </Col>
          <Col className="relative" xs={12} md={6} lg={2}>
            <ValidationWrapper
              isValid={locationValidations.physicalAddress.physicalZipcode}
              title={"Zip Code"}
              titleStar={true}
              inputId="input-zipcode"
              errorMessage="Invalid zip code"
            >
              <Form.Control
                className={`form-control ${
                  !locationValidations.physicalAddress.physicalZipcode &&
                  "danger"
                }`}
                maxLength={5}
                value={location.physicalAddress?.physicalZipcode || ""}
                onChange={(e) => {
                  if (e.target.value && e.target.value.match(/\D/gi)) {
                    return false;
                  }
                  innerChange(
                    "physicalAddress.physicalZipcode",
                    e.target.value
                  );
                }}
                id="input-zipcode"
                autoComplete="off"
              />
            </ValidationWrapper>
          </Col>
        </Row>
      </div>

      {/* Mailing Address */}
      <div className={styles["section"]}>
        <div className={styles["section-title"]}>
          <span>{process[2].title}</span>
          <div className={styles["line"]}></div>
        </div>
        <Row>
          <Col className="relative" xs={12} md={12} lg={3}>
            <ValidationWrapper
              isValid={locationValidations.mailingAddress.mailingStreet1}
              title={"Street Line 1"}
              titleStar={true}
              errorMessage="Invalid street line"
            >
              <Form.Control
                className={`form-control ${
                  !locationValidations.mailingAddress.mailingStreet1 && "danger"
                }`}
                value={location.mailingAddress?.mailingStreet1 || ""}
                onChange={(e) =>
                  innerChange("mailingAddress.mailingStreet1", e.target.value)
                }
                autoComplete="off"
              />
            </ValidationWrapper>
          </Col>
          <Col className="relative" xs={12} md={6} lg={3}>
            <ValidationWrapper
              // isValid={locationValidations.locationInfo.street2}
              title={"Street Line 2"}
              errorMessage="Invalid street line"
            >
              <Form.Control
                value={location.mailingAddress?.mailingStreet2 || ""}
                onChange={(e) =>
                  innerChange("mailingAddress.mailingStreet2", e.target.value)
                }
                autoComplete="off"
              />
            </ValidationWrapper>
          </Col>

          <Col className="relative" xs={12} md={6} lg={2}>
            <ValidationWrapper
              isValid={locationValidations.mailingAddress.mailingCity}
              title={"City"}
              titleStar={true}
              errorMessage="Invalid city"
            >
              <Form.Control
                className={`form-control ${
                  !locationValidations.mailingAddress.mailingCity && "danger"
                }`}
                value={location.mailingAddress?.mailingCity || ""}
                onChange={(e) =>
                  innerChange("mailingAddress.mailingCity", e.target.value)
                }
                autoComplete="off"
              />
            </ValidationWrapper>
          </Col>
          <Col className="relative" xs={12} md={6} lg={2}>
            <ValidationWrapper
              isValid={locationValidations.mailingAddress.mailingState}
              title={"State"}
              titleStar={true}
              errorMessage="Invalid state"
            >
              <SelectControl
                className={`${styles["input"]} ${
                  !locationValidations.mailingAddress.mailingState &&
                  styles["not-valid"]
                }`}
                options={stateCollection}
                value={
                  !!location.mailingAddress.mailingState
                    ? stateCollection.find(
                        (i) => i.value === location.mailingAddress.mailingState
                      )
                    : null
                }
                onChange={(v) => innerChange("mailingAddress.mailingState", v)}
                placeholder=""
              />
            </ValidationWrapper>
          </Col>
          <Col className="relative" xs={12} md={6} lg={2}>
            <ValidationWrapper
              isValid={locationValidations.mailingAddress.mailingZipcode}
              title={"Zip Code"}
              titleStar={true}
              inputId="input-zipcode"
              errorMessage="Invalid zip code"
            >
              <Form.Control
                className={`form-control ${
                  !locationValidations.mailingAddress.mailingZipcode && "danger"
                }`}
                maxLength={5}
                value={location.mailingAddress?.mailingZipcode || ""}
                onChange={(e) => {
                  if (e.target.value && e.target.value.match(/\D/gi)) {
                    return false;
                  }
                  innerChange("mailingAddress.mailingZipcode", e.target.value);
                }}
                id="input-zipcode"
                autoComplete="off"
              />
            </ValidationWrapper>
          </Col>
        </Row>
      </div>

      {/* Owner Information */}
      <div className={styles["section"]}>
        <div className={styles["section-title"]}>
          <span>{process[3].title}</span>
          <div className={styles["line"]}></div>
        </div>
        <Row>
          <Col className="relative" xs={12} md={6} lg={3}>
            <ValidationWrapper
              isValid={locationValidations.ownerInfo.ownerFirstName}
              title={"First Name"}
              titleStar={true}
              errorMessage="Invalid first name"
            >
              <Form.Control
                className={`form-control ${
                  !locationValidations.ownerInfo.ownerFirstName && "danger"
                }`}
                value={location.ownerInfo?.ownerFirstName || ""}
                onChange={(e) =>
                  innerChange("ownerInfo.ownerFirstName", e.target.value)
                }
                autoComplete="off"
              />
            </ValidationWrapper>
          </Col>
          <Col className="relative" xs={12} md={6} lg={3}>
            <ValidationWrapper
              isValid={locationValidations.ownerInfo.ownerLastName}
              title={"Last Name"}
              titleStar={true}
              errorMessage="Invalid last name"
            >
              <Form.Control
                className={`form-control ${
                  !locationValidations.ownerInfo.ownerLastName && "danger"
                }`}
                value={location.ownerInfo?.ownerLastName || ""}
                onChange={(e) =>
                  innerChange("ownerInfo.ownerLastName", e.target.value)
                }
                autoComplete="off"
              />
            </ValidationWrapper>
          </Col>
          <Col className="relative" xs={12} md={12} lg={6}>
            <ValidationWrapper
              isValid={locationValidations.ownerInfo.ownerLicenseNumber}
              title="Medical/Business/State License Number"
              titleStar={true}
              errorMessage="Invalid Number"
            >
              <NumericFormat
                name="number"
                className={`form-control ${
                  !locationValidations.ownerInfo.ownerLicenseNumber && "danger"
                }`}
                // thousandSeparator={true}
                // prefix={"$"}
                placeholder=""
                autoComplete="off"
                customInput={FormControl}
                allowNegative={false}
                decimalScale={0}
                fixedDecimalScale={true}
                value={location.ownerInfo.licenseNumber || ""}
                onValueChange={(values) => {
                  innerChange(
                    "ownerInfo.ownerLicenseNumber",
                    values.floatValue
                  );
                }}
                maxLength={8}
              />
            </ValidationWrapper>
          </Col>
        </Row>
        <div className={styles["separator-dashed"]}></div>
        <Row>
          <Col className="relative" xs={12} md={12} lg={3}>
            <ValidationWrapper
              // isValid={locationValidations.locationInfo.street1}
              title={"Street Line 1"}
              // titleStar={true}
              errorMessage="Invalid street line"
            >
              <Form.Control
                className={`form-control ${!true && "danger"}`}
                value={location.ownerInfo?.ownerStreet1 || ""}
                onChange={(e) =>
                  innerChange("ownerInfo.ownerStreet1", e.target.value)
                }
                autoComplete="off"
              />
            </ValidationWrapper>
          </Col>
          <Col className="relative" xs={12} md={6} lg={3}>
            <ValidationWrapper
              // isValid={locationValidations.locationInfo.street2}
              title={"Street Line 2"}
              errorMessage="Invalid street line"
            >
              <Form.Control
                value={location.ownerInfo?.ownerStreet2 || ""}
                onChange={(e) =>
                  innerChange("ownerInfo.ownerStreet2", e.target.value)
                }
                autoComplete="off"
              />
            </ValidationWrapper>
          </Col>

          <Col className="relative" xs={12} md={6} lg={2}>
            <ValidationWrapper
              // isValid={locationValidations.locationInfo.city}
              title={"City"}
              // titleStar={true}
              errorMessage="Invalid city"
            >
              <Form.Control
                className={`form-control ${!true && "danger"}`}
                value={location.ownerInfo?.ownerCity || ""}
                onChange={(e) =>
                  innerChange("ownerInfo.ownerCity", e.target.value)
                }
                autoComplete="off"
              />
            </ValidationWrapper>
          </Col>
          <Col className="relative" xs={12} md={6} lg={2}>
            <ValidationWrapper
              // isValid={locationValidations.locationInfo.state}
              title={"State"}
              // titleStar={true}
              errorMessage="Invalid state"
            >
              <SelectControl
                className={`${styles["input"]} ${!true && styles["not-valid"]}`}
                options={stateCollection}
                value={
                  !!location.ownerInfo.ownerState
                    ? stateCollection.find(
                        (i) => i.value === location.ownerInfo.ownerState
                      )
                    : null
                }
                onChange={(v) => innerChange("ownerInfo.ownerState", v)}
                placeholder=""
              />
            </ValidationWrapper>
          </Col>
          <Col className="relative" xs={12} md={6} lg={2}>
            <ValidationWrapper
              isValid={locationValidations.ownerInfo.ownerZipcode}
              title={"Zip Code"}
              // titleStar={true}
              inputId="input-zipcode"
              errorMessage="Invalid zip code"
            >
              <Form.Control
                className={`form-control ${
                  !locationValidations.ownerInfo.ownerZipcode && "danger"
                }`}
                maxLength={5}
                value={location.ownerInfo?.ownerZipcode || ""}
                onChange={(e) => {
                  if (e.target.value && e.target.value.match(/\D/gi)) {
                    return false;
                  }
                  innerChange("ownerInfo.ownerZipcode", e.target.value);
                }}
                id="input-zipcode"
                autoComplete="off"
              />
            </ValidationWrapper>
          </Col>
        </Row>
        <div className={styles["separator-dashed"]}></div>
        <Row>
          <Col className="relative" xs={12} md={6} lg={6}>
            <ValidationWrapper
              isValid={locationValidations.ownerInfo.ownerCellPhone}
              title="Phone Number"
              titleStar={true}
              inputId="input-cell-phone"
              errorMessage="Invalid Phone Number"
            >
              <PatternFormat
                className={`form-control ${
                  !locationValidations.ownerInfo.ownerCellPhone && "danger"
                }`}
                id="input-cell-phone"
                name="number"
                autoComplete="off"
                customInput={FormControl}
                value={location.ownerInfo.ownerCellPhone || ""}
                onValueChange={(values) => {
                  innerChange("ownerInfo.ownerCellPhone", values.value);
                }}
                type="tel"
                format="+1 (###) ###-####"
                mask="_"
              />
            </ValidationWrapper>
          </Col>
          <Col className="relative" xs={12} md={6} lg={6}>
            <ValidationWrapper
              isValid={locationValidations.ownerInfo.ownerEmailAddress}
              title={"Email Address"}
              titleStar={true}
              inputId="input-emailAddress"
              errorMessage="Invalid email address"
            >
              <Form.Control
                className={`form-control ${
                  !locationValidations.ownerInfo.ownerEmailAddress && "danger"
                }`}
                type="email"
                value={location.ownerInfo?.ownerEmailAddress || ""}
                onChange={(e) => {
                  innerChange("ownerInfo.ownerEmailAddress", e.target.value);
                }}
                id="input-emailAddress"
                autoComplete="off"
              />
            </ValidationWrapper>
          </Col>
        </Row>
      </div>

      {/* Managing Doctor */}
      <div className={styles["section"]}>
        <div className={styles["section-title"]}>
          <span>{process[4].title}</span>
          <div className={styles["line"]}></div>
        </div>
        <Row>
          <Col className="relative" xs={12} md={6} lg={3}>
            <ValidationWrapper
              isValid={locationValidations.managingDoctor.doctorFirstName}
              title={"First Name"}
              titleStar={true}
              errorMessage="Invalid first name"
            >
              <Form.Control
                className={`form-control ${
                  !locationValidations.managingDoctor.doctorFirstName &&
                  "danger"
                }`}
                value={location.managingDoctor?.doctorFirstName || ""}
                onChange={(e) =>
                  innerChange("managingDoctor.doctorFirstName", e.target.value)
                }
                autoComplete="off"
              />
            </ValidationWrapper>
          </Col>
          <Col className="relative" xs={12} md={6} lg={3}>
            <ValidationWrapper
              isValid={locationValidations.managingDoctor.doctorLastName}
              title={"Last Name"}
              titleStar={true}
              errorMessage="Invalid last name"
            >
              <Form.Control
                className={`form-control ${
                  !locationValidations.managingDoctor.doctorLastName && "danger"
                }`}
                value={location.managingDoctor?.doctorLastName || ""}
                onChange={(e) =>
                  innerChange("managingDoctor.doctorLastName", e.target.value)
                }
                autoComplete="off"
              />
            </ValidationWrapper>
          </Col>
          <Col className="relative" xs={12} md={6} lg={3}>
            <ValidationWrapper
              isValid={locationValidations.managingDoctor.doctorLicenseNumber}
              title={"Medical License Number"}
              titleStar={true}
              errorMessage="Invalid License Number"
            >
              <Form.Control
                className={`form-control ${
                  !locationValidations.managingDoctor.doctorLicenseNumber &&
                  "danger"
                }`}
                value={location.managingDoctor?.doctorLicenseNumber || ""}
                onChange={(e) =>
                  innerChange(
                    "managingDoctor.doctorLicenseNumber",
                    e.target.value
                  )
                }
                autoComplete="off"
              />
            </ValidationWrapper>
          </Col>
          <Col className="relative" xs={12} md={6} lg={3}>
            <ValidationWrapper
              isValid={locationValidations.managingDoctor.doctorLicenseState}
              title={"Medical License State"}
              titleStar={true}
              errorMessage="Invalid state"
            >
              <SelectControl
                className={`${styles["input"]} ${
                  !locationValidations.managingDoctor.doctorLicenseState &&
                  styles["not-valid"]
                }`}
                options={stateCollection}
                value={
                  !!location.managingDoctor.doctorLicenseState
                    ? stateCollection.find(
                        (i) =>
                          i.value === location.managingDoctor.doctorLicenseState
                      )
                    : null
                }
                onChange={(v) =>
                  innerChange("managingDoctor.doctorLicenseState", v)
                }
                placeholder=""
              />
            </ValidationWrapper>
          </Col>
        </Row>
      </div>

      {/* Contact Information */}
      <div className={styles["section"]}>
        <div className={styles["section-title"]}>
          <span>{process[5].title}</span>
          <div className={styles["line"]}></div>
        </div>
        <Row>
          <Col className="relative" xs={12} md={6} lg={3}>
            <ValidationWrapper
              isValid={locationValidations.contactInfo.contactFirstName}
              title={"First Name"}
              titleStar={true}
              errorMessage="Invalid first name"
            >
              <Form.Control
                className={`form-control ${
                  !locationValidations.contactInfo.contactFirstName && "danger"
                }`}
                value={location.contactInfo?.contactFirstName || ""}
                onChange={(e) =>
                  innerChange("contactInfo.contactFirstName", e.target.value)
                }
                autoComplete="off"
              />
            </ValidationWrapper>
          </Col>
          <Col className="relative" xs={12} md={6} lg={3}>
            <ValidationWrapper
              isValid={locationValidations.contactInfo.contactLastName}
              title={"Last Name"}
              titleStar={true}
              errorMessage="Invalid last name"
            >
              <Form.Control
                className={`form-control ${
                  !locationValidations.contactInfo.contactLastName && "danger"
                }`}
                value={location.contactInfo?.contactLastName || ""}
                onChange={(e) =>
                  innerChange("contactInfo.contactLastName", e.target.value)
                }
                autoComplete="off"
              />
            </ValidationWrapper>
          </Col>
          <Col className="relative" xs={12} md={6} lg={3}>
            <ValidationWrapper
              isValid={locationValidations.contactInfo.contactOfficePhoneNumber}
              title="Office Phone Number"
              titleStar={true}
              inputId="input-cell-phone"
              errorMessage="Invalid Phone Number"
            >
              <PatternFormat
                className={`form-control ${
                  !locationValidations.contactInfo.contactOfficePhoneNumber &&
                  "danger"
                }`}
                id="input-cell-phone"
                name="number"
                autoComplete="off"
                customInput={FormControl}
                value={location.contactInfo.contactOfficePhoneNumber || ""}
                onValueChange={(values) => {
                  innerChange(
                    "contactInfo.contactOfficePhoneNumber",
                    values.value
                  );
                }}
                type="tel"
                format="+1 (###) ###-####"
                mask="_"
              />
            </ValidationWrapper>
          </Col>
          <Col className="relative" xs={12} md={6} lg={3}>
            <ValidationWrapper
              isValid={locationValidations.contactInfo.contactEmailAddress}
              title={"Email Address"}
              titleStar={true}
              inputId="input-emailAddress"
              errorMessage="Invalid email address"
            >
              <Form.Control
                className={`form-control ${
                  !locationValidations.contactInfo.contactEmailAddress &&
                  "danger"
                }`}
                type="email"
                value={location.contactInfo?.contactEmailAddress || ""}
                onChange={(e) => {
                  innerChange(
                    "contactInfo.contactEmailAddress",
                    e.target.value
                  );
                }}
                id="input-emailAddress"
                autoComplete="off"
              />
            </ValidationWrapper>
          </Col>
        </Row>
      </div>

      {/* Bank Information */}
      <div className={styles["section"]}>
        <div className={styles["section-title"]}>
          <span>{process[6].title}</span>
          <div className={styles["line"]}></div>
        </div>
        <Row>
          <Col className="relative" xs={12} md={6} xl={3}>
            <ValidationWrapper
              isValid={locationValidations.bankInfo.bankName}
              title={"Bank Name"}
              titleStar={true}
              errorMessage="Invalid Name"
            >
              <Form.Control
                className={`form-control ${
                  !locationValidations.bankInfo.bankName && "danger"
                }`}
                value={location.bankInfo?.bankName || ""}
                onChange={(e) =>
                  innerChange("bankInfo.bankName", e.target.value)
                }
                autoComplete="off"
              />
            </ValidationWrapper>
          </Col>
          <Col className="relative" xs={12} md={6} xl={2}>
            <ValidationWrapper
              isValid={locationValidations.bankInfo.routingNumber}
              title="Routing Number"
              titleStar={true}
              errorMessage="Invalid Number"
            >
              <NumericFormat
                name="number"
                className={`form-control ${
                  !locationValidations.bankInfo.routingNumber && "danger"
                }`}
                // thousandSeparator={true}
                // prefix={"$"}
                placeholder=""
                autoComplete="off"
                customInput={FormControl}
                allowNegative={false}
                decimalScale={0}
                fixedDecimalScale={true}
                value={location.bankInfo.routingNumber || ""}
                onValueChange={(values) => {
                  innerChange("bankInfo.routingNumber", values.floatValue);
                }}
                maxLength={8}
              />
            </ValidationWrapper>
          </Col>
          <Col className="relative" xs={12} md={5} xl={2}>
            <ValidationWrapper
              isValid={locationValidations.bankInfo.accountNumber}
              title="Account Number"
              titleStar={true}
              errorMessage="Invalid Number"
            >
              <NumericFormat
                name="number"
                className={`form-control ${
                  !locationValidations.bankInfo.accountNumber && "danger"
                }`}
                // thousandSeparator={true}
                // prefix={"$"}
                placeholder=""
                autoComplete="off"
                customInput={FormControl}
                allowNegative={false}
                decimalScale={0}
                fixedDecimalScale={true}
                value={location.bankInfo?.accountNumber || ""}
                onValueChange={(values) => {
                  innerChange("bankInfo.accountNumber", values.floatValue);
                }}
                maxLength={8}
              />
            </ValidationWrapper>
          </Col>
          <Col className="relative" xs={12} md={7} xl={5}>
            <ValidationWrapper
              isValid={locationValidations.bankInfo.bankLetter}
              title={"Bank Letter or Void Check as An Attachment"}
              titleStar={true}
              errorMessage="Invalid value"
            >
              <Form.Control
                className={`form-control ${
                  !locationValidations.bankInfo.bankLetter && "danger"
                }`}
                value={location.bankInfo?.bankLetter || ""}
                onChange={(e) =>
                  innerChange("bankInfo.bankLetter", e.target.value)
                }
                autoComplete="off"
              />
            </ValidationWrapper>
          </Col>
        </Row>
      </div>
      <div className={styles["required-note"]}>
        <span className={styles["red-star"]}>*</span> required fields
      </div>
    </div>
  );
};

NewLocationInputs.propTypes = {};

export default NewLocationInputs;
