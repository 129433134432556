import React, { useState } from "react";
import styles from "./styles.module.scss";
import { FilterHorizontalIcon, Search01Icon } from "hugeicons-react";
import SelectControl from "../select-control/select-control";
import treatmentType from "../../infrastructure/constants/enums/treatment-types";
import applicationStatus from "../../infrastructure/constants/enums/application-status";
import { Col, Container, Row } from "react-bootstrap";
import ReactSlider from "react-slider";
import _ from "lodash";
import {
  HgSitesSelectControl,
  LenderHGSiteSelectControl,
} from "../select-control";
import PropTypes from "prop-types";
import { commaSeperatedCurrency } from "../../infrastructure/helpers";

const FilterSearch = ({ searchFilterData, setSearchFilterData }) => {
  const [showRange, setShowRange] = useState(false);

  const filterSearchDataInput = (e) => {
    const name = e.target.name;
    setSearchFilterData({
      ...searchFilterData,
      [name]: e.target.value.toUpperCase(),
    });
  };

  return (
    <>
      <div className={styles["container"]}>
        <div className={styles["search-container"]}>
          <input
            type="text"
            name="query"
            placeholder="Search"
            defaultValue={searchFilterData.query}
            className={styles["search-text"]}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                filterSearchDataInput(e);
              }
            }}
          />
          <Search01Icon className={styles["search-icon"]} />
        </div>
        <div className={styles["filter-container"]}>
          <FilterHorizontalIcon
            color="#767676"
            className={styles["filter-icon"]}
          />
          <Container>
            <Row>
              <Col sm={6} md={6} lg={3} className="relative">
                {/* TODO: Create a custom control for this */}
                <button
                  className={styles["price-button"]}
                  onClick={() => setShowRange(!showRange)}
                >
                  {searchFilterData.minAmount > 0
                    ? commaSeperatedCurrency(searchFilterData.minAmount, 0)
                    : "No min"}{" "}
                  –{" "}
                  {searchFilterData.maxAmount < 20000
                    ? commaSeperatedCurrency(searchFilterData.maxAmount, 0)
                    : "No max"}
                </button>
                <div
                  className={styles[showRange ? "price-selector" : "notShow"]}
                >
                  <ReactSlider
                    value={[
                      !searchFilterData.minAmount ||
                      searchFilterData.minAmount < 0
                        ? 0
                        : searchFilterData.minAmount,
                      !searchFilterData.maxAmount ||
                      searchFilterData.maxAmount >= 20000
                        ? 20000
                        : searchFilterData.maxAmount,
                    ]}
                    onAfterChange={(value, index) => {
                      setSearchFilterData({
                        ...searchFilterData,
                        minAmount: value[0] <= 0 ? undefined : value[0],
                        maxAmount:
                          !value[1] || value[1] >= 20000 ? undefined : value[1],
                      });
                      setShowRange(false);
                    }}
                    min={0}
                    max={20000}
                    className="horizontal-slider"
                    thumbClassName="example-thumb"
                    trackClassName="example-track"
                    renderThumb={(props, state) => {
                      const { key, ...restProps } = props;
                      return (
                        <div key={key} {...restProps}>
                          {state.valueNow >= 20000 ? (
                            "Max"
                          ) : (
                            <>{state.valueNow > 0 ? state.valueNow : "Min"}</>
                          )}
                        </div>
                      );
                    }}
                    pearling={true}
                    minDistance={50}
                    step={100}
                  />
                </div>
              </Col>
              <Col sm={6} md={6} lg={3}>
                <SelectControl
                  placeholder="Status"
                  options={applicationStatus.collection}
                  value={
                    !searchFilterData.applicationStatusCollection ||
                    searchFilterData.applicationStatusCollection.length === 0
                      ? []
                      : applicationStatus.collection.filter(
                          (i) =>
                            searchFilterData.applicationStatusCollection.indexOf(
                              i.value
                            ) > -1
                        )
                  }
                  onChange={(v) => {
                    setSearchFilterData({
                      ...searchFilterData,
                      applicationStatusCollection: (v || []).map(
                        (i) => i.value
                      ),
                    });
                  }}
                  isMulti={true}
                />
              </Col>
              <Col sm={6} md={6} lg={3}>
                {/* // singleValue */}
                <SelectControl
                  options={treatmentType.collection}
                  placeholder="Treatment"
                  value={searchFilterData.treatmentType}
                  onChange={(v) => {
                    if (!v) {
                      setSearchFilterData({
                        ...searchFilterData,
                        treatmentType: null,
                      });
                    } else {
                      setSearchFilterData({
                        ...searchFilterData,
                        treatmentType: v.value,
                      });
                    }
                  }}
                />
              </Col>

              {/* Multi values */}
              {/* <SelectControl
                  options={treatmentType.collection}
                  placeholder="Treatment"
                  value={
                    !multiValues || multiValues.length === 0
                      ? []
                      : treatmentType.collection.filter(
                          (i) => multiValues.indexOf(i.value) > -1
                        )
                  }
                  onChange={(v) => {
                    console.log(v);
                    setMultiValues((v || []).map((i) => i.value));
                  }}
                  isMulti
                /> */}

              <Col sm={6} md={6} lg={3}>
                <LenderHGSiteSelectControl
                  placeholder="Locations"
                  onChange={(value) =>
                    setSearchFilterData({
                      ...searchFilterData,
                      locationIdCollection: value ? [value.id] : undefined,
                    })
                  }
                />
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};
FilterSearch.propTypes = {
  searchFilterData: PropTypes.any,
  setSearchFilterData: PropTypes.func,
};
export default FilterSearch;
