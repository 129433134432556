import { useCommonContext } from "../../common-context";
import LocationCard from "../../components/location-card";
import { useHgLocations } from "../../hooks";
import Layout from "../../layout";
import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { apiLoadingStatus } from "../../infrastructure/constants/api";
import LoadingSpinner from "../../components/loading-spinner/loading-spinner";
import LenderHGsSelectControl from "../../components/select-control/lender-hgs-select-control";
import UseGetLenderHgSitesLocation from "../../hooks/get-lender-hg-sites-location";
import _ from "lodash";
import { toast } from "react-toastify";
import { Container } from "react-bootstrap";
import { Location01Icon } from "hugeicons-react";
import { useNavigate } from "react-router-dom";
import Pagination from "../../components/pagination-control/pagination";

const Locations = () => {
  const [{ user, logo }] = useCommonContext();
  const [userLocationCollection, setUserLocationCollection] = useState([]);
  const [hgIdCollection, setHgIdCollection] = useState();
  const [isLoading, setIsLoading] = useState(apiLoadingStatus.unloaded);
  const [page, setPage] = useState({
    start: 0,
    records: 10,
    totalRecords: 10,
  });
  // useHgLocations
  const { getLocationByIds } = useHgLocations();
  const getSites = UseGetLenderHgSitesLocation();
  const navigate = useNavigate();
  const showErrorMassage = (massage) => {
    toast(massage, { type: "error", autoClose: 4000, toastId: "error" });
  };
  const locationAsyncMethod = async (locationId) => {
    const loc = await getLocationByIds(locationId);
    return loc;
  };
  // console.log(user.data.userType);
  useEffect(() => {
    setIsLoading(apiLoadingStatus.loading);

    let actionStatus = null;
    getSites({
      hgIdCollection: hgIdCollection,
      start: page.start,
      records: page.records,
      userType: user.data.userType,
    })
      .then(([res, status, totalCount]) => {
        setIsLoading(apiLoadingStatus.loaded);
        actionStatus = status;
        setUserLocationCollection(res);
        setPage({
          ...page,
          totalRecords: totalCount,
        });
      })
      .catch((err) => {
        actionStatus = _.get(err, "response.headers.action-status", null);
        setIsLoading(apiLoadingStatus.error);
        console.log(err);
      })
      .finally(() => {
        switch (actionStatus) {
          default:
            break;
          case "Failed":
            showErrorMassage("Failed");
            break;
          case "UsedBefore":
            showErrorMassage("Used Before");
            break;
          case "SameAsPrevious":
            showErrorMassage("Same As Previous");
            break;
          case "AdminLockedOut":
            showErrorMassage("Admin LockedOut ");
            break;
          case "InvalidCredentialsNewlyLockedOut":
            showErrorMassage("Invalid Credentials Newly LockedOut");
            break;
          case "InvalidCredentials":
            showErrorMassage("Invalid Credentials");
            break;
          case "ValidationError":
            showErrorMassage("Validation Error");
            break;
          case "NotExists":
            showErrorMassage("Not Found");
            break;
          case "Forbidden":
            showErrorMassage("Forbidden ");
            break;
          case "Success":
            break;
        }
      });
  }, [user.data.hgSiteIdsCollection, hgIdCollection, page.start]);

  return (
    <div>
      <Layout
        user={{
          type: "admin",
          name: user.data.firstName + " " + user.data.lastName,
          logo: logo.data?.url,
        }}
      >
        <LoadingSpinner isLoading={isLoading === apiLoadingStatus.loading} />
        <Container className={styles["container"]}>
          <div className={styles["select"]}>
            <div xs={6} className={styles["hcg"]}>
              {user.data.userType === "Lender" && (
                <LenderHGsSelectControl
                  onChange={(o) => {
                    setHgIdCollection(o.id);
                    setPage({
                      ...page,
                      start: 0,
                    });
                  }}
                />
              )}
            </div>
            <div xs={6} className={styles["btn"]}>
              {user.data.userType === "HealthcareGroup" && (
                <button
                  className={styles["new-location"]}
                  onClick={() => navigate("/locations/new")}
                >
                  <Location01Icon className={styles["icon"]} /> New Location
                </button>
              )}
            </div>
          </div>
          <div>
            {!!user.data.hgSiteIdsCollection &&
              !!userLocationCollection &&
              userLocationCollection.length > 0 &&
              userLocationCollection.map((location, index) => {
                return <LocationCard location={location} key={index} />;
              })}
          </div>
        </Container>
        <Pagination
          start={page.start}
          records={page.records}
          spanWidth={5}
          totalCount={page.totalRecords}
          onClick={(value) =>
            setPage({
              ...page,
              start: value,
            })
          }
          displayViewOptions={false}
          handleViewOptions={(records) =>
            setPage({
              ...page,
              records: records,
            })
          }
          // className={styles["pagination"]}
        />
      </Layout>
    </div>
  );
};

export default Locations;
