import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useCommonContext } from "../common-context";
import { apiLoadingStatus } from "../infrastructure/constants/api";
import { useLocation, useNavigate } from "react-router-dom";
import LoadingSpinner from "../components/loading-spinner/loading-spinner";

const CheckAuthorization = ({ children = <></> }) => {
  const [{ user }] = useCommonContext();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (
      location.pathname.startsWith("/login") ||
      (location.pathname.startsWith("/application") &&
        !location.pathname.startsWith("/applications")) ||
      location.pathname.startsWith("/recovery") ||
      location.pathname.startsWith("/invitation")
    ) {
      return;
    }
    if (
      user.loadingStatus === apiLoadingStatus.unauthorized ||
      (user.loadingStatus === apiLoadingStatus.loaded && !user.data)
    ) {
      navigate(`/login?return=${encodeURI(location.pathname)}`);
    }
  }, [user.loadingStatus]);

  if (user.loadingStatus === apiLoadingStatus.loading) {
    return (
      <div className='page-loader'>
        <LoadingSpinner
          isLoading={user.loadingStatus === apiLoadingStatus.loading}
        />
      </div>
    );
  }

  if (
    location.pathname.startsWith("/login") ||
    (location.pathname.startsWith("/application") &&
      !location.pathname.startsWith("/applications")) ||
    location.pathname.startsWith("/recovery") ||
    location.pathname.startsWith("/invitation")
  ) {
    return <>{children}</>;
  }

  if (!user.data) {
    return <></>;
  }
  return <>{children}</>;
};

CheckAuthorization.propTypes = {
  children: PropTypes.any,
};

export default CheckAuthorization;
