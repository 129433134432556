import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { IntlProvider } from "react-intl";
import { useCommonContext } from "./common-context";
import en from "./locals/en";
import es from "./locals/es";

const Localize = ({ children }) => {
  const [{ language }] = useCommonContext();
  
  const messages = useMemo(() => {
    switch (language) {
      default:
      case "en":
        return en;
      case "es":
        return es;
    }
  }, [language]);
  return (
    <IntlProvider locale={language} messages={messages}>
      {children}
    </IntlProvider>
  );
};

Localize.propTypes = {
  children: PropTypes.any,
};

export default Localize;
