/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { getItem } from "../../utils/storage.js";
import _ from "lodash";
import styles from "./styles.module.scss";
import { Container, Row } from "react-bootstrap";

export function DashboardText() {
  const userType = JSON.parse(getItem("role"));

  return (
    <div className="my_6 fs_lg">
      <div className={styles["definitions-Title"]}>KPI Definitions</div>
      <Container className={styles["definition-container"]}>
        <div className={styles["definition"]}>
          <div className={styles["title"]}>Submitted Applications:</div>
          <p className={styles["text"]}>
            The total number of applications sent by DentiRate for pre-approval.
          </p>
        </div>
        <div className={styles["definition"]}>
          <div className={styles["title"]}>Pre-Approved Applications: </div>
          <p>The total number of applications that have been pre-approved.</p>
        </div>
        <div className={styles["definition"]}>
          <p>
            {(userType || "").toUpperCase() === "LENDER" ? (
              <span>
                <div className={styles["title"]}>Leads: </div> The total number
                of leads that are sent by DentiRate.
              </span>
            ) : (
              <span>
                <div className={styles["title"]}>Prospect Applications: </div>{" "}
                The total number of applications forwarded to lenders.
              </span>
            )}
          </p>
        </div>
        <div className={styles["definition"]}>
          <div className={styles["title"]}>Pre-Approved Rate: </div>
          <p>
            The percentage of applications pre-approved through the DentiRate
            app out of all submitted applications..
          </p>
        </div>
        <div className={styles["definition"]}>
          <p>
            {(userType || "").toUpperCase() === "LENDER" ? (
              <span>
                <div className={styles["title"]}>Lead Rate: </div> The
                percentage of leads delivered from all applications requested
                for pre-approval through the DentiRate App.
              </span>
            ) : (
              <span>
                <div className={styles["title"]}>Prospect Rate: </div> The
                percentage of prospect applications out of all applications
                requested for pre-approval through the DentiRate App.
              </span>
            )}
          </p>
        </div>
        <div className={styles["definition"]}>
          <div className={styles["title"]}>Submitted Amount: </div>
          <p>The total requested amount via DentiRate App.</p>
        </div>
        <div className={styles["definition"]}>
          <div className={styles["title"]}>Pre-Approved Amount: </div>
          <p>The total amount pre-approved by lender.</p>
        </div>
        <div className={styles["definition"]}>
          <p>
            {(userType || "").toUpperCase() === "LENDER" ? (
              <span>
                <div className={styles["title"]}>Lead Amount: </div> The total
                amount of finalized leads.
              </span>
            ) : (
              <span>
                <div className={styles["title"]}>Prospect Amount: </div> The
                total amount of prospect applications.
              </span>
            )}
          </p>
        </div>
        <div className={styles["definition"]}>
          <div className={styles["title"]}>Average Submitted Amount: </div>
          <p>Total requested amount / Number of submitted applications.</p>
        </div>
        <div className={styles["definition"]}>
          <div className={styles["title"]}>Average Pre-Approved Amount: </div>
          <p>
            Total pre-approved amount / Number of pre-approved applications.
          </p>
        </div>
        <div className={styles["definition"]}>
          <p>
            {(userType || "").toUpperCase() === "LENDER" ? (
              <span>
                <div className={styles["title"]}>Average Lead Amount: </div>{" "}
                Total lead amount / Number of leads.
              </span>
            ) : (
              <span>
                <div className={styles["title"]}>Average Prospect Amount: </div>{" "}
                Total Prospect amount / Number of prospect applications.
              </span>
            )}
          </p>
        </div>
        <div className={styles["definition"]}>
          <div className={styles["title"]}>Number of Active Users: </div>
          <p>The number of users with active profile.</p>
        </div>
        <div className={styles["definition"]}>
          <div className={styles["title"]}>Number of Active Locations: </div>{" "}
          <p>The number of registered locations that are active.</p>
        </div>
        <div className={styles["definition"]}>
          <div className={styles["title"]}>Number of Active HCG: </div>
          <p>The number of registered HCG that are active.</p>
        </div>
        <div className={styles["definition"]}>
          <div className={styles["title"]}>Healthcare Group:: </div>
          <p>
            A healthcare organization with multiple locations. In the dental
            industry, we categorize a DSO as an HCG.
          </p>
        </div>
      </Container>
    </div>
  );
}

// export function Header() {
//   const navigate = useNavigate();

//   return (
//     <>
//       <header>
//         <div className="nav container">
//           <button className="btn_return me_3" onClick={() => navigate("/")} />
//           <div className="title">Dashboard</div>
//         </div>
//       </header>
//     </>
//   );
// }
