import React, { useEffect, useState } from "react";
import {
  getDateRangeForPeriod,
  getDynamicViewType,
} from "../../infrastructure/helpers/date-helpers.js";
import _ from "lodash";
import { useCommonContext } from "../../common-context/common-context-provider.jsx";
import Layout from "../../layout.jsx";
import ShowDataControl from "../../components/show-data-control/show-data-control.jsx";
import styles from "./styles.module.scss";
import { Container } from "react-bootstrap";
import SummaryControl from "../../components/dashboard-controls/summary-control/summary-control.jsx";
import TabularReportControl from "../../components/dashboard-controls/tabular-report-control/tabular-report-control.jsx";
import DownloadReportControl from "../../components/dashboard-controls/download-report-control/index.js";
import { DashboardText } from "../../components/DashBoardText/dashboard-text.jsx";

const Dashboard = (props) => {
  const options = ["WTD", "MTD", "QTD", "YTD"];
  const [{ user }] = useCommonContext();
  const [homepageFilter, setHomepageFilter] = useState({
    viewType: getDynamicViewType(),
    timeZoneId: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });
  const [searchParams, setSearchParams] = useState({
    userType: user.data.userType,
    startDate: getDateRangeForPeriod(homepageFilter.viewType).startDate,
    endDate: getDateRangeForPeriod(homepageFilter.viewType).endDate,
    timeZoneId: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });

  //changing searsh parameters according to view type selected by user
  useEffect(() => {
    const temp = getDateRangeForPeriod(homepageFilter.viewType);
    const newSearchParams = {
      ...searchParams,
      startDate: temp.startDate,
      endDate: temp.endDate,
    };
    if (!_.isEqual(searchParams, newSearchParams)) {
      setSearchParams(newSearchParams);
    }
  }, [homepageFilter.viewType]);

  return (
    <Layout>
      <div className={[styles["main"], "wrapper-content"].join(" ")}>
        <div className={styles["container"]}>
          {/* <Header /> */}
          <Container className={styles["header-container"]}>
            <div className={styles["title"]}>OVERVIEW</div>
            <ShowDataControl
              period={
                options.find(
                  (i) => i === homepageFilter?.viewType?.toUpperCase()
                ) || options[0]
              }
              callbackfunction={(o) => {
                if (o) {
                  setHomepageFilter({
                    ...homepageFilter,
                    viewType: o,
                  });
                }
              }}
            />
          </Container>
          <SummaryControl filters={searchParams} />
          <TabularReportControl
            filters={searchParams}
            userType={user.data.userType}
          />
          <DownloadReportControl filters={searchParams} />
          <DashboardText />
        </div>
      </div>
    </Layout>
  );
};

Dashboard.propTypes = {};

export default Dashboard;
