// LoanOptions.jsx
import React, { useCallback, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDown,
  faBalanceScale,
  faDollar,
  faDollarSign,
  faExternalLink,
  faExternalLinkSquare,
  faLightbulb,
  faPercent,
} from "@fortawesome/free-solid-svg-icons";
import { Button, Col, Row, Table } from "react-bootstrap";
import {
  commaSeperatedCurrency,
  commaSeperatedDecimal,
} from "../../infrastructure/helpers";
import { usePrevious } from "../../hooks";
import { isMobile } from "react-device-detect";

import _ from "lodash";
import SortSign from "../../components/sort-sign";
import ShowMoreLess from "../../components/show-more-less";
import StickyHeader from "../../components/sticky-header";
import { FormattedMessage } from "react-intl";
import ScrollableMenu from "../../components/scrollable-menu";
import {
  CalendarCheckIn01Icon,
  ChartBarLineIcon,
  ChartColumnIcon,
  ChartIcon,
  ChartLineData01Icon,
  CouponPercentIcon,
  Invoice01Icon,
  MoneyBag01Icon,
  MoneyBag02Icon,
  PercentIcon,
  PercentSquareIcon,
  PresentationBarChart01Icon,
  PresentationBarChart02Icon,
} from "hugeicons-react";
import { useNavigate } from "react-router-dom";
import ApplyModal from "./apply-modal";

const handleSort = (arr, direction, column) => {
  if (direction === "asc") {
    return _.orderBy(arr, column, "asc");
  } else {
    return _.orderBy(arr, column, "desc");
  }
};
const LoanOptions = ({
  prequalicationCollection = [],

  initialInfo = {},
  application = {},
  lenderCollection = {},
}) => {
  const [collection, setCollection] = useState(prequalicationCollection);
  const [sort, setSort] = useState({
    direction: "asc",
    column: "minMonthlyPayment",
  });
  const previousSort = usePrevious(sort);
  const previousCollection = usePrevious(prequalicationCollection);
  const [viewMore, setViewMore] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [lenderIndex, setLenderIndex] = useState(0);

  const switchSort = useCallback(
    (column, direction) => {
      if (column === sort.column) {
        setSort({
          ...sort,
          direction: direction || (sort.direction === "asc" ? "desc" : "asc"),
        });
      } else {
        setSort({
          ...sort,
          column,
          direction:
            direction ||
            (column === "minMonthlyPayment" || column === "minInterestRate"
              ? "asc"
              : "desc"),
        });
      }
    },
    [sort]
  );

  useEffect(() => {
    if (
      _.isEqual(previousSort, sort) &&
      _.isEqual(previousCollection, prequalicationCollection)
    ) {
      return;
    }
    const arr =
      handleSort(prequalicationCollection, sort.direction, sort.column) || [];
    setCollection([...arr]);
  }, [sort, prequalicationCollection]);
  return (
    <>
      <div
        className={styles["filter-container"]}
        style={isMobile ? { maxWidth: "100vw" } : {}}
      >
        <ScrollableMenu>
          <Button
            className={`${styles["btn"]} ${
              sort.column === "score" && `${styles["active"]}`
            }`}
            onClick={(e) => {
              e.preventDefault();
              e.currentTarget.blur();
              switchSort("score", "desc");
            }}
          >
            {/* <FontAwesomeIcon icon={faLightbulb} />{" "} */}
            <FormattedMessage id="Recommended" />
            {/* <SortSign
                  refColumnName='score'
                  currentColumnName={sort.column}
                  className={styles["sort-icon"]}
                  direction={sort.direction}
                /> */}
          </Button>
          <Button
            className={`${styles["btn"]} ${
              sort.column === "minMonthlyPayment" && `${styles["active"]}`
            }`}
            onClick={(e) => {
              e.preventDefault();
              e.currentTarget.blur();
              switchSort("minMonthlyPayment", "asc");
            }}
          >
            {/* <FontAwesomeIcon icon={faDollarSign} />{" "} */}
            <FormattedMessage id="MinimumMonthlyPayment" />
            {/* <SortSign
                  refColumnName='minMonthlyPayment'
                  currentColumnName={sort.column}
                  className={styles["sort-icon"]}
                  direction={sort.direction}
                /> */}
          </Button>
          <Button
            className={`${styles["btn"]} ${
              sort.column === "minInterestRate" && `${styles["active"]}`
            }`}
            // className={`btn btn-outline-success ${styles.btnFilter} ${
            //   sort.column === "minInterestRate" && styles.active
            // }`}
            onClick={(e) => {
              e.preventDefault();
              e.currentTarget.blur();
              switchSort("minInterestRate", "asc");
            }}
          >
            {/* <FontAwesomeIcon icon={faPercent} />{" "} */}
            <FormattedMessage id="BestInterestRate" />
            {/* <SortSign
                  refColumnName='minInterestRate'
                  currentColumnName={sort.column}
                  className={styles["sort-icon"]}
                  direction={sort.direction}
                /> */}
          </Button>
          <Button
            className={`${styles["btn"]} ${
              sort.column === "maxFinancingAmount" && `${styles["active"]}`
            }`}
            // className={`btn btn-outline-success ${styles.btnFilter} ${
            //   sort.column === "maxFinancingAmount" && styles.active
            // }`}
            onClick={(e) => {
              e.preventDefault();
              e.currentTarget.blur();
              switchSort("maxFinancingAmount", "desc");
            }}
          >
            {/* <FontAwesomeIcon icon={faBalanceScale} />{" "} */}
            <FormattedMessage id="HighestAmount" />
            {/* <SortSign
                  refColumnName='maxFinancingAmount'
                  currentColumnName={sort.column}
                  className={styles["sort-icon"]}
                  direction={sort.direction}
                /> */}
          </Button>
        </ScrollableMenu>
      </div>
      <ApplyModal
        show={showModal}
        handleClose={setShowModal}
        application={application}
        lenderCollection={lenderCollection}
        lenderIndex={lenderIndex}
        initialInfo={initialInfo}
      />
      <div className={`${styles["loan-option-container"]} `}>
        {/* <StickyHeader
        initalOffset={0}
        // onStickyChange={(v) => setIsSticky(v)}
        containerStickyClass={styles["sticky-header-container"]}
      > */}

        {/* </StickyHeader> */}
        <div className="row justify-content-center mt-3">
          <div>
            {(collection || []).map((item, index) => (
              <div key={index} className={styles["card"]}>
                <div className={styles["company-title"]}>
                  {true ? (
                    <div className={styles["offered"]}>Offered</div>
                  ) : (
                    <div className={styles["Sponsored"]}>Sponsored</div>
                  )}

                  <img
                    src={item.logoUrl}
                    alt={`${item.lenderName} Logo`}
                    // className="img-fluid mx-auto d-block"
                    // style={{ width: "150px" }}
                  />
                  <h5 className={styles["company-name"]}>
                    <span>{item.lenderName}</span>
                    {/* <FontAwesomeIcon icon={faExternalLink} /> */}
                  </h5>
                </div>
                <div className={styles["info1"]}>
                  <div
                    className={`${styles.info} mb-2`}
                    style={{
                      padding: "16px 16px 0px 16px",
                    }}
                  >
                    <span className={styles.infoLabel}>
                      <Invoice01Icon />
                      <FormattedMessage id="Financing type" />:
                    </span>
                    <span className={styles.infoValue}>
                      {!!item.loanType && (
                        <b>
                          <FormattedMessage
                            id={`LoanType_${item.loanType.toUpperCase()}`}
                          />
                        </b>
                      )}
                    </span>
                  </div>
                  <div className={styles["line"]} />
                  <div
                    className={`${styles.info} mb-2`}
                    style={{ padding: "8px 16px 0px 16px" }}
                  >
                    <span className={styles.infoLabel}>
                      <MoneyBag02Icon />
                      <FormattedMessage id="Financing amount" />:
                    </span>
                    <span className={styles.infoValue}>
                      {(typeof item.maxFinancingAmount === "number" && (
                        <>
                          <b>
                            {commaSeperatedCurrency(item.maxFinancingAmount, 0)}
                          </b>
                        </>
                      )) || <>–</>}
                    </span>
                  </div>
                  <div className={styles["line"]} />

                  {["loan", "InstallmentPlan".toLowerCase()].indexOf(
                    (item.loanType || "_random_").toLowerCase()
                  ) > -1 && (
                    <>
                      <div
                        className={`${styles.info} mb-2`}
                        style={{ padding: "8px 16px 0 16px" }}
                      >
                        <span className={styles.infoLabel}>
                          <ChartColumnIcon />
                          <FormattedMessage id="Number of installments" />:
                        </span>
                        <span className={styles.infoValue}>
                          <b>
                            {item.numberOfInstallment ||
                              item.initialPeriodMonths}
                          </b>
                        </span>
                      </div>
                      <div className={styles["line"]} />
                    </>
                  )}
                  <div
                    className={`${styles.info} mb-2`}
                    style={{
                      padding: "8px 16px",
                    }}
                  >
                    <span className={styles.infoLabel}>
                      <PercentSquareIcon />
                      <FormattedMessage id="Interest rate" />:
                    </span>
                    <span className={styles.infoValue}>
                      {(typeof item.minInterestRate === "number" && (
                        <>
                          <b>
                            {commaSeperatedDecimal(
                              item.minInterestRate * 100,
                              item.minInterestRate > 0 ? 1 : 0
                            )}
                            %
                          </b>
                        </>
                      )) || <>–</>}
                    </span>
                  </div>
                </div>
                <div
                  className={styles.info}
                  style={{ padding: "16px 16px 0 16px" }}
                >
                  <span className={styles.infoLabel}>
                    <CalendarCheckIn01Icon />
                    <FormattedMessage id="Monthly payment" />:
                  </span>
                  <span
                    className={`${styles.infoValue} ${styles.bigNum}`}
                    style={{ fontSize: "24px" }}
                  >
                    {(typeof item.minMonthlyPayment === "number" && (
                      <>
                        <b>
                          {commaSeperatedCurrency(item.minMonthlyPayment, 0)}
                        </b>
                      </>
                    )) || <>–</>}
                  </span>
                </div>
                <div className="d-grid mt-2">
                  <button
                    className={`btn btn-success ${styles["apply-button"]}`}
                    onClick={() => {
                      if (item.preConsodilatedType === "IHF") {
                        // console.log(item);
                        setShowModal(true);
                        setLenderIndex(item);
                      } else {
                        document.location.href = item.redirectLink;
                      }
                      return;
                    }}
                  >
                    <FormattedMessage id="Apply" />
                  </button>
                </div>
                {Array.isArray(item.loanOptionCollection) &&
                  item.loanOptionCollection.length > 1 && (
                    <>
                      <div
                        className={`mt-1 ${styles["view-more-details-container"]}`}
                      >
                        <ShowMoreLess
                          getLabel={(e) =>
                            e
                              ? "Show Less"
                              : `Show More (${item.loanOptionCollection.length})`
                          }
                          initialShowMore={false}
                          collapsedHeight={75}
                          isActive
                        >
                          <Table className={styles["view-more-table"]}>
                            <thead>
                              <tr>
                                <td>Type</td>
                                <td>Amount</td>
                                <td>Interest Rate</td>
                                <td>Monthly Payment</td>
                                <td></td>
                              </tr>
                            </thead>
                            <tbody>
                              {item.loanOptionCollection.map((i, idx2) => (
                                <tr key={`${index}-${idx2}`}>
                                  <td>
                                    {!!i.loanType && (
                                      <FormattedMessage
                                        id={`LoanType_${i.loanType.toUpperCase()}`}
                                      />
                                    )}
                                  </td>
                                  <td>
                                    {(typeof item.maxFinancingAmount ===
                                      "number" && (
                                      <div>
                                        {commaSeperatedCurrency(
                                          item.maxFinancingAmount,
                                          0
                                        )}
                                      </div>
                                    )) ||
                                      "–"}
                                  </td>
                                  <td className="t_center">
                                    {(typeof i.interestRate === "number" && (
                                      <div>
                                        {commaSeperatedDecimal(
                                          i.interestRate * 100,
                                          1
                                        )}
                                        %
                                      </div>
                                    )) ||
                                      "–"}
                                  </td>
                                  <td className="t_center">
                                    {(typeof i.monthlyPayment === "number" && (
                                      <div>
                                        {commaSeperatedCurrency(
                                          i.monthlyPayment,
                                          0
                                        )}
                                      </div>
                                    )) ||
                                      "–"}
                                  </td>
                                  <td className="pb-0 pt-2">
                                    <Button
                                      size="sm"
                                      className={`btn btn-success ${styles["apply-button"]} ${styles["small"]} ${styles["outline"]}`}
                                    >
                                      Apply
                                    </Button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </ShowMoreLess>
                      </div>
                    </>
                  )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default LoanOptions;
