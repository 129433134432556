import { apiUrls } from "../infrastructure/constants/api";
import apiHelperMethods from "../infrastructure/helpers/api-helpers";

const useChangePassword = () => {
  const postData = ({ password, code }) => {
    const data = {
      encryptedCode: code,
      newPassword: password,
    };

    const [results] = apiHelperMethods.postWithReturn(
      apiUrls.userIdentityUrls.changePassword,
      data
    );
    return results;
  };
  return postData;
};

export default useChangePassword;
