import _ from "lodash";
import apiHelperMethods from "../infrastructure/helpers/api-helpers";
import { apiUrls } from "../infrastructure/constants/api";

const useGetKpiSummary = () => {
  const loadData = async ({ userType, startDate, endDate, timeZoneId }) => {
    let url;
    switch (userType) {
      case "HealthcareGroup":
        url = apiUrls.hgUrls.getKpiSummary;
        break;
      case "Lender":
        url = apiUrls.lenderUrls.getKpiSummary;
        break;
      default:
        url = apiUrls.hgUrls.getKpiSummary;
        break;
    }
    const data = {
      startDate,
      endDate,
      timeZoneId,
    };
    // make sure you
    return apiHelperMethods.getWithReturn(url, data).then((res) => {
      let tempUsers;
      if (userType === "HealthcareGroup") {
        tempUsers = res.data?.data?.hgKpiSummaryCollection[0];
      }
      if (userType === "Lender") {
        tempUsers = res.data?.data?.lenderKpiSummaryCollection[0];
      }
      if (!tempUsers) {
        return Promise.reject("No user found!");
      }
      const actionStatus = _.get(res, "headers.action-status", null);
      return Promise.resolve([tempUsers, actionStatus]);
    });
  };
  return loadData;
};

export default useGetKpiSummary;
