import { useState } from "react";
import FormCustomControl from "../form-custom-control";
import VerificationInput from "react-verification-input";
import { Form } from "react-bootstrap";
import useMfa from "../../hooks/use-mfa";
import { apiLoadingStatus } from "../../infrastructure/constants/api";
import _ from "lodash";
import { toast } from "react-toastify";
import styles from "./styles.module.scss";
import CheckBox from "../check-box/check-box";

const MfaControl = ({
  setLoadingStatus = () => {},
  setStep = () => {},
  onSuccessfulLogin = () => {},
}) => {
  const [mfaForm, setMfaForm] = useState({
    token: "",
    mfaType: "EmailToken",
    trustComputer: false,
  });

  const handleMfa = useMfa();

  const showErrorMessage = (message) => {
    toast(message, {
      type: "error",
      toastId: "login error",
    });
  };

  function onMfaContinue() {
    setLoadingStatus(apiLoadingStatus.loading);
    let actionStatus = undefined;
    let data = undefined;
    handleMfa({
      token: mfaForm.token,
      mfaType: mfaForm.mfaType,
      trustComputer: mfaForm.trustComputer,
    })
      .then((res) => {
        actionStatus = _.get(res, "headers.action-status", null);
        data = _.get(res, "data");
      })
      .catch((err) => {
        actionStatus = _.get(err, "response.headers.action-status", null);
      })
      .finally(() => {
        setLoadingStatus(apiLoadingStatus.loaded);
        switch (actionStatus) {
          default:
            showErrorMessage("Unknown error");
            break;
          case "Failed":
          case "ExpiredToken":
            showErrorMessage("Invalid credentials");
            break;
          case "TokenAlreadyUsed ":
            showErrorMessage("This toke have been already used");
            break;
          case "InvalidToken":
            showErrorMessage("Your code is invalid");
            break;
          case "ValidationError":
            showErrorMessage("Token is short or long");
            break;
          case "ActiveSessionExists":
            toast("This token has been already used.", { type: "info" });
            setStep(1);
            break;
          case "Success":
            onSuccessfulLogin({ newUser: data?.data?.user });
            break;
        }
      });
  }

  return (
    <div className={styles["mfa-control"]}>
      <FormCustomControl onContinue={onMfaContinue}>
        <div className={styles["mfa-containerr"]}>
          <div className={styles["mfa-label"]}>
            Enter your Verification code here
          </div>
          <VerificationInput
            validChars="0-9"
            placeholder="_"
            length={6}
            onComplete={(value) =>
              setMfaForm({
                ...mfaForm,
                token: value,
              })
            }
            autoFocus
            inputProps={{ inputMode: "numeric" }}
            classNames={{
              container: styles["mfa-container"],
              character: styles["character"],
              characterInactive: styles["character--inactive"],
              characterSelected: styles["character--selected"],
              characterFilled: styles["character--filled"],
            }}
          />
        </div>
        <CheckBox
          text="Trust this computer"
          onchange={(event) =>
            setMfaForm({
              ...mfaForm,
              trustComputer: event.target.checked,
            })
          }
        />
      </FormCustomControl>
    </div>
  );
};

export default MfaControl;
