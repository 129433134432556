import styles from "./styles.module.scss";
import React from "react";
import PropTypes from "prop-types";
import {
  TaskDone01Icon,
  NoteEditIcon,
  NoteIcon,
  TaskEdit01Icon,
} from "hugeicons-react";
import { commaSeperatedValues } from "../../infrastructure/helpers";

const StatisticItem = ({ type = "", value = 0 }) => {
  return (
    <div className={`${styles["container"]} ${styles[`${type}`]}`}>
      <div className={styles["value-box"]}>{commaSeperatedValues(value)}</div>
      <div className={`${styles["type-box"]} ${styles[`${type}-box`]}`}>
        {type === "leads" && (
          <TaskDone01Icon className={styles[`${type}-icon`]} />
        )}
        {type === "submitted" && (
          <TaskDone01Icon className={styles[`${type}-icon`]} />
        )}
        {type === "prospects" && (
          <TaskEdit01Icon className={styles[`${type}-icon`]} />
        )}
        {type === "pre-approved" && (
          <NoteEditIcon className={styles[`${type}-icon`]} />
        )}
        {type === "applications" && (
          <NoteIcon className={styles[`${type}-icon`]} />
        )}
        <span>{type}</span>
      </div>
    </div>
  );
};

StatisticItem.propTypes = {
  type: PropTypes.oneOf([
    "leads",
    "pre-approved",
    "applications",
    "prospects",
    "submitted",
  ]),
  value: PropTypes.number,
};

export default StatisticItem;
