const applicationStatus = {
  initiated: "Initiated",
  pendingApplicantSubmission: "PendingApplicantSubmission",
  submitted: "Submitted",
  processing: "Processing",
  processedSuccessfulyWithApproval: "ProcessedSuccessfulyWithApproval",
  processedNoApproval: "ProcessedNoApproval",
  processedNoLender: "ProcessedNoLender",
  processingError: "ProcessingError",
  applicantApplied: "ApplicantApplied",
  abandoned: "Abandoned",
  lenderLinkClicked: "LenderLinkClicked",
  getValueByText: (v) => {
    if (!v || typeof v !== "string" || v.length === 0) {
      return null;
    }
    switch (v.toLowerCase()) {
      default:
        return null;
      case "Initiated".toLocaleLowerCase():
        return applicationStatus.initiated;
      case "PendingApplicantSubmission".toLocaleLowerCase():
        return applicationStatus.pendingApplicantSubmission;
      case "Submitted".toLocaleLowerCase():
        return applicationStatus.submitted;
      case "Processing".toLocaleLowerCase():
        return applicationStatus.processing;
      case "ProcessedSuccessfulyWithApproval".toLocaleLowerCase():
        return applicationStatus.processedSuccessfulyWithApproval;
      case "ProcessedNoApproval".toLocaleLowerCase():
        return applicationStatus.processedNoApproval;
      case "ProcessedNoLender".toLocaleLowerCase():
        return applicationStatus.processedNoLender;
      case "ProcessingError".toLocaleLowerCase():
        return applicationStatus.processingError;
      case "ApplicantApplied".toLocaleLowerCase():
        return applicationStatus.applicantApplied;
      case "Abandoned".toLocaleLowerCase():
        return applicationStatus.abandoned;
      case "LenderLinkClicked".toLocaleLowerCase():
        return applicationStatus.lenderLinkClicked;
    }
  },
  collection: [
    // { label: "None", value: undefined },
    { label: "Initiated", value: "Initiated" },
    {
      label: "Pending Applicant Submission",
      value: "PendingApplicantSubmission",
    },
    { label: "Submitted", value: "Submitted" },
    { label: "Processing", value: "Processing" },
    {
      label: "Processed Successfuly With Approval",
      value: "ProcessedSuccessfulyWithApproval",
    },
    { label: "Processed No Approval", value: "ProcessedNoApproval" },
    { label: "Processed No Lender", value: "ProcessedNoLender" },
    { label: "Processing Error", value: "ProcessingError" },
    {
      label: "Applicant Applied",
      value: "ApplicantApplied",
    },
    { label: "Abandoned", value: "Abandoned" },
    { label: "Lender Link Clicked", value: "LenderLinkClicked" },
  ],
  getLabel: (v) => {
    const t = applicationStatus.getValueByText(v);
    if (!t) {
      return null;
    }
    return applicationStatus.collection.find((i) => i.value === t)?.label;
  },
};

export default applicationStatus;
