import React, { useEffect, useRef, useState } from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { useCommonContext } from "../../common-context";
import { useParams } from "react-router-dom";
import UseGetPaginatedUserList from "../../hooks/use-get-paginated-user-list";
import { apiLoadingStatus } from "../../infrastructure/constants/api";
import _ from "lodash";
import LoadingSpinner from "../../components/loading-spinner/loading-spinner";
import { toast } from "react-toastify";
import styles from "./styles.module.scss";
import { Container, Row, Col, Form } from "react-bootstrap";
import {
  MultiplicationSignCircleIcon,
  Tick02Icon,
  UserCircleIcon,
} from "hugeicons-react";
import { useNavigate } from "react-router-dom";
import {
  HgSitesSelectControl,
  LenderHGSiteSelectControl,
  LenderHGsSelectControl,
} from "../../components/select-control";
import FormCustomControl from "../../components/form-custom-control/form-custom-control";
import EditUserBio from "../../hooks/edit-user-bio";
import EditUserLocation from "../../hooks/edit-user-location";

const EditUser = () => {
  const [{ user, logo }] = useCommonContext();
  const { userId } = useParams();
  // console.log(user);
  const [loadingStatus, setLoadingStatus] = useState(apiLoadingStatus.unloaded);
  const navigate = useNavigate();
  const [newUser, setNewUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
    roleType: "",
    hgSiteIdsCollection: [],
  });
  const userRef = useRef(null);
  // console.log(newUser, user);
  const [newUserValidation, setNewUserValidation] = useState({
    email: true,
    firstName: true,
    lastName: true,
    submit: true,
  });
  const [step, setStep] = useState(1);
  const [hcg, setHcg] = useState();

  const showErrorMessage = (message) => {
    toast(message, {
      type: "error",
      toastId: "login error",
    });
  };
  function validateEmail(email) {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return !!email && email !== "" && emailRegex.test(email);
  }

  function validateForm() {
    ////////
    const emailValid = validateEmail(newUser.email);
    const firstNameValid = !!newUser.firstName && newUser.firstName.length > 0;
    const lastNameValid = !!newUser.lastName && newUser.lastName.length > 0;

    setNewUserValidation({
      ...newUserValidation,
      email: emailValid,
      firstName: firstNameValid,
      lastName: lastNameValid,
    });
    return emailValid && firstNameValid && lastNameValid;
  }

  function userInput(event) {
    const name = event.target.name;
    setNewUser({
      ...newUser,
      [name]: event.target.value,
    });
    setNewUserValidation({
      ...newUserValidation,
      [name]: true,
    });
  }

  const handleEditUser = EditUserBio();
  const handleEditLocation = EditUserLocation();
  // console.log(newUser.hgSiteIdsCollection);
  window.user = newUser;
  window.userRef = userRef.current;
  function editUserInfo() {
    if (loadingStatus === apiLoadingStatus.loading) {
      return;
    }
    //validation here
    const validationResult = validateForm();

    if (!validationResult) {
      return;
    }
    // setLoadingStatus(apiLoadingStatus.loading);
    let actionStatus = undefined;
    let data = undefined;
    const newUserinfo = _.cloneDeep(newUser);

    let newLocationIdsCollection = [];
    if (!userRef.current) {
      newLocationIdsCollection = newUser.hgSiteIdsCollection.map((id) => ({
        userId: userId,
        isActive: true, // newUserinfo.isActiveByOrganization,
        siteId: id,
      }));
    } else {
      const activeLocationIds = [];
      const inactiveLocationIds = [];
      const newCollection = newUser?.hgSiteIdsCollection || [];
      const refCollection = userRef.current?.hgSiteIdsCollection || [];
      for (let i in newCollection) {
        if (!refCollection.includes(newCollection[i])) {
          activeLocationIds.push(newCollection[i]);
        }
      }
      for (let i in refCollection) {
        if (!newCollection.includes(refCollection[i])) {
          inactiveLocationIds.push(refCollection[i]);
        }
      }
      newLocationIdsCollection = activeLocationIds
        .map((i) => ({
          userId: userId,
          isActive: true,
          siteId: i,
        }))
        .concat(
          inactiveLocationIds.map((k) => ({
            userId: userId,
            isActive: false,
            siteId: k,
          }))
        );
      console.log(
        activeLocationIds,
        inactiveLocationIds,
        newLocationIdsCollection
      );
    }
    handleEditUser(newUserinfo)
      .then((res) => {
        actionStatus = _.get(res, "headers.action-status", null);
        data = _.get(res, "data");
      })
      .catch((err) => {
        actionStatus = _.get(err, "response.headers.action-status", null);
      })
      .finally(() => {});
    handleEditLocation({
      updateCollection: newLocationIdsCollection,
      userType: user.data.userType,
    })
      .then((res) => {
        actionStatus = _.get(res, "headers.action-status", null);
        data = _.get(res, "data");
      })
      .catch((err) => {
        actionStatus = _.get(err, "response.headers.action-status", null);
      })
      .finally(() => {
        setLoadingStatus(apiLoadingStatus.loaded);
        switch (actionStatus) {
          default:
            break;
          case "ValidationError":
            showErrorMessage("Validation Error");
            break;
          case "NotExists":
            showErrorMessage("Account Not Exists");
            break;
          case "DuplicateEmail":
            showErrorMessage("Email has been used already!");
            break;
          case "InvitationThrottled":
            showErrorMessage("This invitation Already has been sent!");
            break;
          case "InvalidInviter ":
            showErrorMessage("InvalidInviter ");
            break;
          case "LockedOut":
            showErrorMessage("This account is locked out.");
            break;
          case "AdminLockedOut":
            showErrorMessage("Your account was just locked out.");
            break;
          case "Success":
            setStep(2);
            break;
        }
      });
  }

  const getUserById = UseGetPaginatedUserList();

  // console.log(newUser.hgSiteIdsCollection);
  useEffect(() => {
    if (!userId || userId === "") {
      return;
    }
    setLoadingStatus(apiLoadingStatus.loading);
    let actionStatus = "";
    getUserById({ records: 1, userId: userId })
      .then((res) => {
        // console.log(res[0].userList[0]);
        setNewUser(res[0].userList[0]);
        userRef.current = res[0].userList[0];
        actionStatus = _.get(res, "headers.action-status", null);
      })
      .catch((err) => {
        actionStatus = _.get(err, "response.headers.action-status", null);
      })
      .finally(() => {
        setLoadingStatus(apiLoadingStatus.loaded);
        switch (actionStatus) {
          default:
            break;
          case "ValidationError":
            showErrorMessage("Validation Error");
            break;
          case "NotExists":
            showErrorMessage("Account Not Exists");
            break;
          case "DuplicateEmail":
            showErrorMessage("Email has been used already!");
            break;
          case "InvitationThrottled":
            showErrorMessage("This invitation Already has been sent!");
            break;
          case "InvalidInviter ":
            showErrorMessage("InvalidInviter ");
            break;
          case "LockedOut":
            showErrorMessage("This account is locked out.");
            break;
          case "AdminLockedOut":
            showErrorMessage("Your account was just locked out.");
            break;
          case "Success":
            break;
        }
      });
  }, []);

  return (
    <>
      <Header user={user.data} userLogoUrl={logo.data?.url} />
      <div className={styles["main"]}>
        <LoadingSpinner
          isLoading={loadingStatus === apiLoadingStatus.loading}
        />

        {step === 1 && (
          <Container className={styles["container"]}>
            <Row className="text-center">
              <Col>
                <UserCircleIcon size={40} color="#007775" />
              </Col>
            </Row>
            <Row className="text-center">
              <Col>
                <h5 className={styles["title"]}>Edit User Information</h5>
              </Col>
            </Row>
            {/* <Row>
            <Col xs={2}>
              <p className={styles["label"]}> Activity</p>
            </Col>
            <Col xs={2}>
              <ToggleSwitch />
            </Col>
          </Row> */}
            <Row>
              <Col>
                <FormCustomControl
                  continueLabel="Submit"
                  onContinue={editUserInfo}
                  onBack={() => {
                    navigate(-1);
                  }}
                  continueDisabled={loadingStatus === apiLoadingStatus.loading}
                >
                  <Form.Group className={styles["form-group"]}>
                    <Form.Label className={styles["label"]} htmlFor="firstName">
                      First Name
                    </Form.Label>
                    <Form.Control
                      id="firstName"
                      className={
                        newUserValidation.firstName
                          ? styles["input-text-box"]
                          : styles["input-text-box-warning"]
                      }
                      type="text"
                      placeholder="Type user's name here!"
                      value={newUser.firstName}
                      name="firstName"
                      onChange={userInput}
                    />
                    {!newUserValidation.firstName && (
                      <Form.Text className={styles["warning-error"]}>
                        <MultiplicationSignCircleIcon /> Please Add User's Name!
                      </Form.Text>
                    )}
                  </Form.Group>
                  <Form.Group className={styles["form-group"]}>
                    <Form.Label className={styles["label"]} htmlFor="lastName">
                      Last Name
                    </Form.Label>
                    <Form.Control
                      id="lastName"
                      className={
                        newUserValidation.lastName
                          ? styles["input-text-box"]
                          : styles["input-text-box-warning"]
                      }
                      type="text"
                      placeholder="Type user's last name here!"
                      value={newUser.lastName}
                      name="lastName"
                      onChange={userInput}
                    />
                    {!newUserValidation.lastName && (
                      <Form.Text className={styles["warning-error"]}>
                        <MultiplicationSignCircleIcon /> Please Add User's Last
                        Name!
                      </Form.Text>
                    )}
                  </Form.Group>

                  {/* <Form.Group className={styles["form-group"]}>
                    <Form.Label className={styles["label"]} htmlFor="email">
                      Email
                    </Form.Label>
                    <Form.Control
                      className={
                        newUserValidation.lastName
                          ? styles["input-text-box"]
                          : styles["input-text-box-warning"]
                      }
                      id="email"
                      type="email"
                      placeholder="Type user's email here!"
                      value={newUser.email}
                      name="email"
                      onChange={userInput}
                    />
                    {!newUserValidation.email && (
                      <Form.Text className={styles["warning-error"]}>
                        <MultiplicationSignCircleIcon /> Please Check User's
                        Email Address!
                      </Form.Text>
                    )}
                  </Form.Group> */}
                  <div className={styles["form-group"]}>
                    <div className={styles["label"]}>Email Address</div>
                    <div className={styles["email"]}>{newUser.email}</div>
                  </div>

                  {user?.data?.userType === "HealthcareGroup" && (
                    <Form.Group className={styles["form-group"]}>
                      <Form.Label className={styles["label"]} htmlFor="email">
                        Helthcare Group Locations
                      </Form.Label>

                      <HgSitesSelectControl
                        // className={styles["input-text-box"]}
                        multi
                        value={newUser.hgSiteIdsCollection}
                        onChange={(value) => {
                          // console.log(value);
                          const newCollection = value.map((i) => i.id);
                          setNewUser({
                            ...newUser,
                            hgSiteIdsCollection: newCollection,
                          });
                        }}
                      />
                    </Form.Group>
                  )}
                  {user?.data?.userType === "Lender" && (
                    <Form.Group className={styles["form-group"]}>
                      <Form.Label className={styles["label"]} htmlFor="email">
                        HCG's
                      </Form.Label>

                      <LenderHGsSelectControl
                        // className={styles["input-text-box"]}
                        multi={true}
                        onChange={(value) => {
                          setHcg(value.id);
                        }}
                      />
                    </Form.Group>
                  )}
                  {user?.data?.userType === "Lender" &&
                    (!!hcg || !!newUser.hgSiteIdsCollection) && (
                      <Form.Group className={styles["form-group"]}>
                        <Form.Label className={styles["label"]} htmlFor="email">
                          Helthcare Group Locations
                        </Form.Label>

                        <LenderHGSiteSelectControl
                          hgIdCollection={hcg}
                          multi={true}
                          value={newUser.hgSiteIdsCollection}
                          onChange={(value) => {
                            // console.log(value);
                            const newCollection = value.map((i) => i.id);
                            setNewUser({
                              ...newUser,
                              hgSiteIdsCollection: newCollection,
                            });
                          }}
                        />
                      </Form.Group>
                    )}
                </FormCustomControl>
              </Col>
            </Row>
          </Container>
        )}

        {step === 2 && (
          <div className={styles["last-container"]}>
            <div className={styles["icon-container"]}>
              <Tick02Icon color="#007775" className={styles["icon"]} />{" "}
            </div>
            <h5 className={styles["title"]}>
              User Informations Has Been Edited Successfully!
            </h5>
            {/* <InvitedUserItem
              user={{
                firstName: newUser.firstName,
                lastName: newUser.lastName,
                email: newUser.email,
                locations: newUser.hgSiteIdsCollection,
              }}
              styles={"width: 600px"}
            /> */}

            <FormCustomControl
              continueLabel="OK"
              onContinue={() => {
                navigate("/user-management");
              }}
              displayBackContinue={false}
            ></FormCustomControl>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default EditUser;
