import React, { useState } from "react";
import styles from "./styles.module.scss";
import { FilterHorizontalIcon } from "hugeicons-react";
import SelectControl from "../select-control/select-control";
import { Col, Container, Row } from "react-bootstrap";
import ReactSlider from "react-slider";
import _ from "lodash";
import billingsStatus from "../../infrastructure/constants/enums/billings-status";
import { commaSeperatedCurrency } from "../../infrastructure/helpers";

const BillingsFilter = ({ searchFilterData, setSearchFilterData }) => {
  const [showRange, setShowRange] = useState(false);

  const filterSearchDataInput = (e) => {
    const name = e.target.name;
    setSearchFilterData({
      ...searchFilterData,
      [name]: e.target.value.toUpperCase(),
    });
  };

  return (
    <>
      <div className={styles["container"]}>
        <div className={styles["filter-container"]}>
          <FilterHorizontalIcon
            color="#767676"
            className={styles["filter-icon"]}
          />
          <Container>
            <Row>
              <Col sm={6} md={6} lg={3} className="relative">
                {/* TODO: Create a custom control for this */}
                <button
                  className={styles["price-button"]}
                  onClick={() => setShowRange(!showRange)}
                >
                  {searchFilterData.minAmount > 0
                    ? commaSeperatedCurrency(searchFilterData.minAmount, 0)
                    : "No min"}{" "}
                  –{" "}
                  {searchFilterData.maxAmount < 20000
                    ? commaSeperatedCurrency(searchFilterData.maxAmount, 0)
                    : "No max"}
                </button>
                <div
                  className={styles[showRange ? "price-selector" : "notShow"]}
                >
                  <ReactSlider
                    value={[
                      !searchFilterData.minAmount ||
                      searchFilterData.minAmount < 0
                        ? 0
                        : searchFilterData.minAmount,
                      !searchFilterData.maxAmount ||
                      searchFilterData.maxAmount >= 20000
                        ? 20000
                        : searchFilterData.maxAmount,
                    ]}
                    onAfterChange={(value, index) => {
                      setSearchFilterData({
                        ...searchFilterData,
                        minAmount: value[0] <= 0 ? undefined : value[0],
                        maxAmount:
                          !value[1] || value[1] >= 20000 ? undefined : value[1],
                      });
                      setShowRange(false);
                    }}
                    min={0}
                    max={20000}
                    className="horizontal-slider"
                    thumbClassName="example-thumb"
                    trackClassName="example-track"
                    renderThumb={(props, state) => {
                      const { key, ...restProps } = props;
                      return (
                        <div key={key} {...restProps}>
                          {state.valueNow >= 20000 ? (
                            "Max"
                          ) : (
                            <>{state.valueNow > 0 ? state.valueNow : "Min"}</>
                          )}
                        </div>
                      );
                    }}
                    pearling={true}
                    minDistance={50}
                    step={100}
                  />
                </div>
              </Col>
              <Col sm={6} md={4}>
                <SelectControl
                  placeholder="Status"
                  options={billingsStatus.collection}
                  value={
                    !searchFilterData.status ||
                    searchFilterData.status.length === 0
                      ? []
                      : billingsStatus.collection.filter(
                          (i) => searchFilterData.status.indexOf(i.value) > -1
                        )
                  }
                  onChange={(v) => {
                    const newValue = v.map((i) => i.value);
                    setSearchFilterData({
                      ...searchFilterData,
                      status: newValue,
                    });
                  }}
                  isMulti={true}
                />
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};

export default BillingsFilter;
