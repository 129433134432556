import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.scss";
import ProcessIndicator from "../../../components/process-indicator";

const ProcessBar = ({ process = [{}] }) => {
  return (
    <div className={styles["process-container"]}>
      {process.map((item, indx) => {
        {
          return (
            <ProcessIndicator
              key={indx}
              text={item.title}
              status={item.status}
              first={indx === 0}
              textWidth={item.textWidth}
            />
          );
        }
      })}
    </div>
  );
};

ProcessBar.propTypes = {};

export default ProcessBar;
