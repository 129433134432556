import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.scss";

const LoadingSpinner = ({ isLoading = false }) => {
  if (!isLoading) {
    return <></>;
  }
  return (
    <div className={styles["loading-overlay"]}>
      <div className={styles["loading-content"]}>
        <div className={styles["lds-roller"]}>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  );
};

LoadingSpinner.propTypes = {
  isLoading: PropTypes.bool.isRequired,
};

export default LoadingSpinner;
