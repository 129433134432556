import { Container } from "react-bootstrap";
import { useCommonContext } from "../../common-context";
import Footer from "../../components/footer";
import Header from "../../components/header";
import styles from "./styles.module.scss";
import {
  LockPasswordIcon,
  Logout03Icon,
  Mail01Icon,
  UserAccountIcon,
  UserIcon,
} from "hugeicons-react";
import { useState } from "react";
import { apiHelperMethods } from "../../infrastructure/helpers";
import { apiUrls } from "../../infrastructure/constants/api";
import { useNavigate } from "react-router-dom";
import ForgotPasswordControl from "../../components/forgot-password/forgot-password";
import FormCustomControl from "../../components/form-custom-control";
import ChangePassword from "../recovery/change/change";
import ChangePasswordControl from "../../components/change-password";

const ProfilePage = () => {
  const [{ user, logo }] = useCommonContext();
  const [step, setStep] = useState("profile");

  const navigate = useNavigate();

  function logOut() {
    apiHelperMethods.get(apiUrls.userIdentityUrls.logout);
    navigate("/login");
  }
  return (
    <div>
      <Header user={user.data} userLogoUrl={logo.data?.url} />
      <div className={styles["main"]}>
        <Container className={styles["container"]}>
          <div className={styles["logo"]}>
            <img src={logo.data?.url} alt="company logo" />
            <div className={styles["organization"]}>{user.data?.hg?.name}</div>
          </div>
          <div className={styles["name-section"]}>
            <div className={styles["user-icon"]}>
              <UserIcon size={40} />
            </div>
            <div>
              <div
                className={styles["user-name"]}
              >{`${user.data?.firstName} ${user.data?.lastName}`}</div>
              <div className={styles["user-type"]}>
                <UserAccountIcon className={styles["role-icon"]} />
                {user.data.roleInOrganization}
              </div>
            </div>
          </div>
          {step === "profile" && (
            <div className={styles["last-section"]}>
              <div>
                <Mail01Icon
                  className={`${styles["email-icon"]} ${styles["icon"]}`}
                />
                {user.data?.email}
              </div>
              <div>
                <button
                  className={styles["button"]}
                  onClick={() => setStep("change-password")}
                >
                  <LockPasswordIcon className={styles["icon"]} /> Change
                  Password
                </button>
              </div>
              <div>
                <button className={styles["button"]} onClick={logOut}>
                  <Logout03Icon className={styles["icon"]} /> Log Out
                </button>
              </div>
            </div>
          )}
          {step === "change-password" && (
            <div className={styles["change-password"]}>
              <ChangePasswordControl setStep={setStep} userId={user.data?.id} />
            </div>
          )}
          {step === "Success" && (
            <div className={styles["success"]}>
              <h4>Your Password Has Been Successfuly Changed</h4>
              <FormCustomControl
                onContinue={() => setStep("profile")}
                displayBackContinue={false}
              />
            </div>
          )}
        </Container>
      </div>
      <Footer />
    </div>
  );
};

export default ProfilePage;
