import _ from "lodash";
import { apiUrls } from "../infrastructure/constants/api";
import apiHelperMethods from "../infrastructure/helpers/api-helpers";

function convertUsersDates(users) {
  return users.map((tempUser) => ({
    ...tempUser,
    createDate: !!tempUser.createDate
      ? new Date(tempUser.createDate)
      : undefined,
    submitDate: !!tempUser.submitDate
      ? new Date(tempUser.submitDate)
      : undefined,
    lastUpdateDate: !!tempUser.lastUpdateDate
      ? new Date(tempUser.lastUpdateDate)
      : undefined,
    lastUnsuccessfulAccessAttempt: !!tempUser.lastUnsuccessfulAccessAttempt
      ? new Date(tempUser.lastUnsuccessfulAccessAttempt)
      : undefined,
  }));
}

const UseGetPaginatedInvitationsList = () => {
  const loadData = async ({ email, expired, start, records }) => {
    const data = {
      email: email,
      expired: expired,

      start: start,
      records: records,
    };
    return apiHelperMethods
      .getWithReturn(apiUrls.userIdentityUrls.paginatedInvitationsList, data)
      .then((res) => {
        let tempUsers = res.data?.data?.invitationCollection;
        if (!tempUsers) {
          return Promise.reject("No user found!");
        }
        tempUsers = convertUsersDates(tempUsers);
        const actionStatus = _.get(res, "headers.action-status", null);
        // console.log(res.data.data?.totalCount);
        return Promise.resolve([
          {
            totalCount: res.data.data?.totalCount || 0,
            invitedUserCollection: tempUsers || [],
          },
          actionStatus,
        ]);
      });
  };
  return loadData;
};

export default UseGetPaginatedInvitationsList;
