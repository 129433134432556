import _ from "lodash";
import { apiUrls } from "../infrastructure/constants/api";
import apiHelperMethods from "../infrastructure/helpers/api-helpers";

function convertUsersDates(users) {
  return users.map((tempUser) => ({
    ...tempUser,
    createDate: !!tempUser.createDate
      ? new Date(tempUser.createDate)
      : undefined,
    submitDate: !!tempUser.submitDate
      ? new Date(tempUser.submitDate)
      : undefined,
    lastUpdateDate: !!tempUser.lastUpdateDate
      ? new Date(tempUser.lastUpdateDate)
      : undefined,
    lastUnsuccessfulAccessAttempt: !!tempUser.lastUnsuccessfulAccessAttempt
      ? new Date(tempUser.lastUnsuccessfulAccessAttempt)
      : undefined,
  }));
}

const UseGetPaginatedUserList = () => {
  const loadData = async ({
    sortDirection,
    sortField,
    query,
    isActive,
    isDeleted,
    start,
    records,
    userId = null,
  }) => {
    const data = {
      sortDirection: sortDirection,
      sortField: sortField,
      query: query,
      isActive: isActive,
      isDeleted: isDeleted,
      start: start,
      records: records,
      userId: userId,
    };
    return apiHelperMethods
      .getWithReturn(apiUrls.userIdentityUrls.paginatedUserList, data)
      .then((res) => {
        let tempUsers = res.data?.data?.userList;
        if (!tempUsers) {
          return Promise.reject("No user found!");
        }
        tempUsers = convertUsersDates(tempUsers);
        const actionStatus = _.get(res, "headers.action-status", null);
        // console.log(res.data.data?.totalCount);

        return Promise.resolve([
          {
            totalCount: res.data.data?.totalCount || 0,
            userList: tempUsers || [],
          },
          actionStatus,
        ]);
      });
  };
  return loadData;
};

export default UseGetPaginatedUserList;
