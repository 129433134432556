const billingsStatus = {
  void: "Void",
  paid: "Paid",
  issued: "Issued",
  getValueByText: (v) => {
    if (!v || typeof v !== "string" || v.length === 0) {
      return null;
    }
    switch (v.toLowerCase()) {
      default:
        return null;
      case "Void".toLocaleLowerCase():
        return billingsStatus.void;
      case "Paid".toLocaleLowerCase():
        return billingsStatus.paid;
      case "Submitted".toLocaleLowerCase():
        return billingsStatus.issued;
    }
  },
  collection: [
    // { label: "None", value: undefined },
    { label: "Void", value: "Void" },
    { label: "Paid", value: "Paid" },
    { label: "Issued", value: "Issued" },
  ],
};

export default billingsStatus;
