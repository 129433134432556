import React from "react";
import styles from "./styles.module.scss";
import Header from "../../components/header";
import Footer from "../../components/footer";
import CreatUserControl from "../../components/create-user-control";
import { useCommonContext } from "../../common-context/common-context-provider";

const CreateUser = () => {
  const [{ user, logo }] = useCommonContext();
  return (
    <>
      <Header user={user.data} userLogoUrl={logo.data?.url} />
      <CreatUserControl />
      <Footer />
    </>
  );
};

export default CreateUser;
