export const data = {
  id: 2,
  nickname: "WCD-ART",
  hgGroupCode: "ART",
  createDate: "2023-09-03T13:24:48.0733333+00:00",
  lastUpdateDate: "2023-11-02T02:32:07.1514927+00:00",
  hG_Id: 20000,
  isActive: true,
  corporateName: "West Coast Dental",
  storeName: "",
  officeName: "Sunshine Smiles Dental",
  taxId: "353",
  typeOfOwnership: "Partnership",
  numberOfEmployees: 654,
  businessStartedDate: "2023-10-26T00:00:00-07:00",
  businessType: "Dental Practice",
  practiceManagementSoftware: "office",
  phoneNumber: "3456789012",
  address1: "3 Masonic Ave",
  city: "San Francisco",
  state: "CA",
  zipCode: "94118",
  mailingAddress1: "",
  mailingAddress2: "",
  mailingCity: "Los Angeles",
  mailingState: "CA",
  mailingZipCode: "2",
  ownerFirstName: "Chloe",
  ownerLastName: "Johnson",
  ownerLicenseNumber: "2",
  ownerAddress1: "2 Maple Ave",
  ownerCity: "Long Beach",
  ownerState: "CA",
  ownerZipCode: "2",
  ownerPhoneNumber: "5698475263",
  ownerEmail: "Chloe@gmail",
  managerFirstName: "Jane",
  managerLastName: "Anderson",
  managerLicenseNumber: "3",
  managerLicenseState: "CA",
  contactFirstName: "Sebastian",
  contactLastName: "Johnson",
  contactPhoneNumber: "647666888",
  contactEmail: "contact@gmail.com",
  bankName: "TD",
  bankRoutingNumber: "563",
  bankAccountNumber: "4563",
  bankLetterImage: "1",
  bankLetterProvided: false,
  longitude: 37.78,
  latitude: -122.45,
  rowId: "10b1d612-cbc9-4834-a1d3-c3a9ad583c46",
};
