import { apiUrls } from "../infrastructure/constants/api";
import apiHelperMethods from "../infrastructure/helpers/api-helpers";

const useMfa = () => {
  const postData = ({ token, mfaType, trustComputer }) => {
    const data = {
      token: token,
      mfaType: mfaType,
      trustComputer: trustComputer,
    };

    const [results] = apiHelperMethods.postWithReturn(
      apiUrls.userIdentityUrls.mfa,
      data
    );
    return results;
  };
  return postData;
};

export default useMfa;
