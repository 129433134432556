import React, { useEffect } from "react";
import LoginFormControl from "../../components/login-form";
import { Col, Container, Row } from "react-bootstrap";
import logo from "../../images/logo.png";
import styles from "./styles.module.scss";
import { useLogin } from "../../hooks";
import { commonContextActions, useCommonContext } from "../../common-context";
import { apiLoadingStatus } from "../../infrastructure/constants/api";
import { useLocation, useNavigate } from "react-router-dom";

const Login = (props) => {
  const [{ user }, commonDispatch] = useCommonContext();
  const handleLogin = useLogin();
  const navigate = useNavigate();
  const location = useLocation();

  const onSuccessfulLogin = ({ newUser }) => {
    // set the context
    if (!!newUser) {
      commonDispatch({
        type: commonContextActions.updateUser,
        payload: {
          ...user,
          loadingStatus: apiLoadingStatus.loaded,
          data: newUser,
        },
      });
      navigate("/");
    }
  };

  useEffect(() => {
    document.title = "Login | DentiRate";
  }, []);

  return (
    <div className={styles["wrapper"]}>
      <div className={`${styles["container"]} ${styles["welcome-container"]}`}>
        {/* <h4> Welcome to Dentirate!</h4> */}
      </div>
      <Container className={styles["container"]}>
        <Row className={styles["logo-container"]}>
          <Col>
            <img src={logo} alt="logo" />
          </Col>
        </Row>
        <Row className={styles["welcome-container"]}>
          <Col>
            <h4>Welcome to Dentirate!</h4>
          </Col>
        </Row>
        <Row>
          <LoginFormControl
            handleLogin={handleLogin}
            onSuccessfulLogin={onSuccessfulLogin}
          />
        </Row>
      </Container>
    </div>
  );
};

Login.propTypes = {};

export default Login;
