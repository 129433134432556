import React from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import styles from "./styles.module.scss";

const ModalContainer = ({
  children = <></>,
  handleClose = () => {},
  show,
  modalTitle = "",
  modalSize,
  dialogClassName = "",
  fullScreen = false,
  lockModal = false,
  centered = false,
  closeOnEscape = false,
  headerComponent = <></>,
  backdropZIndex = -1,
  hideModalTitle = false,
}) => {
  const handleModalShow = () => {
    if (backdropZIndex < 0) {
      return;
    }
    // Find the modal-backdrop element added by Bootstrap
    const backdrops = document.getElementsByClassName("modal-backdrop");
    if (backdrops.length) {
      // Apply the custom z-index to the last added backdrop (relevant in case of multiple modals)
      backdrops[backdrops.length - 1].style.zIndex = backdropZIndex;
    }
  };

  return (
    <>
      <Modal
        onShow={handleModalShow}
        show={show}
        onHide={handleClose}
        size={modalSize}
        dialogClassName={dialogClassName}
        fullscreen={fullScreen}
        onEscapeKeyDown={(e) => {
          if (lockModal) {
            e.preventDefault();
          }
          if (closeOnEscape) {
            handleClose();
          }
        }}
        centered={centered}
        enforceFocus={false}
        backdrop={lockModal ? "static" : true}
      >
        {!hideModalTitle && (
          <Modal.Header closeButton={!lockModal}>
            <Modal.Title>{modalTitle}</Modal.Title>
            {headerComponent}
          </Modal.Header>
        )}
        <Modal.Body>{children}</Modal.Body>
      </Modal>
    </>
  );
};

ModalContainer.propTypes = {
  handleClose: PropTypes.func,
  fullScreen: PropTypes.bool,
  closeOnEscape: PropTypes.bool,
  lockModal: PropTypes.bool,
  hideModalTitle: PropTypes.bool,
  centered: PropTypes.bool,
  children: PropTypes.any,
  dialogClassName: PropTypes.string,
  headerComponent: PropTypes.node,
  backdropZIndex: PropTypes.number,
  modalSize: PropTypes.oneOf(["sm", "xl", "lg"]),
};

export default ModalContainer;
