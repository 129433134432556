import styles from "./styles.module.scss";

const PartnerCard = ({ logoUrl = "", companyName = "", website = "" }) => {
  return (
    <a href={website} target="_blank" className={styles["container"]}>
      <div className={styles["logo"]}>
        <img src={logoUrl} alt="company logo" />
      </div>
      <div className={styles["name"]}>{companyName}</div>
    </a>
  );
};

export default PartnerCard;
