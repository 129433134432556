import { apiUrls } from "../infrastructure/constants/api";
import apiHelperMethods from "../infrastructure/helpers/api-helpers";

const useLogout = () => {
  const postData = () => {
    const [results] = apiHelperMethods.postWithReturn(
      apiUrls.userIdentityUrls.logout
    );
    return results;
  };

  return postData;
};

export default useLogout;
