import _ from "lodash";
import apiHelperMethods from "../infrastructure/helpers/api-helpers";
import { apiUrls } from "../infrastructure/constants/api";

const useGetKpiOverView = () => {
  const loadData = async ({ userType, viewType }) => {
    let url;
    switch (userType) {
      case "HealthcareGroup":
        url = apiUrls.hgUrls.getKpiOverVew;
        break;
      case "Lender":
        url = apiUrls.lenderUrls.getKpiOverVew;
        break;
      default:
        url = apiUrls.hgUrls.getKpiOverVew;
        break;
    }
    const data = {
      viewType,
    };
    // make sure you
    return apiHelperMethods.getWithReturn(url, data).then((res) => {
      let tempUsers = res.data;

      const actionStatus = _.get(res, "headers.action-status", null);
      return Promise.resolve([tempUsers, actionStatus]);
    });
  };
  return loadData;
};

export default useGetKpiOverView;
