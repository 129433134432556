import React from "react";
import PropTypes from "prop-types";
import styles from "./pagination.module.scss";
import SelectControl from "../select-control/select-control";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const analyzePaginationInput = (start, records, totalCount, spanWidth) => {
  if (records === 0 || totalCount === 0) {
    return [];
  }
  let innerSpanWidth = spanWidth && spanWidth > 2 ? spanWidth : 10;
  const minPage = Math.max(
    Math.ceil(start / records) + 1 - Math.floor(innerSpanWidth / 2),
    1
  );
  const maxPage = Math.min(
    Math.ceil(start / records) + Math.ceil(innerSpanWidth / 2),
    Math.ceil(totalCount / records)
  );
  const range = _.range(minPage, maxPage + 1);
  const output = [];

  for (let i = 0; i < range.length; i += 1) {
    const item = {
      value: (range[i] - 1) * records,
      page: range[i],
      label: range[i],
    };
    item.active = Math.floor(start / records) + 1 === item.page;
    output.push(item);
  }
  if (range[0] !== 1) {
    output.unshift({
      value: 0,
      page: 1,
      label: "<<",
    });
  }
  if (range[range.length - 1] < Math.ceil(totalCount / records)) {
    const lastPage = Math.ceil(totalCount / records);
    output.push({
      value: (lastPage - 1) * records,
      page: lastPage,
      label: ">>",
    });
  }
  return [output];
};

const Pagination = (props) => {
  const {
    start,
    records,
    totalCount,
    onClick = () => {},
    className = "",
    displayViewOptions = true,
    handleViewOptions = () => {},
    spanWidth = 10,
  } = props;
  const [items] = analyzePaginationInput(start, records, totalCount, spanWidth);
  if (!items || items.length < 1) {
    return <></>;
  }
  const viewingOptions = [
    {
      label: "10",
      value: 10,
    },
    {
      label: "25",
      value: 25,
    },
    {
      label: "50",
      value: 50,
    },
  ];
  return (
    <div className={[styles.container, className].join(" ")}>
      <ul className={styles.items}>
        {items.map((i) => (
          <li className={styles.item} key={i.value}>
            <a
              className={[
                "btn btn-primary",
                styles.link,
                i.active ? `btn-dark ${styles.disabled}` : "",
              ].join(" ")}
              onClick={() => {
                if (i.active) {
                  return;
                }
                onClick(i.value, records);
              }}
            >
              {i.label}
            </a>
          </li>
        ))}
      </ul>
      {displayViewOptions && (
        <div className={styles["viewing-options-container"]}>
          <SelectControl
            clearable={false}
            options={viewingOptions}
            value={records}
            onChange={(i) => {
              handleViewOptions(i ? i.value : viewingOptions[0].value);
            }}
            isSearchable={false}
          />
        </div>
      )}
    </div>
  );
};

Pagination.propTypes = {
  className: PropTypes.string,
  start: PropTypes.number.isRequired, // starting index
  records: PropTypes.number.isRequired, // how many records you want to display
  totalCount: PropTypes.number.isRequired, // how many rows do you have in total
  onClick: PropTypes.func, // on click handle
  displayViewOptions: PropTypes.bool,
  handleViewOptions: PropTypes.func,
  spanWidth: PropTypes.number,
};

export default Pagination;
