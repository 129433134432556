import React, { useEffect, useState } from "react";

import UserItem from "../../components/user-item";
import UseGetPaginatedUserList from "../../hooks/use-get-paginated-user-list";
import _ from "lodash";
import { apiLoadingStatus } from "../../infrastructure/constants/api";
import { toast } from "react-toastify";
import { useCommonContext } from "../../common-context";
import useHgLocations from "../../hooks/use-hg-locations1";
import styles from "./styles.module.scss";
import Pagination from "../../components/pagination-control/pagination";

const UsersTab = ({
  users,
  noUser,
  start,
  records,
  totalCount,
  handlePagination = () => {},
}) => {
  const {
    getLocationByIds,
    addLocations,
    deleteLocations,
    setUserType,
    locationCollection,
  } = useHgLocations();
  // window.getLocationByIds = getLocationByIds; // hgSiteId; not mistaken with HgId or LenderId or UserId
  // window.addLocations = addLocations;
  // window.deleteLocations = deleteLocations;
  // window.locationCollection = locationCollection;
  // console.log(totalCount);
  const [{ user }] = useCommonContext();
  return (
    <>
      {users?.length !== 0
        ? users.map((u, index) => {
            // console.log(user);
            return (
              <UserItem
                key={index}
                user={{
                  firstName: u.firstName,
                  lastName: u.lastName,
                  type: u.roleInOrganization,
                  email: u.email,
                  date: `${u.createDate?.getDate()}/${
                    u.createDate?.getMonth() + 1
                  }/${u.createDate?.getFullYear()}`,
                  isActive: u.isActiveByOrganization,
                  locations: u.hgSiteIdsCollection,

                  userId: u.id,
                  updatedById: 14,
                  overrideAccess: true,
                  organizationId: u.organizationId,
                }}
                currentUserId={user?.data?.id}
              />
            );
          })
        : noUser && <div className={styles["no-user"]}>No user exists!</div>}

      <Pagination
        start={start}
        records={records}
        spanWidth={5}
        totalCount={totalCount}
        displayViewOptions={false}
        onClick={(value) => handlePagination(value)}

        // handleViewOptions={(value) =>
        //   setSearchFilterData({
        //     ...searchFilterData,
        //     Records: value,
        //   })
        // }
        // className={styles["pagination"]}
      />
    </>
  );
};

export default UsersTab;
