import React, { Children, useRef } from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.scss";

const ScrollableMenu = ({ children = <></> }) => {
  const menuRef = useRef(null);

  const handleMouseDown = (e) => {
    menuRef.current.isDown = true;
    menuRef.current.startX = e.pageX - menuRef.current.offsetLeft;
    menuRef.current.scrollLeft = menuRef.current.scrollLeft;
  };

  const handleMouseLeave = () => {
    menuRef.current.isDown = false;
  };

  const handleMouseUp = () => {
    menuRef.current.isDown = false;
  };

  const handleMouseMove = (e) => {
    if (!menuRef.current.isDown) return;
    e.preventDefault();
    const x = e.pageX - menuRef.current.offsetLeft;
    const walk = (x - menuRef.current.startX) * 3; // Adjust scroll speed
    menuRef.current.scrollLeft = menuRef.current.scrollLeft - walk;
  };

  return (
    <div className={styles["menu-container"]}>
      <div
        className={styles["menu"]}
        ref={menuRef}
        onMouseDown={handleMouseDown}
        onMouseLeave={handleMouseLeave}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
      >
        {children}
      </div>
    </div>
  );
};

ScrollableMenu.propTypes = {};

export default ScrollableMenu;
