import { useEffect, useState } from "react";
import LoadingSpinner from "../../components/loading-spinner/loading-spinner";
import { apiLoadingStatus, apiUrls } from "../../infrastructure/constants/api";
import styles from "./styles2.module.scss";
import logo from "../../images/sample-logo.png";
import { Row, Col, Container, Form, FormControl } from "react-bootstrap";
import {
  Coins01Icon,
  Money01Icon,
  Payment01Icon,
  PercentIcon,
  TreatmentIcon,
} from "hugeicons-react";
import {
  apiHelperMethods,
  commaSeperatedCurrency,
  commaSeperatedDecimal,
} from "../../infrastructure/helpers";
import moment from "moment";
import ValidationWrapper from "../../components/validation-wrapper";
import CheckBox from "../../components/check-box/check-box";
import FormCustomControl from "../../components/form-custom-control/form-custom-control";
import _, { set } from "lodash";
import axios from "axios";
import { NumericFormat, PatternFormat } from "react-number-format";
import SelectControl from "../../components/select-control";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const mounths = [];
const yearNow = new Date().getFullYear();
const years = [];
for (let i = 0; i < 12; i++) {
  years.push({ label: `${yearNow - 2000 + i}`, value: yearNow + i });
  mounths.push({ label: i < 9 ? `0${i + 1}` : `${i + 1}`, value: i + 1 });
}
const PaymentForm = ({
  application = {},
  lenderIndex = {},
  initialInfo = {},
  handleClose = () => {},
}) => {
  // console.log(lenderIndex, application);
  const [loadingStatus, setLoadingStatus] = useState(apiLoadingStatus.unloaded);
  const [step, setStep] = useState(1);

  const [applicantInfo, setApplicantInfo] = useState({
    timeZoneId: Intl.DateTimeFormat().resolvedOptions().timeZone,
    id: initialInfo.id,
    acceptedTermsAndConditions: false,
    agreementAcceptedTimestamp: "",
    agreementPresentedTimestamp: "",
    cardExpirationMonth: 0,
    cardExpirationYear: 0,
    cardExpirationDate: "",
    cardHolderName: "",
    cardLast4Digits: "",
    legalNoticesAcknowledged: false,
    signatureTimestamp: "",
    userConsentedToElectronicRecords: true,
    userConsentedToElectronicSignature: true,
    productId: lenderIndex.id,
    accessKey: initialInfo.accessKey,
    ssnLast4: "1234",
    yearOfBirth: initialInfo.yearOfBirth,
    zipcode: initialInfo.zipCode,
  });
  const [applicantInfoValidation, setApplicantInfoValidation] = useState({
    cardHolderName: true,
    cardLast4Digits: true,
    cardExpirationDate: true,
    cardExpirationMonth: true,
    cardExpirationYear: true,
  });

  const validateAll = () => {
    const date = new Date();
    const currentYear = date.getFullYear();
    const currentMonth = date.getMonth();
    setApplicantInfoValidation({
      ...applicantInfoValidation,
      cardHolderName:
        !!applicantInfo.cardHolderName &&
        applicantInfo.cardHolderName.length > 0,
      cardExpirationDate:
        applicantInfo.cardExpirationYear.value === currentYear
          ? !!applicantInfo.cardExpirationMonth &&
            applicantInfo.cardExpirationMonth.value > currentMonth
          : !!applicantInfo.cardExpirationMonth &&
            !!applicantInfo.cardExpirationYear,
      cardExpirationMonth:
        applicantInfo.cardExpirationYear.value === currentYear
          ? !!applicantInfo.cardExpirationMonth &&
            applicantInfo.cardExpirationMonth.value > currentMonth
          : !!applicantInfo.cardExpirationMonth,
      cardExpirationYear: !!applicantInfo.cardExpirationYear,
      cardLast4Digits:
        !!applicantInfo.cardLast4Digits && applicantInfo.cardLast4Digits > 999,
    });

    return (
      !!applicantInfo.cardHolderName &&
      (applicantInfo.cardExpirationYear.value === currentYear
        ? !!applicantInfo.cardExpirationMonth &&
          applicantInfo.cardExpirationMonth.value > currentMonth
        : !!applicantInfo.cardExpirationMonth) &&
      !!applicantInfo.cardExpirationYear &&
      !!applicantInfo.cardLast4Digits
    );
  };

  const navigate = useNavigate();

  const showErrorMessage = (message) => {
    toast(message, {
      type: "error",
      toastId: "login error",
    });
  };

  const onSubmit = () => {
    const validation = validateAll();
    if (!validation) {
      return;
    }
    setLoadingStatus(apiLoadingStatus.loading);
    // console.log(applicantInfo);
    const temp = { ...applicantInfo };
    temp.cardExpirationMonth = applicantInfo.cardExpirationMonth.value;
    temp.cardExpirationYear = applicantInfo.cardExpirationYear.value;
    delete temp.cardExpirationDate;
    console.log(temp);
    // console.log(temp);
    let actionStatus = undefined;
    let data = undefined;
    axios
      .post(apiUrls.userIdentityUrls.ihfAgreement, temp)
      .then((res) => {
        actionStatus = _.get(res, "headers.action-status", null);
        data = _.get(res, "data");
      })
      .catch(
        (err) =>
          (actionStatus = _.get(err, "response.headers.action-status", null))
      )
      .finally(() => {
        setLoadingStatus(apiLoadingStatus.loaded);
        switch (actionStatus) {
          case "ValidationError":
            showErrorMessage("Validation Error");
            break;
          case "Failed":
            showErrorMessage("Failed");
            break;
          case "Unauthorized":
            navigate("/login");
            break;
          case "Forbidden":
            showErrorMessage("Forbidden");
            break;
          case "NotFound":
            showErrorMessage("Not Found");
            break;
          case "TransactionLock":
            showErrorMessage("Transaction Lock");
            break;
          case "OldVersionUpdate":
            showErrorMessage("Old Version Update");
            break;
          case "Unprocessable":
            showErrorMessage("Unprocessable");
            break;
          case "TooManyRequests":
            showErrorMessage("Too Many Requests");
            break;
          case "InvalidApplicationAmount ":
            showErrorMessage("Invalid Application Amount ");
            break;
          case "Locked":
            showErrorMessage("This account is locked out.");
            break;
          case "TooOldApplication":
            showErrorMessage("Too Old Application");
            break;
          case "UnsuccessfulAttemptsReached":
            showErrorMessage("Unsuccessful Attempts Reached");
            break;
          case "Success":
            // setStep(1);

            handleClose(false);
            break;
          default:
            showErrorMessage("Unknown Error.");
            break;
        }
      });
  };

  useEffect(() => {
    setApplicantInfo({
      ...applicantInfo,
      agreementPresentedTimestamp: new Date().toISOString(),
    });
  }, []);
  return (
    <div>
      <LoadingSpinner isLoading={loadingStatus === apiLoadingStatus.loading} />
      <div className={styles["main"]}>
        {step === 0 && (
          <div className={styles["container"]}>
            <Row className={styles["heading"]}>
              <Col sm={8} className={styles["company"]}>
                <div className={styles["logo"]}>
                  <img src={lenderIndex.logoUrl} alt="logo" />
                </div>
                <div className={styles["name"]}>{lenderIndex.lenderName}</div>
              </Col>
              <Col sm={4} className={styles["date"]}>
                {moment().format("MM/DD/YYYY")}
              </Col>
            </Row>
            <div>
              <h5> Electronic Signature Consent Notice Effective</h5>
              <ol>
                <li>
                  <h6>Introduction</h6>
                  At {lenderIndex.lenderName} ("we," "us," or "our"), we strive
                  to provide you with convenient and secure services. As part of
                  our commitment to enhancing your experience, we offer the
                  option to sign documents electronically.
                </li>
                <li>
                  <h6>What Is an Electronic Signature?</h6>
                  An electronic signature ("e-signature") is a digital version
                  of your handwritten signature that you can use to sign
                  documents online. E-signatures are legally binding and hold
                  the same validity as traditional paper signatures under
                  applicable laws, including the [e.g., ESIGN Act, UETA].
                </li>
                <li>
                  <h6> Consent to Use Electronic Signatures</h6>
                  By providing your consent below, you agree to use electronic
                  signatures for all agreements, contracts, and other documents
                  that require your signature with [Your Company Name]. This
                  includes, but is not limited to, [list types of documents,
                  e.g., service agreements, privacy policies, terms and
                  conditions].
                </li>
                <li>
                  <h6>How It Works</h6>
                  <ul>
                    <li>
                      <span className={styles["bold"]}>Signing Documents:</span>{" "}
                      You will receive documents via email or through our secure
                      online portal. Instructions for electronically signing the
                      documents will be provided.
                    </li>
                    <li>
                      <span className={styles["bold"]}>Security:</span> We use
                      industry-standard security measures to protect your
                      information and ensure the integrity of your electronic
                      signatures.
                    </li>
                    <li>
                      <span className={styles["bold"]}> Record Keeping:</span>{" "}
                      Electronic copies of all signed documents will be
                      maintained in our records and can be accessed by you upon
                      request.
                    </li>
                  </ul>
                </li>
                <li>
                  <h6>Your Rights</h6>
                  <ul>
                    <li>
                      <span className={styles["bold"]}>Opting Out:</span> You
                      have the right to opt out of using electronic signatures
                      at any time. To do so, please contact us at [contact
                      information], and we will provide alternative methods for
                      signing documents.
                    </li>
                    <li>
                      <span className={styles["bold"]}>
                        Withdrawal of Consent:
                      </span>{" "}
                      You may withdraw your consent to use electronic signatures
                      by notifying us in writing. Withdrawal of consent will not
                      affect any actions taken prior to the withdrawal.
                    </li>
                  </ul>
                </li>
                <li>
                  <h6>Acceptance</h6>
                  By clicking "I Agree," "Sign," or by providing your electronic
                  signature below, you acknowledge that you have read,
                  understood, and agree to the terms outlined in this Electronic
                  Signature Consent Notice.
                </li>
              </ol>
              <CheckBox
                Checked={applicantInfo.userConsentedToElectronicRecords}
                onchange={(e) =>
                  setApplicantInfo({
                    ...applicantInfo,
                    userConsentedToElectronicRecords: e.target.checked,
                  })
                }
                text="I Agree to Use Electronic
                  Signatures"
                on
              />
              <div style={{ marginTop: "20px" }}>
                Contact Us If you have any questions or concerns about this
                consent notice or the use of electronic signatures, please
                contact us at:
              </div>
              {/* <h6
                style={{
                  textAlign: "center",
                  marginTop: "20px",
                  fontSize: "1.25rem",
                }}
              >
                {lenderIndex.lenderName}
              </h6> */}
              {/* <h6
                style={{
                  textAlign: "center",
                  marginTop: "20px",
                }}
              >
                <a href={lenderIndex.link} target="_blanck">
                  {lenderIndex.link}
                </a>
              </h6> */}
            </div>
            <FormCustomControl
              displayBackContinue={false}
              onContinue={() => {
                if (applicantInfo.userConsentedToElectronicRecords) {
                  setApplicantInfo({
                    ...applicantInfo,
                    userConsentedToElectronicSignature: true,
                  });
                  setStep(1);
                }
              }}
              continueDisabled={!applicantInfo.userConsentedToElectronicRecords}
            />
          </div>
        )}
        {step === 1 && (
          <div className={styles["container"]}>
            <Row className={styles["heading"]}>
              <Col sm={8} className={styles["company"]}>
                <div className={styles["logo"]}>
                  <img src={lenderIndex.logoUrl} alt="logo" />
                </div>
                <div className={styles["name"]}>{lenderIndex.lenderName}</div>
              </Col>
              <Col sm={4} className={styles["date"]}>
                {moment().format("MM/DD/YYYY")}
              </Col>
            </Row>
            <Container className={styles["financing"]}>
              <div className={styles["title"]}>Applicant Information</div>
              <Row className={styles["detailes"]}>
                <Col xs={6} sm={2} className={styles["title"]}>
                  Applicant Name
                </Col>
                <Col xs={6} sm={4} className={styles["amount"]}>
                  {`${application.applicantInfo.firstName} ${application.applicantInfo.lastName}`}
                </Col>

                <Col xs={6} sm={4} className={styles["title"]}>
                  Chart Number
                </Col>
                <Col xs={6} sm={4} className={styles["amount"]}>
                  {application.applicantInfo.chartNo}
                </Col>
              </Row>
              <div className={styles["seperator"]}></div>

              <Row className={styles["detailes"]}>
                <Col sm={3} className={styles["title"]}>
                  {/* <Location01Icon className={styles["icon"]} /> */}
                  Applicant Address
                </Col>
                <Col sm={9} className={styles["amount"]}>
                  {`${application.applicantInfo.zipcode},${application.applicantInfo.street1},${application.applicantInfo.city},${application.applicantInfo.state}`}
                </Col>
              </Row>
              <div className={styles["seperator"]}></div>

              <Row className={styles["detailes"]}>
                <Col sm={6} className={styles["title"]}>
                  {/* <Location01Icon className={styles["icon"]} /> */}
                  Applicant Email
                </Col>
                <Col sm={6} className={styles["amount"]}>
                  {application.applicantInfo.emailAddress}
                </Col>
              </Row>
            </Container>
            <Container className={styles["financing"]}>
              <div className={styles["title"]}>Financing Details</div>
              <Row className={styles["detailes"]}>
                <Col xs={6} className={styles["title"]}>
                  <Money01Icon className={styles["icon"]} />
                  Financing Amount
                </Col>
                <Col xs={6} className={styles["amount"]}>
                  {commaSeperatedCurrency(lenderIndex.approvedAmount, 0)}
                </Col>
              </Row>
              <div className={styles["seperator"]}></div>
              <Row className={styles["detailes"]}>
                <Col xs={6} className={styles["title"]}>
                  <TreatmentIcon className={styles["icon"]} />
                  Financing Type
                </Col>
                <Col xs={6} className={styles["amount"]}>
                  {lenderIndex.loanType}
                </Col>
              </Row>
              <div className={styles["seperator"]}></div>
              <Row className={styles["detailes"]}>
                <Col xs={6} className={styles["title"]}>
                  <Coins01Icon className={styles["icon"]} />
                  Number of Installations
                </Col>
                <Col xs={6} className={styles["amount"]}>
                  {lenderIndex.numberOfInstallment}
                </Col>
              </Row>
              <div className={styles["seperator"]}></div>
              <Row className={styles["detailes"]}>
                <Col xs={6} className={styles["title"]}>
                  <PercentIcon className={styles["icon"]} />
                  Interest Rate
                </Col>
                <Col xs={6} className={styles["amount"]}>
                  {commaSeperatedDecimal(lenderIndex.interestRate * 100)}%
                </Col>
              </Row>
              <div className={styles["seperator"]}></div>

              <Row className={styles["monthly"]}>
                <Col xs={6} className={styles["title"]}>
                  <Payment01Icon className={styles["icon"]} />
                  Monthly Payment
                </Col>
                <Col xs={6} className={styles["amount"]}>
                  {commaSeperatedCurrency(lenderIndex.monthlyPayment, 0)}
                </Col>
              </Row>
            </Container>

            <div>
              Day of Mounth transactions are to occur: {"01"} of each mounth
              until balance of the contract is paid in full.
            </div>
            <div>
              Day 1st mounthly transaction to occure:{" "}
              {moment().format("MM/DD/YYYY")}
            </div>
            <div>
              I herby authorize {lenderIndex.lenderName} on behalf of{" "}
              {lenderIndex.lenderName} to process mounthly credit card/debit
              card for transactions for the agreed upon amount on the date
              indicated above. The authorizes card information is provided
              below.
            </div>
            <Row>
              <ValidationWrapper
                isValid={applicantInfoValidation.cardHolderName}
                title={"Card Holder Full Name"}
                titleStar={true}
                errorMessage="Invalid Card Holder Full Name"
              >
                <Form.Control
                  type="text"
                  className={`form-control ${
                    !applicantInfoValidation.cardHolderName && "danger"
                  }`}
                  value={applicantInfo.cardHolderName || ""}
                  onChange={(e) =>
                    setApplicantInfo({
                      ...applicantInfo,
                      cardHolderName: e.target.value,
                    })
                  }
                  autoComplete="off"
                  maxLength={50}
                />
              </ValidationWrapper>
            </Row>
            <Row>
              <Col sm={6}>
                <ValidationWrapper
                  isValid={applicantInfoValidation.cardLast4Digits}
                  title={"Credit Card Last 4 Digits"}
                  titleStar={true}
                  errorMessage="Invalid Credit Card Last 4 Digits"
                >
                  <PatternFormat
                    format="****-****-****- ####"
                    mask="_"
                    allowEmptyFormatting
                    id="card-last-4-digits"
                    name="number"
                    className={`form-control ${
                      !applicantInfoValidation.cardLast4Digits && "danger"
                    }`}
                    placeholder=""
                    autoComplete="off"
                    customInput={FormControl}
                    value={applicantInfo.cardLast4Digits || null}
                    onValueChange={(values) =>
                      setApplicantInfo({
                        ...applicantInfo,
                        cardLast4Digits: values.floatValue,
                      })
                    }
                  />
                </ValidationWrapper>
              </Col>
              <Col sm={6}>
                <ValidationWrapper
                  isValid={applicantInfoValidation.cardExpirationDate}
                  title={"Expiration Date"}
                  titleStar={true}
                  errorMessage="Invalid Expiration Date"
                >
                  <Row>
                    <Col>
                      <SelectControl
                        placeholder="MM"
                        className={`${styles["input"]} ${
                          !applicantInfoValidation.cardExpirationMonth &&
                          styles["not-valid"]
                        }`}
                        options={mounths}
                        value={applicantInfo.cardExpirationMonth}
                        onChange={(v) =>
                          setApplicantInfo({
                            ...applicantInfo,
                            cardExpirationMonth: v,
                          })
                        }
                      />
                    </Col>
                    <Col>
                      <SelectControl
                        placeholder="YY"
                        className={`${styles["input"]} ${
                          !applicantInfoValidation.cardExpirationYear &&
                          styles["not-valid"]
                        }`}
                        options={years}
                        value={applicantInfo.cardExpirationYear}
                        onChange={(v) =>
                          setApplicantInfo({
                            ...applicantInfo,
                            cardExpirationYear: v,
                          })
                        }
                      />
                    </Col>
                  </Row>
                  {/* <Form.Control
                    type="date"
                    className="form-control"
                    value={applicantInfo.cardExpirationDate || ""}
                    onChange={(e) =>
                      setApplicantInfo({
                        ...applicantInfo,
                        cardExpirationDate: e.target.value,
                      })
                    }
                    min={"01-01-2000"}
                    max={"01-12-2030"}
                    autoComplete="off"
                    maxLength={50}
                  /> */}
                </ValidationWrapper>
              </Col>
            </Row>
            {/* <Container className={`${styles["financing"]} ${styles["billing"]}`}>
            <div className={styles["title"]}>Billing Detail</div>
            <Row className={styles["detailes"]}>
              <Col sm={12} className={styles["title"]}>
                <ValidationWrapper
                  isValid={applicantInfo.billingAddress || true}
                  title={"Billing Address"}
                  titleStar={true}
                  errorMessage="Invalid Billing Address"
                >
                  <Form.Control
                    type="text"
                    className="form-control"
                    value={applicantInfo.billingAddress || ""}
                    onChange={(e) =>
                      innerChange(
                        "applicantInfo.billingAddress",
                        e.currentTarget.value
                      )
                    }
                    autoComplete="off"
                    maxLength={50}
                  />
                </ValidationWrapper>
              </Col>
              <Col sm={7}>
                <CheckBox text="Billing address is the same as applicant address" />
              </Col>
            </Row>
          </Container> */}
            <CheckBox
              Checked={applicantInfo.acceptedTermsAndConditions}
              onchange={(e) => {
                setApplicantInfo({
                  ...applicantInfo,
                  acceptedTermsAndConditions: e.target.checked,
                  agreementAcceptedTimestamp: e.target.checked
                    ? new Date().toISOString()
                    : "",
                });
              }}
              text={`By signing above, I agree to pay the above stipulated contract. I authorize West Coast Dental Services to charge my credit card for the total amount specified. I understand and agree to the terms and conditions outlined in this agreement and acknowledge that I have read and understood all the information provided. I understand that I am responsible for all charges incurred and that I may cancel this authorization at any time by providing written notice to ${lenderIndex.lenderName}.`}
            />
            <CheckBox
              Checked={applicantInfo.legalNoticesAcknowledged}
              onchange={(e) => {
                setApplicantInfo({
                  ...applicantInfo,
                  legalNoticesAcknowledged: e.target.checked,
                  signatureTimestamp: e.target.checked
                    ? new Date().toISOString()
                    : "",
                });
              }}
              text="By signing above, you acknowledge that your electronic signature is legally equivalent to a manual signature.
 "
            />
            <FormCustomControl
              onContinue={onSubmit}
              displayBackContinue={false}
              continueDisabled={
                !applicantInfo.legalNoticesAcknowledged ||
                !applicantInfo.acceptedTermsAndConditions
              }
            />
          </div>
        )}
        {step === 2 && (
          <div className={styles["container"]}>
            <h4>Your Loan Application Has Been Successfully Sent.</h4>
            <FormCustomControl
              displayBackContinue={false}
              onContinue={() => handleClose(false)}
              continueLabel="Close"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default PaymentForm;

// applocanyInfo={
//   companyName: "West Coast Dental Services Inc.",
//   officeName: "West Coast Dental Group of Picco Rivera",
//   companyLogo: logo,
//   date: moment().format("MM/DD/YYYY"),
//   patientName: "Amirhossein Bagheri",
//   chartNo: "12345-1",
//   transactionDay: "01",
//   transactionDate: moment().format("YYYY-MM-DD"),
//   fullName: "",
//   cardNumber: null,
//   expDate: "",
//   address: "California",
//   email: "amibagheri@dentirate.com",
//   billingAddress: "",
//   amount: 4000,
//   type: "General Dentistry",
//   installation: 4,
//   rate: 0,
//   mounthlyPayment: 500,
// }
