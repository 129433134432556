import { useEffect, useRef, useState } from "react";
import { apiLoadingStatus } from "../../../infrastructure/constants/api";
import HgReportSelectControl from "../../show-data-control/hg-report-select-control";
import { Container } from "react-bootstrap";
import styles from "./styles.module.scss";
import LoadingSpinner from "../../loading-spinner/loading-spinner";
import useGetKpiTopLenders from "../../../hooks/use-get-kpi-top-lenders";
import { hgKpiTopLenderCollection } from "./dataCollection";
import {
  commaSeperatedCurrencyKMB,
  commaSeperatedDecimal,
} from "../../../infrastructure/helpers/number-helpers";
import _ from "lodash";
import { toast } from "react-toastify";
import { Invoice04Icon, InvoiceIcon } from "hugeicons-react";

const TabularReportControl = ({ filters = {}, userType = "" }) => {
  const options = ["User", "Location", "Lender"];
  const [isLoading, setIsLoading] = useState(apiLoadingStatus.loaded);
  const [filterData, setFilterData] = useState({
    hgKpiType: "",
  });
  const [kpiCollection, setKpiCollection] = useState(hgKpiTopLenderCollection);
  const getTopLenders = useGetKpiTopLenders();

  const oldFilterData = useRef(filterData);
  const showErrorMassage = (massage) => {
    toast(massage, { type: "error", autoClose: 4000, toastId: "error" });
  };

  useEffect(() => {
    setFilterData({
      ...filterData,
      ...filters,
    });
  }, [filters]);

  useEffect(() => {
    let actionStatus = null;
    if (_.isEqual(oldFilterData.current, filterData)) {
      return;
    }
    setIsLoading(apiLoadingStatus.loading);
    const data = {
      userType,
      startDate: filterData.startDate,
      endDate: filterData.endDate,
      hgKpiType: filterData.hgKpiType,
    };
    getTopLenders(data)
      .then(([res, status]) => {
        actionStatus = status;
        setKpiCollection(res);
      })
      .catch((err) => {
        actionStatus = _.get(err, "response.headers.action-status", null);
      })
      .finally(() => {
        setIsLoading(apiLoadingStatus.loaded);
        switch (actionStatus) {
          default:
            break;
          case "Failed":
            showErrorMassage("Failed");
            break;
          case "UsedBefore":
            showErrorMassage("Used Before");
            break;
          case "SameAsPrevious":
            showErrorMassage("Same As Previous");
            break;
          case "AdminLockedOut":
            showErrorMassage("Admin LockedOut ");
            break;
          case "InvalidCredentialsNewlyLockedOut":
            showErrorMassage("Invalid Credentials Newly LockedOut");
            break;
          case "InvalidCredentials":
            showErrorMassage("Invalid Credentials");
            break;
          case "ValidationError":
            showErrorMassage("Validation Error");
            break;
          case "NotExists":
            showErrorMassage("Not Found");
            break;
          case "Forbidden":
            showErrorMassage("Forbidden ");
            break;
          case "Success":
            break;
        }
      });
  }, [, filterData]);

  return (
    <Container className={styles["container"]}>
      <LoadingSpinner isLoading={isLoading === apiLoadingStatus.loading} />
      <div className={styles["header"]}>
        <div className={styles["title"]}>SUMMARY REPORT</div>
        <HgReportSelectControl
          choice={options.find((i) => i === filterData.hgKpiType) || options[0]}
          callbackfunction={(option) =>
            setFilterData({
              ...filterData,
              hgKpiType: option,
            })
          }
        />
      </div>

      <table className={styles["table"]}>
        <thead className={styles["table-header"]}>
          <tr>
            <th className={styles["main-title"]}>
              <InvoiceIcon size={30} />
              <span>KPI</span>
            </th>
            {kpiCollection.map((kpi, indx) =>
              indx !== kpiCollection.length - 1 ? (
                <th className={styles["title"]}>
                  <span className={styles["num"]}>
                    {kpi.label.substring(0, 2)}
                  </span>
                  <br />
                  <span>{kpi.label.substring(3)}</span>
                </th>
              ) : (
                <th className={styles["title"]}>
                  <Invoice04Icon />
                  <span>{kpi.label}</span>
                </th>
              )
            )}
          </tr>
        </thead>
        <tbody className={styles["table-body"]}>
          <tr>
            <td>Avg Prospect Amount</td>
            {kpiCollection.map((kpi) => (
              <td>{commaSeperatedCurrencyKMB(kpi.avgLeadAmount, 0)}</td>
            ))}
          </tr>
          <tr>
            <td>Avg Pre-Approved Amount</td>
            {kpiCollection.map((kpi) => (
              <td>{commaSeperatedCurrencyKMB(kpi.avgPreApprovedAmount, 0)}</td>
            ))}
          </tr>
          <tr>
            <td>Average Submitted Amount</td>
            {kpiCollection.map((kpi) => (
              <td>{commaSeperatedCurrencyKMB(kpi.avgSubmittedAmount, 0)}</td>
            ))}
          </tr>
          <tr>
            <td>Prospect Amount Rate</td>
            {kpiCollection.map((kpi) => (
              <td>{`${commaSeperatedDecimal(kpi.leadAmtRate * 100, 0)}%`}</td>
            ))}
          </tr>
          <tr>
            <td>Prospect Rate</td>
            {kpiCollection.map((kpi) => (
              <td>{`${commaSeperatedDecimal(kpi.leadRate * 100, 0)}%`}</td>
            ))}
          </tr>
          <tr>
            <td>Pre-Approved Amount Rate</td>
            {kpiCollection.map((kpi) => (
              <td>{`${commaSeperatedDecimal(
                kpi.preApprovedAmtRate * 100,
                0
              )}%`}</td>
            ))}
          </tr>
          <tr>
            <td>Pre-Approved Rate</td>
            {kpiCollection.map((kpi) => (
              <td>{`${commaSeperatedDecimal(
                kpi.preApprovedRate * 100,
                0
              )}%`}</td>
            ))}
          </tr>
          <tr>
            <td>Prospect Amount</td>
            {kpiCollection.map((kpi) => (
              <td>{commaSeperatedCurrencyKMB(kpi.totalLeadAmount, 0)}</td>
            ))}
          </tr>
          <tr>
            <td>Prospect Applications</td>
            {kpiCollection.map((kpi) => (
              <td>{kpi.totalLeadCount}</td>
            ))}
          </tr>
          <tr>
            <td>Pre-Approved Amount</td>
            {kpiCollection.map((kpi) => (
              <td>
                {commaSeperatedCurrencyKMB(kpi.totalPreApprovedAmount, 0)}
              </td>
            ))}
          </tr>
          <tr>
            <td>Pre-Approved Applications</td>
            {kpiCollection.map((kpi) => (
              <td>{kpi.totalPreApprovedCount}</td>
            ))}
          </tr>
          <tr>
            <td>Submitted Amount</td>
            {kpiCollection.map((kpi) => (
              <td>{commaSeperatedCurrencyKMB(kpi.totalSubmittedAmount, 0)}</td>
            ))}
          </tr>
          <tr>
            <td>Submitted Applications</td>
            {kpiCollection.map((kpi) => (
              <td>{kpi.totalSubmittedCount}</td>
            ))}
          </tr>
        </tbody>
      </table>
    </Container>
  );
};

export default TabularReportControl;
