import { useCommonContext } from "../../common-context";
import Footer from "../footer";
import Header from "../header";
import styles from "./styles.module.scss";
import lenderIcon from "../../images/icons/Lender-Icon.svg";
import hgIcon from "../../images/icons/Healthcare-Icon.svg";
import FormCustomControl from "../form-custom-control";
import ValidationWrapper from "../validation-wrapper";
import { Form } from "react-bootstrap";
import { useState } from "react";

const UserTypeSelection = () => {
  const [{ user, logo }] = useCommonContext();
  const [userType, setUserType] = useState();

  return (
    <>
      <Header user={user.data} userLogoUrl={logo.data?.url} />
      <div className={styles["main"]}>
        <div className={styles["container"]}>
          <FormCustomControl continueDisabled={!userType}>
            <div className={styles["title"]}>
              Select the user type you need to check
            </div>
            <div className={styles["type-container"]}>
              <button
                className={styles["type"]}
                onClick={() => setUserType("Lender")}
              >
                <img src={lenderIcon} className={styles["icon"]} />
                <div className={styles["title"]}>Lenders</div>
              </button>
              <button
                className={styles["type"]}
                onClick={() => setUserType("HealthCare")}
              >
                <img src={hgIcon} className={styles["icon"]} />
                <div className={styles["title"]}>HealthCares</div>
              </button>
            </div>
            {userType && (
              <ValidationWrapper
                // isValid={applicantInfoValidation.cardHolderName}
                title={"User Name"}
                titleStar={true}
                errorMessage="Invalid Card Holder Full Name"
              >
                <Form.Control
                  type="email"
                  className={`form-control `}
                  // value={applicantInfo.cardHolderName || ""}
                  // onChange={(e) =>
                  //   setApplicantInfo({
                  //     ...applicantInfo,
                  //     cardHolderName: e.target.value,
                  //   })
                  // }
                  autoComplete="off"
                  maxLength={50}
                />
              </ValidationWrapper>
            )}
          </FormCustomControl>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default UserTypeSelection;
