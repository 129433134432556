import { useEffect, useMemo, useState } from "react";
import { useCommonContext } from "../../common-context";
import ApplicationCard from "../../components/application-card";
import FilterSearch from "../../components/filter-search/leads-filter-search";
import Layout from "../../layout";
import styles from "./styles.module.scss";
import _ from "lodash";
import { toast } from "react-toastify";
import useGetApplication from "../../hooks/use-get-application";
import { apiLoadingStatus } from "../../infrastructure/constants/api";
import LoadingSpinner from "../../components/loading-spinner/loading-spinner";
import Pagination from "../../components/pagination-control/pagination";
import { useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "../../hooks";
import applicationStatus from "../../infrastructure/constants/enums/application-status";
import treatmentType from "../../infrastructure/constants/enums/treatment-types";
import { queryStringHelpers } from "../../infrastructure/helpers";
import LeadCard from "../../components/lead-card/lead-card";

const Leads = () => {
  // external lib
  const navigate = useNavigate();
  const location = useLocation();

  // internal hooks
  const queryHelper = useQuery();
  const handleApplication = useGetApplication();

  // common context
  const [{ user, logo }] = useCommonContext();

  // states
  const [loadingStatus, setLoadingStatus] = useState(apiLoadingStatus.unloaded);
  const [applications, setApplications] = useState([]);
  const searchFilterData = useMemo(() => {
    return {
      start: parseInt(queryHelper.getValue("start"), 10) || 0,
      records: 10,
      query: queryHelper.getValue("query"),
      minAmount: parseInt(queryHelper.getValue("minAmount"), 10) || undefined,
      maxAmount: parseInt(queryHelper.getValue("maxAmount"), 10) || undefined,
      applicationStatusCollection: _.uniq(
        (queryHelper.getAllValues("status") || [])
          .map((i) => applicationStatus.getValueByText(i))
          .filter((i) => !!i)
      ),
      treatmentType: treatmentType.getValueByText(
        queryHelper.getValue("treatmentType")
      ),
      locationIdCollection: (queryHelper.getAllValues("location") || [])
        .map((i) => parseInt(i, 10))
        .filter((i) => !!i),
      healthcareGroupId:
        parseInt(queryHelper.getValue("healthcareGroupId"), 10) || undefined,
    };
  }, [location.search]);

  // functions
  const showErrorMassage = (massage) => {
    toast(massage, { type: "error", autoClose: 4000, toastId: "error" });
  };
  const handleFilterChange = (newFilter) => {
    const temp = _.cloneDeep(newFilter);

    // convert applicationStatusCollection --> status
    temp.status = temp.applicationStatusCollection;
    // then delete it
    delete temp.applicationStatusCollection;
    // why did I do it? because it seems prettier!!!

    // convert locationIdCollection --> location
    temp.location = temp.locationIdCollection;
    // then delete it
    delete temp.locationIdCollection;
    const queryString = queryStringHelpers.stringify(temp);
    navigate(`/lead` + !!queryString ? "?" + queryString : "");
  };

  useEffect(() => {
    let actionStatus = null;
    if (loadingStatus === apiLoadingStatus.loading) {
      return;
    }
    setLoadingStatus(apiLoadingStatus.loading);

    const tempQuery = {
      query: searchFilterData.query,
      minAmount: searchFilterData.minAmount,
      maxAmount: searchFilterData.maxAmount,
      applicationStatusCollection: searchFilterData.applicationStatusCollection,
      treatmentType: searchFilterData.treatmentType,
      locationIdCollection: searchFilterData.locationIdCollection,
      start: searchFilterData.start,
      records: searchFilterData.records,
      healthcareGroupId: searchFilterData.healthcareGroupId,
    };

    handleApplication(tempQuery)
      .then(([res, status]) => {
        setLoadingStatus(apiLoadingStatus.loaded);
        actionStatus = status;
        // console.log(res);
        setApplications(res);
      })
      .catch((err) => {
        setLoadingStatus(apiLoadingStatus.error);
        actionStatus = _.get(err, "response.headers.action-status", null);
      })
      .finally(() => {
        switch (actionStatus) {
          default:
            showErrorMassage("Unknown error");
            break;
          case "InvalidCredentials":
            showErrorMassage("Invalid Credientials");
            break;
          case "Failed":
            showErrorMassage("Failed");
            break;
          case "InvalidCredentialsNewlyLockedOut":
            showErrorMassage("Invalid Credientials Newly LockedOut");
            break;
          case "UsedBefore":
            showErrorMassage("UsedBefore");
            break;
          case "SameAsPrevious":
            showErrorMassage("Same As Previous");
            break;
          case "AdminLockedOut":
            showErrorMassage("Your account was just locked out.");
            break;
          case "ValidationError":
            showErrorMassage("Validation Error");
            break;
          case "NotExists":
            showErrorMassage("Not Exists");
            break;
          case "Forbidden":
            showErrorMassage("Please Login to your Account ");
            navigate("/login");
            break;
          case "Success":
            break;
        }
      });
  }, [location.search]);

  return (
    <>
      <LoadingSpinner isLoading={loadingStatus === apiLoadingStatus.loading} />
      <Layout
        user={{
          type: "admin",
          name: user.data.firstName + " " + user.data.lastName,
          logo: logo.data?.url,
        }}
      >
        <div className={styles["container"]}>
          <FilterSearch
            searchFilterData={searchFilterData}
            setSearchFilterData={handleFilterChange}
          />
          {applications
            .filter((i) => !!i)
            .map((application, index) => (
              <LeadCard
                key={index}
                amount={application.amount}
                firstName={application.firstName}
                lastName={application.lastName}
                createDate={application.createDate}
                preferredMonthlyPayment={application.preferredMonthlyPayment}
                treatmentType={application.treatmentType}
                emailAddress={application.emailAddress}
                status={application.applicationStatus}
                personId={application.initiatorUserId}
                chartNumber={application.chartNumber}
                location={application.locationId}
                phoneNumber={application.cellPhone}
                logo={logo.data?.url}
              />
            ))}
        </div>
        <Pagination
          start={searchFilterData.start}
          records={searchFilterData.records}
          spanWidth={5}
          totalCount={1000}
          onClick={(value) =>
            handleFilterChange({
              ...searchFilterData,
              start: value,
            })
          }
          displayViewOptions={false}
          handleViewOptions={(records) =>
            handleFilterChange({
              ...searchFilterData,
              Records: records,
            })
          }
          // className={styles["pagination"]}
        />
      </Layout>
    </>
  );
};

export default Leads;
