import { apiUrls } from "../infrastructure/constants/api";
import apiHelperMethods from "../infrastructure/helpers/api-helpers";

const UseRecoveryInit = () => {
  const postData = (email) => {
    const data = {
      contactText: email,
      userContactType: "None",
    };

    const [results] = apiHelperMethods.postWithReturn(
      apiUrls.userIdentityUrls.recoveryInit,
      data
    );
    return results;
  };
  return postData;
};

export default UseRecoveryInit;
