import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import SelectAsyncControl from "./select-async-control";
import axios from "axios";
import _ from "lodash";
import styles from "./styles.module.scss";

const url = "/api/Account/PaginatedUserList";

const Label = ({
  firstName = "",
  lastName = "",
  isActive = true,
  label = undefined,
}) => {
  return (
    <div className={isActive ? styles["inactive-item"] : ""}>
      {(!!label && <>{label}</>) || <>{[lastName, firstName].join(", ")}</>}
    </div>
  );
};
const UserSelectControl = (props) => {
  const {
    onChange = () => {},
    menuPlacement = "auto",
    value = null,
    multi = false,
    className = "",
    clearable = true,
    isActive = undefined,
  } = props;
  const [innerValue, setInnerValue] = useState(value);

  const records = 10;
  const loadOptions = async (query, loadedOptions, { page }) => {
    const response = await axios.get(url, {
      params: {
        start: page < 0 ? 0 : (page - 1) * records,
        records,
        query,
        isActive: _.isNil(isActive) ? undefined : isActive,
      },
    });
    const json = response;
    return {
      options: (json.data?.data?.userList || []).map((i) => ({
        ...i,
        value: i.id,
        label: [i.lastName, i.firstName].join(", "),
      })),
      hasMore:
        (json.data?.data?.totalCount || 0) >
        (json.data?.data?.userList || []).length,
      additional: {
        page: page + 1,
      },
    };
  };

  const innerChange = (o) => {
    setInnerValue(o);
    onChange(o);
  };
  useEffect(() => {
    setInnerValue(value);
  }, [value]);
  return (
    <SelectAsyncControl
      loadOptions={loadOptions}
      onChange={innerChange}
      getOptionLabel={(o) =>
        !o ? (
          <>"–"</>
        ) : (
          <Label
            label={o.label}
            firstName={o.firstName}
            lastName={o.lastName}
            isActive={o.isActive || false}
          />
        )
      }
      value={innerValue}
      getOptionValue={(o) => (!o ? null : o.value || o.id)}
      menuPlacement={menuPlacement}
      placeholder={"Users"}
      multi={multi}
      className={className}
      clearable={clearable}
    />
  );
};
UserSelectControl.propTypes = {
  value: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
  }),
  onChange: PropTypes.func,
  menuPlacement: PropTypes.string,
  multi: PropTypes.bool,
  isActive: PropTypes.oneOf([undefined, false, null]),
};

export default UserSelectControl;
