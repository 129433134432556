const commaSeperatedValues = (value) =>
  typeof value === "number"
    ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    : typeof value === "string"
    ? value.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    : "";
const commaSeperatedDecimal = (value, numberOfDecimals = 2) => {
  return typeof value === "number"
    ? `${commaSeperatedValues(value.toFixed(numberOfDecimals))}`
    : typeof value === "string"
    ? `${commaSeperatedValues(parseFloat(value).toFixed(numberOfDecimals))}`
    : "";
};
const commaSeperatedCurrency = (value, numberOfDecimals = 2) => {
  if (typeof value !== "number") {
    return "";
  }
  if (value >= 0) {
    return `$${commaSeperatedDecimal(value, numberOfDecimals)}`;
  }
  return `$(${commaSeperatedDecimal(Math.abs(value), numberOfDecimals)})`;
};
const commaSeperatedCurrencyKMB = (value, numberOfDecimals = 2) => {
  if (typeof value !== "number") {
    return "";
  }
  if (value >= 0 && value < 1000) {
    return `$${commaSeperatedDecimal(value, numberOfDecimals)}`;
  } else if (value >= 1000 && value < 1000000) {
    return `$${commaSeperatedDecimal(value / 1000, 1)} K`;
  } else if (value >= 1000000 && value < 1000000000) {
    return `$${commaSeperatedDecimal(value / 1000000, 2)} M`;
  } else if (value >= 1000000000) {
    return `$${commaSeperatedDecimal(value / 1000000000, 2)} B`;
  }
  return `$(${commaSeperatedDecimal(Math.abs(value), numberOfDecimals)})`;
};

export {
  commaSeperatedCurrency,
  commaSeperatedDecimal,
  commaSeperatedValues,
  commaSeperatedCurrencyKMB,
};
