import axios from "axios";
import { apiUrls } from "../constants/api";
import qs from "qs";

const emptyFunctions = {
  onSuccess: () => {},
  onError: () => {},
  onLoad: () => {},
  onFinish: () => {},
};
const destructMethods = (methods) => {
  if (!methods) {
    return emptyFunctions;
  }
  const { onSuccess, onLoad, onFinish, onError } = methods;
  return {
    onLoad: typeof onLoad === "function" ? onLoad : () => {},
    onSuccess: typeof onSuccess === "function" ? onSuccess : () => {},
    onError: typeof onError === "function" ? onError : () => {},
    onFinish: typeof onFinish === "function" ? onFinish : () => {},
  };
};

const innerHandlePromise = (promise, onSuccess, onError, onFinish) => {
  let canFinish = true;
  promise
    .then(onSuccess)
    .catch((error) => {
      if (
        error.response &&
        (error.response.status === 401 || error.response.status === 403)
      ) {
        document.location.href = `${apiUrls.login}?returnUrl=${encodeURI(
          window.location.pathname +
            window.location.search +
            window.location.hash
        )}`;
        canFinish = false;
      }
      canFinish = true;
      onError(error);
    })
    .finally(() => {
      if (canFinish) {
        onFinish();
      }
    });
};
const apiHelperMethods = {
  createCancelToken: () => axios.CancelToken.source(),
  createAbortController: () => new AbortController(),
  get: (url, params, methods, paramsSerializer, headers) => {
    const { onSuccess, onLoad, onFinish, onError } = destructMethods(methods);
    onLoad();
    const tempHeaders = headers || {};
    const filteredParams =
      params && Object.keys(params).length > 0
        ? Object.fromEntries(
            Object.entries(params).filter(
              ([_, value]) => !(Array.isArray(value) && value.length === 0)
            )
          )
        : undefined;
    innerHandlePromise(
      typeof paramsSerializer === "function"
        ? axios.get(url, {
            params: filteredParams,
            paramsSerializer:
              paramsSerializer ||
              ((params) => qs.stringify(params, { arrayFormat: "repeat" })),
            headers: tempHeaders,
          })
        : axios.get(url, {
            params: filteredParams,
            headers: tempHeaders,
          }),
      onSuccess,
      onError,
      onFinish
    );
  },
  getWithReturn: (url, params = {}, paramsSerializer) => {
    if (!params || Object.keys(params).length === 0) {
      return axios.get(url);
    }
    const filteredParams = Object.fromEntries(
      Object.entries(params).filter(
        ([_, value]) => !(Array.isArray(value) && value.length === 0)
      )
    );
    return axios.get(url, {
      params: filteredParams,
      paramsSerializer:
        paramsSerializer ||
        ((params) => qs.stringify(params, { arrayFormat: "repeat" })),
    });
  },
  post: (url, data, methods, headers) => {
    const { onSuccess, onLoad, onFinish, onError } = destructMethods(methods);
    onLoad();
    const tempHeaders = headers || {};
    innerHandlePromise(
      axios.post(url, data, { headers: tempHeaders }),
      onSuccess,
      onError,
      onFinish
    );
  },
  postWithReturn: (url, data, headers, controller) => {
    const cancelToken = axios.CancelToken.source();
    const innerController = new AbortController();
    const tempHeaders = headers || {};
    return [
      axios.post(url, data, {
        headers: tempHeaders,
        cancelToken: cancelToken.token,
        signal: innerController.signal,
      }),
      cancelToken,
    ];
  },
  put: (url, data, methods, headers) => {
    const { onSuccess, onLoad, onFinish, onError } = destructMethods(methods);
    onLoad();
    const tempHeaders = headers || {};
    innerHandlePromise(
      axios.put(url, data, { headers: tempHeaders }),
      onSuccess,
      onError,
      onFinish
    );
  },
  patch: (url, data, methods, headers) => {
    const { onSuccess, onLoad, onFinish, onError } = destructMethods(methods);
    onLoad();
    const tempHeaders = headers || {};
    innerHandlePromise(
      axios.patch(url, data, { headers: tempHeaders }),
      onSuccess,
      onError,
      onFinish
    );
  },
  delete: (url, data, methods, headers) => {
    const { onSuccess, onLoad, onFinish, onError } = destructMethods(methods);
    onLoad();
    const tempHeaders = headers || {};
    innerHandlePromise(
      axios.delete(url, data, { headers: tempHeaders }),
      onSuccess,
      onError,
      onFinish
    );
  },
};

export default apiHelperMethods;
