import {
  CorporateIcon,
  Delete02Icon,
  Edit01Icon,
  Location01Icon,
  UserAccountIcon,
} from "hugeicons-react";
import styles from "./styles.module.scss";
import { Container } from "react-bootstrap";
import PropTypes from "prop-types";
import { data } from "./locationData";
import TooltipControl from "../tooltip-control/tooltip-control";
import ToggleSwitch from "../toggle-switch/toggle-switch";
import UseUpdateLocationActivation from "../../hooks/use-update-location-activation";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import ModalContainer from "../modal-control";
import { isMobile } from "react-device-detect";

const LocationCard = ({ location = data }) => {
  const [isActive, setIsActive] = useState(location?.isActive);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const updateOfficeActivation = UseUpdateLocationActivation();
  const showMassage = (massage) => {
    massage === "Successfully deactivated!"
      ? toast(massage, { type: "success", autoClose: 4000, toastId: "success" })
      : massage === "Please contact DentiRate to activate the office"
      ? toast(massage, { type: "warning", autoClose: 4000, toastId: "warning" })
      : toast(massage, { type: "error", autoClose: 4000, toastId: "error" });
  };

  const handleActivation = async () => {
    if (isActive) {
      console.log(location);
      console.log(location?.isActive);
      let result = window.confirm(
        "Do you confirm you want to deactivate this office? If deactivated, DentiRate support can only re-activate it."
      );
      if (result) {
        const data = { hgSiteId: location.id, isActive: false };
        // console.log(data);
        let actionStatus = null;

        try {
          setIsLoading(true);
          const res = await updateOfficeActivation(data);
          actionStatus = _.get(res, "headers.action-status", null);
          setIsActive(!isActive);
          setIsLoading(false);
          // setChecked(!checked);
          // console.log(actionStatus);
        } catch (error) {
          showMassage("Something went wrong!");
          // console.log(error);
        } finally {
          setIsLoading(false);
          switch (actionStatus) {
            default:
              showMassage("Unknown error");
              break;
            case "InvalidCredentials":
              showMassage("Invalid Credientials");
              break;
            case "Failed":
              showMassage("Failed");
              break;
            case "InvalidCredentialsNewlyLockedOut":
              showMassage("Invalid Credientials Newly LockedOut");
              break;
            case "UsedBefore":
              showMassage("UsedBefore");
              break;
            case "SameAsPrevious":
              showMassage("Same As Previous");
              break;
            case "AdminLockedOut":
              showMassage("Your account was just locked out.");
              break;
            case "ValidationError":
              showMassage("Validation Error");
              break;
            case "NotExists":
              showMassage("Not Exists");
              break;
            case "Forbidden":
              showMassage("Forbidden");
              break;
            case "Success":
              showMassage("Successfully deactivated!");
              break;
          }
        }
      }
    } else {
      showMassage("Please contact DentiRate to activate the office");
    }
  };

  return (
    <Container className={styles["container"]}>
      <div className={styles["row"]}>
        <div className={styles["name"]}>{!!location && location.nickname}</div>
        <div className={styles["tools"]}>
          <div className={styles[isActive ? "confirmed" : "pending"]}>
            {isActive ? "Active" : "Inactive"}
          </div>
          {/* <TooltipControl content={"Delete Location"}>
            <button className={styles["icon"]}>
              <Delete02Icon />
            </button>
          </TooltipControl> */}
          <TooltipControl content={"Edit Location"}>
            <button
              className={styles["icon"]}
              onClick={() => navigate(`/locations/edit/${location.id}`)}
              // onClick={() => navigate(`/applications/edit/${personId}`)}
            >
              <Edit01Icon />
            </button>
          </TooltipControl>
          <TooltipControl
            content={isActive ? "Deactivate the office" : "Activate the office"}
          >
            <div>
              <ToggleSwitch
                isChecked={isActive}
                callbackFunction={handleActivation}
                isLoading={isLoading}
              />
            </div>
          </TooltipControl>
        </div>
      </div>
      <div className={styles["separation"]}></div>
      <div className={styles["row"]}>
        <div className={styles["corporateName"]}>
          <CorporateIcon className={styles["userIcon"]} />
          {!!location && location.corporateName}
        </div>
      </div>
      <div className={styles["separation"]}></div>
      <div className={styles["row"]}>
        <div className={styles["userName"]}>
          <UserAccountIcon className={styles["userIcon"]} />
          {!!location && location.ownerFirstName + " " + location.ownerLastName}
        </div>
        <div className={styles["tools"]}>
          <div className={styles["locationIcon"]}>
            <Location01Icon />
          </div>
          <button
            className={styles["show-more"]}
            onClick={() => {
              console.log(location);
              setShowModal(true);
            }}
          >{`Show More >`}</button>
        </div>
      </div>
      <ModalContainer
        show={showModal}
        handleClose={() => {
          setShowModal(false);
        }}
        modalSize={"lg"}
        modalTitle={location?.nickname}
        centered
        fullScreen={isMobile}
        headerComponent={
          <TooltipControl content={"Edit Location"}>
            <button
              className={styles["edit-icon"]}
              onClick={() => navigate(`/locations/edit/${location.id}`)}
              // onClick={() => navigate(`/applications/edit/${personId}`)}
            >
              <Edit01Icon />
            </button>
          </TooltipControl>
        }
      >
        <div className={styles["details-container"]}>
          <div className={styles["loc-info"]}>
            <div className={styles["title"]}>Location Information</div>
            <div className={styles["item"]}>
              Corporate/Legal Name: <span>{location?.corporateName}</span>
            </div>
            <div className={styles["item"]}>
              Associated Practice Name or Store Number:{" "}
              <span>{location?.id}</span>
            </div>
            <div className={styles["item"]}>
              Office/DBA Name: <span>{location?.officeName}</span>
            </div>
            <div className={styles["item"]}>
              Tax ID Number: <span>{location?.taxId}</span>
            </div>
            <div className={styles["item"]}>
              Type of Ownership: <span>{location?.typeOfOwnership}</span>
            </div>
            <div className={styles["item"]}>
              Total Number of Employees:{" "}
              <span>{location?.numberOfEmployees}</span>
            </div>
            <div className={styles["item"]}>
              Date Business Started:{" "}
              <span>{location?.businessStartedDate}</span>
            </div>
            <div className={styles["item"]}>
              Practice/Medical Specialty, or Business Type:{" "}
              <span>{location?.businessType}</span>
            </div>
            <div className={styles["item"]}>
              Practice Management Software:{" "}
              <span>{location?.practiceManagementSoftware}</span>
            </div>
            <div className={styles["item"]}>
              Office Phone Number: <span>{location?.phoneNumber}</span>
            </div>
            <div className={styles["item"]}>
              Fax Number: <span>{location?.id}</span>
            </div>
            <div className={styles["item"]}>
              Office Website: <span>{location?.id}</span>
            </div>
          </div>
          <div className={styles["physical-address"]}>
            <div className={styles["title"]}>Physical Address</div>
            <div className={styles["item"]}>
              Street Line 1: <span>{location?.address1}</span>
            </div>
            <div className={styles["item"]}>
              Street Line 2: <span>{location?.address2}</span>
            </div>
            <div className={styles["item"]}>
              City: <span>{location?.city}</span>
            </div>
            <div className={styles["item"]}>
              State: <span>{location?.state}</span>
            </div>
            <div className={styles["item"]}>
              Zip Code: <span>{location?.zipCode}</span>
            </div>
          </div>
          <div className={styles["mailing-address"]}>
            <div className={styles["title"]}>Mailing Address</div>
            <div className={styles["item"]}>
              Street Line 1: <span>{location?.mailingAddress1}</span>
            </div>
            <div className={styles["item"]}>
              Street Line 2: <span>{location?.mailingAddress2}</span>
            </div>
            <div className={styles["item"]}>
              City: <span>{location?.mailingCity}</span>
            </div>
            <div className={styles["item"]}>
              State: <span>{location?.mailingState}</span>
            </div>
            <div className={styles["item"]}>
              Zip Code: <span>{location?.mailingZipCode}</span>
            </div>
          </div>
          <div className={styles["owner"]}>
            <div className={styles["title"]}>Owner</div>
            <div className={styles["item"]}>
              First Name: <span>{location?.ownerFirstName}</span>
            </div>
            <div className={styles["item"]}>
              Last Name: <span>{location?.ownerLastName}</span>
            </div>
            <div className={styles["item"]}>
              Medical/Business/State License Number:{" "}
              <span>{location?.ownerLicenseNumber}</span>
            </div>
            <div className={styles["item"]}>
              Street Line 1: <span>{location?.ownerAddress1}</span>
            </div>
            <div className={styles["item"]}>
              Street Line 2: <span>{location?.id}</span>
            </div>
            <div className={styles["item"]}>
              City: <span>{location?.ownerCity}</span>
            </div>
            <div className={styles["item"]}>
              State: <span>{location?.ownerState}</span>
            </div>
            <div className={styles["item"]}>
              Zip Code: <span>{location?.ownerZipCode}</span>
            </div>
            <div className={styles["item"]}>
              Phone Number: <span>{location?.ownerPhoneNumber}</span>
            </div>
            <div className={styles["item"]}>
              Email Address: <span>{location?.ownerEmail}</span>
            </div>
          </div>
          <div className={styles["man-doc"]}>
            <div className={styles["title"]}>Managing Doctor</div>
            <div className={styles["item"]}>
              First Name: <span>{location?.managerFirstName}</span>
            </div>
            <div className={styles["item"]}>
              Last Name: <span>{location?.managerLastName}</span>
            </div>
            <div className={styles["item"]}>
              Medical License Number:{" "}
              <span>{location?.managerLicenseNumber}</span>
            </div>
            <div className={styles["item"]}>
              Medical License State:{" "}
              <span>{location?.managerLicenseState}</span>
            </div>
          </div>
          <div className={styles["contact"]}>
            <div className={styles["title"]}>Contact</div>
            <div className={styles["item"]}>
              First Name: <span>{location?.contactFirstName}</span>
            </div>
            <div className={styles["item"]}>
              Last Name: <span>{location?.contactLastName}</span>
            </div>
            <div className={styles["item"]}>
              Office Phone Number: <span>{location?.contactPhoneNumber}</span>
            </div>
            <div className={styles["item"]}>
              Email Address: <span>{location?.contactEmail}</span>
            </div>
          </div>
          <div className={styles["bank-info"]}>
            <div className={styles["title"]}>Bank Information</div>
            <div className={styles["item"]}>
              Bank Name: <span>{location?.bankName}</span>
            </div>
            <div className={styles["item"]}>
              Routing Number: <span>{location?.bankRoutingNumber}</span>
            </div>
            <div className={styles["item"]}>
              Account Number: <span>{location?.bankAccountNumber}</span>
            </div>
            {/* <div className={styles["item"]}>
              Bank Letter or Void Check as An Attachment:{" "}
              <span>{location?.bankLetterImage}</span>
            </div> */}
          </div>
        </div>
      </ModalContainer>
    </Container>
  );
};

LocationCard.propTypes = {
  location: PropTypes.shape({
    nickname: PropTypes.string,
    isActive: PropTypes.bool,
    ownerFirstName: PropTypes.string,
    ownerLastName: PropTypes.string,
    number: PropTypes.number,
  }),
};
export default LocationCard;

//
