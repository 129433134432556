import { useLocation } from "react-router-dom";

const useQuery = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const getValue = (queryName) => {
    return searchParams.get(queryName);
  };
  const getAllValues = (queryName) => {
    return searchParams.getAll(queryName);
  };

  return { getValue, getAllValues };
};

export default useQuery;
