import React, { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faExternalLink } from "@fortawesome/free-solid-svg-icons";
import { useIntl } from "react-intl";
import { Tick02Icon, Tick03Icon } from "hugeicons-react";
import styles from "./styles.module.scss";
import CheckBox from "../../components/check-box/check-box";

const ConsentItem = ({
  lenderName = "",
  lenderLink = "",
  termsConditions = "",
  privacyPolicy = "",
  checked = null,
  onClick = () => {},
  lastItem = false,
  onchange = () => {},
}) => {
  const intl = useIntl();
  const getIntlMessage = useCallback(
    (id) => intl.formatMessage({ id }),
    [intl]
  );

  const lenderNamePossessive = getIntlMessage(
    "ConsentItem.LenderNamePossessive"
  ).replace("{lenderName}", lenderName);

  return (
    <div
      className={`${styles["list-item"]} ${lastItem && styles["last-item"]}`}
    >
      <div className={styles["tick-icon"]}>
        {/* <Tick02Icon /> */}
        <CheckBox
          Checked={checked}
          style={{ marginTop: "2px" }}
          onClick={onClick}
          onchange={onchange}
        />
      </div>
      <div className={styles["terms"]}>
        {getIntlMessage("ConsentItem.ReadAndAgreed")}{" "}
        <a className='link_primary fw_bold' href={lenderLink} target='_blank'>
          {lenderNamePossessive} <FontAwesomeIcon icon={faExternalLink} />
        </a>{" "}
        <a
          className='link_primary fw_bold'
          href={termsConditions}
          target='_blank'
        >
          {getIntlMessage("ConsentItem.TermsConditions")}{" "}
          <FontAwesomeIcon icon={faExternalLink} />
        </a>{" "}
        {getIntlMessage("And")}{" "}
        <a
          className='link_primary fw_bold'
          href={privacyPolicy}
          target='_blank'
        >
          {getIntlMessage("ConsentItem.PrivacyPolicy")}{" "}
          <FontAwesomeIcon icon={faExternalLink} />
        </a>
        .
      </div>
    </div>
  );
};

ConsentItem.propTypes = {
  lenderName: PropTypes.string,
  lenderLink: PropTypes.string,
  termsConditions: PropTypes.string,
  privacyPolicy: PropTypes.string,
};

export default ConsentItem;
